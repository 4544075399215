import { Button } from '@coreui/coreui'
import React from 'react'
import { AnusuchiAPI } from './AnusuchiAPI'
import { CONSTANTS } from '.././../../constants'

export const AnusuchiTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => rowData.tableData.id + 1,
      },
      {
        title: 'कैदी को नाम',
        field: 'kaidi_ko_nam_thar',
      },

      {
        title: 'विभाग मा पठाइएको',
        field: 'central',
        render: (rowData) => (rowData.central == '1' ? 'छ' : 'छैन'),
      },
    ]
  },

  getTableDatas: function (anusuchies) {
    return anusuchies
  },

  getDataActions: function (
    handleEditOpen,
    handleDeleteOpen,
    setPrintData,
    generatePdf,
    handleSubmitOpen,
    handleMaafiOpen,
    giveMaafi,
  ) {
    return [
      {
        icon: 'edit',
        iconProps: {
          style: {
            color: CONSTANTS.EDIT_ICON_COLOR,
            background: CONSTANTS.EDIT_BACKGROUND_COLOUR,
          },
        },
        tooltip: 'Edit',
        onClick: (e, rowData) => handleEditOpen(rowData),
        position: 'row',
      },

      {
        icon: 'delete',
        iconProps: {
          style: {
            color: CONSTANTS.DELETE_ICON_COLOR,
            background: CONSTANTS.DELETE_BACKGROUND_COLOR,
          },
        },

        tooltip: 'Delete',
        onClick: (e, rowData) => handleDeleteOpen(rowData),
        position: 'row',
      },

      {
        icon: 'print',
        iconProps: {
          style: {},
        },
        tooltip: 'Print',
        onClick: async (e, rowData) => {
          await setPrintData(rowData)
          generatePdf(rowData)
        },
      },

      {
        icon: 'publish',
        tooltip: 'केन्द्र मा पठाउनुहोस ।',
        onClick: (e, rowData) => handleSubmitOpen(rowData),
      },

      {
        icon: 'swipe-up',
        tooltip: 'माफी पाएको ',
        onClick: (e, rowData) => handleMaafiOpen(rowData),
      },
    ]
  },
}
