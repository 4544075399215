import React, { useState } from 'react'
import AbedanForm from './AbedanForm'
import AbedanList from './Abedanlist'

const Abedan = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      {/* <AbedanForm reload={reload} setReload={setReload} /> */}
      <AbedanList reload={reload} setReload={setReload} />
    </>
  )
}
export default Abedan
