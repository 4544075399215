import React from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import PropTypes from 'prop-types'

const ContentBox = ({ title, children }) => {
  return (
    <CCard className="mb-4">
      <CCardHeader>{title}</CCardHeader>
      <CCardBody>{children}</CCardBody>
    </CCard>
  )
}

export default ContentBox

ContentBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}

ContentBox.defaultProps = {
  title: '',
  children: <></>,
}
