export const CONSTANTS = {
  ALERT_TIME_OUT: 5000,
  NETWORK_TIME_OUT: 150000,
  NETWORK_TIME_OUT_MESSAGE: 'Network Timeout ! Try again later.',
  EDIT_ICON_COLOR: '#fff',
  DELETE_ICON_COLOR: '#fff',
  EDIT_BACKGROUND_COLOUR: '#08D57F',
  DELETE_BACKGROUND_COLOR: '#7a0401',
  VIEW_ICON_COLOR: '#fff',
  VIEW_BACKGROUND_COLOR: '#7367F0',
  SEND_ICON_COLOR: '#fff',
  SEND_BACKGROUND_COLOR: '#E67E22',
  ACCEPT_BACKGROUND_COLOR: '#0DAC50',
}
