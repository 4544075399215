import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  CButton,
  CCard,
  CFormLabel,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import axios from 'axios'
import { UserContext, FirstLoginContext } from '../../../context/UserContext'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Logo from '../../../assets/images/logo.png'

// Constants import
import { CONSTANTS } from 'src/constants'

const ZillaPasswordChange = () => {
  // State to maintain error response while logging in
  const [errorResponse, setErrorResponse] = useState(null)

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  // State to manage credentials
  const [credentials, setCredentials] = useState({
    new_password: '',
    confirm_password: '',
  })

  // User Context
  const [user, setUser] = useContext(UserContext)
  // console.log(user)

  // Function to handle credentials value change
  const handleCredentialsChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  // Login function

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    if (credentials.new_password !== credentials.confirm_password) {
      setErrorResponse('पासवर्ड मेल खाएन ।')
      setLoading(false)
      return
    }

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/user/changepassword`,
      headers: {
        Authorization: `Bearer ${user ? user.access_token : null}`,
      },
      data: credentials,
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
      .then((res) => {
        console.log('Password Change data', res.data)
        if (res.data.status) {
          //   window.location.reload()
          history.push('/')
          setLoading(false)
        } else {
          setErrorResponse(res.data.message)
          setLoading(false)
        }
      })
      .catch((e) => {
        setErrorResponse(e.message)
        setLoading(false)
      })

    setTimeout(() => setErrorResponse(null), 5000)
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row ">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <div
                    className="img-container"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {/* <img src={Logo} style={{ width: 'auto', height: '10rem' }} /> */}
                    <div>
                      <h4 className="my-3" style={{ color: '#ad0007' }}>
                        पासवर्ड परिवर्तन
                      </h4>
                    </div>
                  </div>

                  <CForm>
                    {errorResponse ? (
                      <div className="mb-4">
                        <Alert severity="error">{errorResponse}</Alert>
                      </div>
                    ) : null}

                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="नयाँ पासवर्ड"
                        name="new_password"
                        value={credentials.new_password}
                        onChange={handleCredentialsChange}
                      />
                    </CInputGroup>

                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="पु:न पासवर्ड"
                        name="confirm_password"
                        value={credentials.confirm_password}
                        onChange={handleCredentialsChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <CButton
                              type="submit"
                              color="primary"
                              className="px-4"
                              onClick={handleSubmit}
                            >
                              पासवर्ड परिवर्तन गर्नुहोस्
                            </CButton>
                          )}
                        </div>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ZillaPasswordChange
