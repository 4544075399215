const nepaliMapping = {
  1: '१',
  2: '२',
  3: '३',
  4: '४',
  5: '५',
  6: '६',
  7: '७',
  8: '८',
  9: '९',
  0: '०',
}
export const NepaliNumber = (englishNumber) => {
  let stringNum = englishNumber + ''
  let nepaliNum = ''
  for (let i = 0; i < stringNum.length; i++) {
    if (nepaliMapping[stringNum[i]]) {
      nepaliNum = nepaliNum + nepaliMapping[stringNum[i]]
    } else {
      if (stringNum[i] === null) {
        return nepaliMapping['0']
      } else {
        nepaliNum = nepaliNum + stringNum[i]
      }
    }
  }

  return nepaliNum
}

const englishMapping = {
  '\u{0966}': '0',

  '\u{0967}': 1,
  '२': 2,
  '३': 3,
  '४': 4,
  '५': 5,
  '६': 6,
  '७': 7,
  '८': 8,
  '९': 9,
}

export const EnglishNumber = (nepaliNumber) => {
  let stringNum = nepaliNumber + ''
  let englishNum = ''
  for (let i = 0; i < stringNum.length; i++) {
    if (englishMapping[stringNum[i]]) {
      englishNum = englishNum + englishMapping[stringNum[i]]
    } else {
      englishNum = englishNum + stringNum[i]
    }
  }

  return englishNum
}
