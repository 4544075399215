import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cilSitemap,
  cilLineStyle,
  cilFunctionsAlt,
  cibSitepoint,
  cilChart,
  cilPeople,
  cilHome,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

export const _superadminnav = [
  {
    component: CNavItem,
    name: 'गृह पृष्ठ',
    to: '/dashboard',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'जिल्ला एडमिन',
    to: '/zillaAdmin',
    icon: <CIcon icon={cilSitemap} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'मुद्दा',
    to: '/mudda',
    icon: <CIcon icon={cilSitemap} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'अदालत',
    to: '/adalat',
    icon: <CIcon icon={cilLineStyle} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'देश',
    to: '/rastriyata',
    icon: <CIcon icon={cilFunctionsAlt} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'कार्यालय',
    to: '/karyalaya',
    icon: <CIcon icon={cibSitepoint} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'गणना मिति',
    to: '/gadana-miti',
    icon: <CIcon icon={cibSitepoint} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'अनुसूची फारम',
    to: '/anusuchi',
    icon: <CIcon icon={cibSitepoint} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'जिल्लाका आवेदन ',
    to: '/jilla-ko-abedan',
    icon: <CIcon icon={cibSitepoint} customClassName="nav-icon" />,
  },
]

export const _jilladminnav = [
  {
    component: CNavItem,
    name: 'गृह पृष्ठ',
    to: '/dashboard',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'अनुसूची फारम',
    to: '/anusuchi',
    icon: <CIcon icon={cibSitepoint} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    // name: 'पुराना अवेदनहरु',
    name: 'पुरानो आवेदनहरु',
    to: '/anusuchi-old',
    icon: <CIcon icon={cibSitepoint} customClassName="nav-icon" />,
  },
]
