import React, { useState } from 'react'
import MuddaForm from './MuddaForm'
import MuddaList from './MuddaList'

const Mudda = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      <MuddaForm reload={reload} setReload={setReload} />
      <MuddaList reload={reload} setReload={setReload} />
    </>
  )
}
export default Mudda
