import React, { useState } from 'react'
import GadanaForm from './GadanaForm'
import GadanaList from './GadanaList'

const Gadana = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      <GadanaForm reload={reload} setReload={setReload} />
      <GadanaList reload={reload} setReload={setReload} />
    </>
  )
}
export default Gadana
