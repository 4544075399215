import React from 'react'
import axios from 'axios'
import { CONSTANTS } from 'src/constants'

export const PuranoAbedanAPI = {
  // ...

  // mapping the API of interest
  get: function (token) {
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/report/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  getSingle: function (token, selectedItem) {
    console.log(selectedItem)
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/oldAnusuchi/get/${selectedItem}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  
  // ...
}
