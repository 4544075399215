import React from 'react'
import { NepaliNumber } from 'src/helpers/NepaliNumber'
import { ZillaAdminAPI } from './ZillaAdminAPI'
import { CONSTANTS } from '.././../../constants'

export const ZillaAdminTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => NepaliNumber(rowData.tableData.id + 1),
      },
      {
        title: 'नाम ',
        field: 'name',
      },
      {
        title: 'इमेल',
        field: 'email',
      },
    ]
  },

  getTableDatas: function (zillaAdmines) {
    return zillaAdmines
  },

  getDataActions: function (handleEditOpen, handleDeleteOpen) {
    return [
      {
        icon: 'edit',
        iconProps: {
          style: {
            color: CONSTANTS.EDIT_ICON_COLOR,
            background: CONSTANTS.EDIT_BACKGROUND_COLOUR,
          },
        },
        tooltip: 'Edit',
        onClick: (e, rowData) => handleEditOpen(rowData.id),
        position: 'row',
      },

      {
        icon: 'delete',
        iconProps: {
          style: {
            color: CONSTANTS.DELETE_ICON_COLOR,
            background: CONSTANTS.DELETE_BACKGROUND_COLOR,
          },
        },
        tooltip: 'Delete',
        onClick: (e, rowData) => handleDeleteOpen(rowData.id),
        position: 'row',
      },
    ]
  },
}
