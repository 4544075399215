import React, { useContext } from 'react'
import { PrintContext } from 'src/context/PrintContext'
import NepaliDate from 'nepali-date'
import { NepaliNumber } from 'src/helpers/NepaliNumber'
// Create Document Component
const AnusuchiOnePdf = () => {
  const [printData] = useContext(PrintContext)

  return (
    <div id="anusuchi-1" style={{ padding: '3rem' }}>
      <header>
        <h4 style={{ textAlign: 'center' }}>अनुसूची १</h4>
        <p style={{ textAlign: 'center' }}>(नियम ४ को उपनियम ( १ ) सँग सम्बन्धित)</p>
      </header>

      <br />
      {/* <br /> */}
      {/* Subject section */}
      <section id="subject">
        <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>
          कैदीको चालचलन सम्बन्धी अभिलेख{' '}
        </h4>
        <p style={{ textAlign: 'center' }}>
          कारागार कार्यालय, {printData.length > 0 ? printData[0].Karagar_Karyalaya : null}
        </p>
      </section>

      <br />
      <br />

      {/* Table Section */}
      <section id="table" className="pdf-table">
        <table border="1" style={{ marginLeft: '1.5rem' }}>
          <thead>
            <tr>
              <th>सि.नं.</th>
              <th>कैदीको नाम</th>
              <th>कैदीको उमेर</th>
              <th>गरेको कसूर</th>
              <th>भएको सजाय </th>
              <th>निजको मुद्दा नकारात्मक सूचीमा परे/नपरेको</th>
              <th>कैद भुक्तान गरेको अवधि</th>
              <th>कैदमा कुनै किसिमको अनुचित काम गरे/नगरेको</th>
              <th>अनुचित काम गरेको भए त्यसको विवरण</th>
              <th>कारागार प्रमुखको राय</th>
            </tr>

            {printData.map((item, id) => (
              <tr key={id}>
                <th>{NepaliNumber(id + 1)}</th>
                <th>{item.kaidi_ko_nam_thar}</th>
                <th>{item.kaidi_ko_umer}</th>

                <th>{item.mudda ? item.mudda.map((item) => item) : null}</th>
                <th>{NepaliNumber(item.bhayeko_sajaye)}</th>
                <th>{item.nij_ko_mudda_nakaratmak}</th>
                <th>{NepaliNumber(item.kaaid_bhuktan_awadhi)}</th>
                <th>{item.Kaid_ma_kunai_kisim_ko_anuchit_kam}</th>
                <th>{item.anuchid_kam_ko_bibaran}</th>
                <th>{item.karagar_pramuk_ko_raye}</th>
              </tr>
            ))}
          </thead>
        </table>
      </section>

      {/* Signatture section */}
      {/* <br /> */}
      {/* <br /> */}
      <section id="signature">
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            // alignContent: 'flex-start',
          }}
        >
          <p>चालचलन प्रमाणित गर्ने कारागार प्रमुखको;</p>
          <table>
            {/* <tr>
              <td>चालचलन प्रमाणित गर्ने कारागार प्रमुखको;</td>
            </tr> */}

            <tr>
              <td>नाम , थर :</td>

              <td></td>
            </tr>

            <tr>
              <td>दस्तखत :</td>

              <td>.........................</td>
            </tr>

            <tr>
              <td>मिति:</td>

              <td>
                {' '}
                {NepaliNumber(
                  `${new NepaliDate().getYear()}-${
                    new NepaliDate().getMonth() + 1
                  }-${new NepaliDate().getDate()}`,
                )}
              </td>
            </tr>
          </table>
        </div>

        {/* <div>
          <p style={{ textAlign: 'right' }}>दस्तखत:</p>
        </div>

        <div>
          <p style={{ textAlign: 'right' }}>
            मिति:
            {NepaliNumber(
              `${new NepaliDate().getYear()}-${
                new NepaliDate().getMonth() + 1
              }-${new NepaliDate().getDate()}`,
            )}
          </p>
        </div> */}
      </section>
    </div>
  )
}

export default AnusuchiOnePdf
