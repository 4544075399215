import React, { useState } from 'react'
import RastriyataForm from './RastriyataForm'
import RastriyataList from './RastriyataList'

const Rastriyata = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      <RastriyataForm reload={reload} setReload={setReload} />
      <RastriyataList reload={reload} setReload={setReload} />
    </>
  )
}
export default Rastriyata
