import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MyDialog from './Dialog/Dialog'
import ContentBox from 'src/components/ContentBox/ContentBox'
import { CButton } from '@coreui/react'
import { CircularProgress } from '@material-ui/core'
import { convert_to_preeti } from 'src/helpers/PreetiConverter'

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
]

export default function MultipleSelect({
  dataList,
  selectionState,
  setSelectionState,
  myvalue,
  showValue,
  otherMenu = false,
  loading,
}) {
  const classes = useStyles()
  const theme = useTheme()
  //   const [personName, setPersonName] = React.useState([])
  const [openOtherForm, setOpenOtherForm] = useState(false)
  const [otherValue, setOtherValue] = useState('')
  const [tempDataList, setTempDataList] = useState(dataList)
  const [newValue, setNewValues] = useState([])

  useEffect(() => {
    setTempDataList(dataList)
  }, [dataList])

  const handleOpen = () => {
    setOpenOtherForm(true)
  }

  const handleClose = () => {
    setOpenOtherForm(false)
  }

  const addOtherToMenu = async () => {
    if (otherMenu) {
      let res = []
      let temp = otherValue.split(',')
      for (let i = 0; i < temp.length; i++) {
        res.push({
          name: temp[i],
        })
      }

      setNewValues(res)
      handleClose()
    }
  }

  useEffect(() => {
    if (otherMenu) {
      setTempDataList(dataList)
      let hello = []
      for (let i = 0; i < newValue.length; i++) {
        let duplicate = false
        for (let j = 0; j < tempDataList.length; j++) {
          if (newValue[i].name === tempDataList[j].name) {
            duplicate = true
          }
        }

        console.log(duplicate)
        if (duplicate === false) {
          hello.push(newValue[i])
        }
      }
      setTempDataList([...hello, ...tempDataList])
      console.log('Hello is', hello)
      console.log(newValue)

      let res = []

      for (let i = 0; i < newValue.length; i++) {
        res.push(newValue[i].name)
      }

      setSelectionState([...selectionState, ...res])
      // for (let i = 0; i < res.length; res++) {
      //   if (!selectionState.includes(res[i])) {
      //     selectionState.push(res[i])
      //   }
      // }
    }
  }, [newValue])

  const handleChange = (event) => {
    setSelectionState(event.target.value)
  }

  if (tempDataList && selectionState && setSelectionState && myvalue) {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={selectionState}
            onChange={handleChange}
            input={<Input />}
            renderValue={(selected) => selected.join(',')}
            MenuProps={MenuProps}
          >
            {otherMenu ? (
              <MenuItem onClick={handleOpen} value="">
                <Checkbox disabled />
                अन्य
              </MenuItem>
            ) : null}
            {tempDataList.map((data) => (
              <MenuItem key={data[myvalue]} value={data[myvalue]}>
                <Checkbox checked={selectionState.indexOf(data[showValue]) > -1} />
                {/* {console.log(data[showValue])} */}
                <ListItemText primary={data[showValue]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <MyDialog open={openOtherForm} onClose={handleClose}>
          <ContentBox title="अन्य मुद्दा">
            <form className="mb-3" onSubmit={(e) => e.preventDefault()}>
              <label>अन्य मुद्दा</label>
              <input
                type="text"
                className="form-control"
                placeholder="अन्य मुद्दा"
                value={otherValue}
                onChange={(e) => setOtherValue(convert_to_preeti(e.target.value))}
              />
            </form>
            {loading ? (
              <CircularProgress />
            ) : (
              <CButton color="primary" size="sm" onClick={addOtherToMenu}>
                Confirm
              </CButton>
            )}
            &nbsp;{' '}
            <CButton color="danger" size="sm" variant="outline" onClick={handleClose}>
              Close
            </CButton>
          </ContentBox>
        </MyDialog>
      </div>
    )
  }

  return <h4>Loading....</h4>
}
