import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import { CButton } from '@coreui/react'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AnusuchiFirst from 'src/views/pages/anusuchi/Anusuchi1'
import AnusuchiSecond from 'src/views/pages/anusuchi/Anusuchi2'
import AnusuchiThree from 'src/views/pages/anusuchi/Anusuchi3'
import AnusuchiOnePdf from 'src/views/pages/anusuchi/Anusuchi1Pdf'
import AnusuchiTwoPdf from 'src/views/pages/anusuchi/Anusuchi2Pdf'
import AnusuchiThreePdf from 'src/views/pages/anusuchi/Anusuchi3Pdf'
import { isAnusuchiValid } from 'src/views/pages/anusuchi/AnusuchiValidator'

import ReactToPdf from 'react-to-pdf'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { UserContext } from 'src/context/UserContext'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps() {
  return ['अनुसूची १', 'अनुसूची २', 'अनुसूची ३ ']
}

export default function MyStepper({
  initialState,
  handleChange,
  anusuchiValue,
  setAnusuchiValue,
  zilla,
  mudda,
  adalat,
  rastriyata,
  karyalaya,
  gadanaMiti,
  submit,
  response,
  setResponse,
  chuttimMiti,
  setChuttiMiti,
  muddaAntimMiti,
  setMuddaAntimMiti,
  muddaFaislaMiti,
  setMuddaFaislaMiti,
  punarabedanMiti,
  setPunarabendanMiti,
  kaidParekoMiti,
  setKaidParekoMiti,
  generatePdf,
  garekoKasurs,
  setGarekoKasurs,
  loading,
  allZilla,
  allSthaniya,
  editMode,
  editData,
  handleEditClose,
}) {
  const classes = useStyles()
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AnusuchiFirst
            initialState={initialState}
            handleChange={handleChange}
            anusuchiValue={anusuchiValue}
            setAnusuchiValue={setAnusuchiValue}
            zilla={zilla}
            mudda={mudda}
            adalat={adalat}
            karyalaya={karyalaya}
            rastriyata={rastriyata}
            kaidParekoMiti={kaidParekoMiti}
            setKaidParekoMiti={setKaidParekoMiti}
            chuttimMiti={chuttimMiti}
            setChuttiMiti={setChuttiMiti}
            gadanaMiti={gadanaMiti}
            garekoKasurs={garekoKasurs}
            setGarekoKasurs={setGarekoKasurs}
            allZilla={allZilla}
            allSthaniya={allSthaniya}
            editMode={editMode}
            editData={editData}
          />
        )
      case 1:
        return (
          <AnusuchiSecond
            allZilla={allZilla}
            initialState={initialState}
            handleChange={handleChange}
            anusuchiValue={anusuchiValue}
            setAnusuchiValue={setAnusuchiValue}
            zilla={zilla}
            mudda={mudda}
            adalat={adalat}
            karyalaya={karyalaya}
            rastriyata={rastriyata}
            muddaAntimMiti={muddaAntimMiti}
            setMuddaAntimMiti={setMuddaAntimMiti}
            allSthaniya={allSthaniya}
            editMode={editMode}
            editData={editData}
          />
        )
      case 2:
        return (
          <AnusuchiThree
            allZilla={allZilla}
            initialState={initialState}
            handleChange={handleChange}
            anusuchiValue={anusuchiValue}
            setAnusuchiValue={setAnusuchiValue}
            zilla={zilla}
            mudda={mudda}
            adalat={adalat}
            karyalaya={karyalaya}
            rastriyata={rastriyata}
            muddaFaislaMiti={muddaFaislaMiti}
            setMuddaFaislaMiti={setMuddaFaislaMiti}
            punarabedanMiti={punarabedanMiti}
            setPunarabendanMiti={setPunarabendanMiti}
            kaidParekoMiti={kaidParekoMiti}
            setKaidParekoMiti={setKaidParekoMiti}
            chuttimMiti={chuttimMiti}
            setChuttiMiti={setChuttiMiti}
            setMuddaAntimMiti={setMuddaAntimMiti}
            allSthaniya={allSthaniya}
            editMode={editMode}
            editData={editData}
          />
        )
      default:
        return 'Unknown step'
    }
  }
  const [parameter, setParameter] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const [user] = useContext(UserContext)

  useEffect(() => {
    if (user.role === 'superadmin') {
      setParameter([])
    } else {
      if (activeStep === 0) {
        setParameter([
          'Karagar_Karyalaya',
          'kaidi_ko_nam_thar',
          'kaidi_ko_desh',
          anusuchiValue.kaidi_ko_desh == 'नेपाल' ? 'kaidi_ko_jilla' : null,
          anusuchiValue.kaidi_ko_desh == 'नेपाल' ? 'kaidi_ko_sthaniya' : null,
          anusuchiValue.kaidi_ko_desh == 'नेपाल' ? 'kaidi_ko_wada' : null,

          'kaidi_ko_umer',
          'mudda',
          'bhayeko_sajaye',
          'kaid_pareko_miti',
          'nij_ko_mudda_nakaratmak',
          'kaaid_bhuktan_awadhi',
          'Kaid_ma_kunai_kisim_ko_anuchit_kam',
          // 'anuchid_kam_ko_bibaran',
          'karagar_pramuk_ko_raye',
          'chutti_janey_miti',
          'gadana_miti',
        ])
      } else if (activeStep === 1) {
        setParameter([
          'nibedako_nam_thar',
          'nibedako_desh',
          'baadi_ra_pratibadhiko_nam_thar',
          'baadi_ra_pratibadhiko_thegana',
          'mudda_aantim_bhayeko_miti',
          'adalat',
          'kaiid_katta_aadhar',
        ])
      } else {
        setParameter([
          'tokiyeko_kaiid',
          'jaherwala_ko_nam',
          'linga',
          'rastriyata',
          'linga',
          'rastriyata',
          'choteunu_parne_kaiid',
          'kaiid_katti_garne_upayukta',
          'kaaid_bhuktan_awadhi_percent',
          'kaaid_bhuktan_percent',
        ])
      }
    }
  }, [activeStep, user])

  useEffect(() => {
    setIsValid(isAnusuchiValid(parameter, anusuchiValue))
  }, [anusuchiValue, parameter])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setResponse({ success: null, error: null })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            {response ? (
              response.success ? (
                <Alert severity="success" className="mb-4">
                  तपाई को फर्म सफलतापुर्वक पेश गरिएको छ।
                </Alert>
              ) : null
            ) : null}

            {response ? (
              response.error ? (
                <Alert severity="error" className="mb-4">
                  {response.error}
                </Alert>
              ) : null
            ) : null}

            {/* <ReactToPdf options={options}>
              {({ toPdf, targetRef }) => (
                <>
                  <button onClick={toPdf}>Download</button>
                  <div ref={targetRef}>
                    <AnusuchiOnePdf />
                    <AnusuchiTwoPdf />
                    <AnusuchiThreePdf />
                  </div>
                </>
              )}
            </ReactToPdf> */}
            {response ? (
              response.success ? null : loading ? (
                <CircularProgress />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                  className={classes.button}
                  size="small"
                >
                  सेभ गर्नुहोस
                </Button>
              )
            ) : null}
            {/* <Button onClick={submit} className={classes.button}>
              Submit
            </Button> */}
            {/* {response ? (
              response.success ? ( */}
            {/* <CButton color="primary" onClick={generatePdf3} className={classes.button} size="sm">
              सबै डाउनलोड गर्नुहोस्
            </CButton> */}
            <Button
              color="secondary"
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
              size="small"
            >
              पछाडी
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              {editMode ? (
                <Button
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                  onClick={handleEditClose}
                >
                  Close
                </Button>
              ) : null}
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                पछाडी
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === steps.length - 1 ? handleNext : handleNext}
                className={classes.button}
                disabled={isValid ? false : true}
              >
                {activeStep === steps.length - 1 ? 'पेश गर्नुहोस्' : 'अर्को '}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
