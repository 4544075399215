import React from 'react'
import { NepaliNumber } from 'src/helpers/NepaliNumber'
import { MuddaAPI } from './MuddaAPI'
import { CONSTANTS } from '.././../../constants'

export const MuddaTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => NepaliNumber(rowData.tableData.id + 1),
      },
      {
        title: 'मुद्दा',
        field: 'name',
      },
    ]
  },

  getTableDatas: function (muddaes) {
    return muddaes
  },

  getDataActions: function (handleEditOpen, handleDeleteOpen) {
    return [
      {
        icon: 'edit',
        iconProps: {
          style: {
            color: CONSTANTS.EDIT_ICON_COLOR,
            background: CONSTANTS.EDIT_BACKGROUND_COLOUR,
          },
        },
        tooltip: 'Edit',
        onClick: (e, rowData) => handleEditOpen(rowData.id),
        position: 'row',
      },

      {
        icon: 'delete',
        iconProps: {
          style: {
            color: CONSTANTS.DELETE_ICON_COLOR,
            background: CONSTANTS.DELETE_BACKGROUND_COLOR,
          },
        },
        tooltip: 'Delete',
        onClick: (e, rowData) => handleDeleteOpen(rowData.id),
        position: 'row',
      },
    ]
  },
}
