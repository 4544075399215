/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { NepaliNumber } from 'src/helpers/NepaliNumber'
import { convert_to_preeti } from 'src/helpers/PreetiConverter'
import { useMemo } from 'react'

export default function NepaliCalendar({ title, setDate, date, yearLimit }) {
  // console.log(years.find((item) => item.english == date?.split('-')[0]))
  const [year, setYear] = React.useState(years[0])

  const [inputYear, setInputYear] = React.useState('')

  const [month, setMonth] = React.useState(months[0])
  const [inputMonth, setInputMonth] = React.useState('')

  const [day, setDay] = React.useState(days[0])
  const [inputDay, setInputDay] = React.useState('')

  const [reload, setReload] = useState(false)
  useMemo(() => {
    if (date) {
      setYear(years.find((item) => item.english == date?.split('-')[0]))
      setMonth(months.find((item) => item.id === date?.split('-')[1]))
      setDay(days.find((item) => item.english == date?.split('-')[2]))

      // setDate(date)
    }
  }, [reload])

  useMemo(() => {
    setReload(!reload)
  }, [date])

  useMemo(() => {
    if (year && month && day) {
      setDate(
        `${year.english ? year.english : 0}-${month.id ? month.id : 0}-${
          day.english ? day.english : 0
        }`,
      )
    }
  }, [year, month, day])

  return (
    <>
      {/* <h5>{title}</h5> */}
      <div className="row">
        <div className="col-md-4">
          <label>वर्ष</label>
          <Autocomplete
            value={year}
            getOptionSelected={(option, value) => option.english === value.english}
            getOptionLabel={(option) => option.nepali}
            size="small"
            onChange={(event, newValue) => {
              setYear(newValue)
            }}
            inputYear={inputYear}
            onInputChange={(event, newInputValue) => {
              setInputYear(convert_to_preeti(newInputValue))
            }}
            options={yearLimit ? years.filter((item) => item.english <= yearLimit) : years}
            renderInput={(params) => {
              return <TextField {...params} />
            }}
          />
        </div>

        <div className="col-md-4">
          <label>महिना</label>
          <Autocomplete
            value={month}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            size="small"
            onChange={(event, newValue) => {
              // console.log(newValue)
              setMonth(newValue)
            }}
            inputMonth={inputMonth}
            onInputChange={(event, newInputValue) => {
              setInputMonth(newInputValue)
            }}
            options={months}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>

        <div className="col-md-4">
          <label>दिन</label>
          <Autocomplete
            getOptionSelected={(option, value) => option.nepali === value.nepali}
            value={day}
            getOptionLabel={(option) => option.nepali}
            size="small"
            onChange={(event, newValue) => {
              setDay(newValue)
            }}
            inputDay={inputDay}
            onInputChange={(event, newInputValue) => {
              setInputDay(newInputValue)
            }}
            options={days}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>
    </>
  )
}

const years = [
  {
    english: 2000,
    nepali: '२०००',
  },
  {
    english: 2001,
    nepali: '२००१',
  },
  {
    english: 2002,
    nepali: '२००२',
  },
  {
    english: 2003,
    nepali: '२००३',
  },
  {
    english: 2004,
    nepali: '२००४',
  },
  {
    english: 2005,
    nepali: '२००५',
  },
  {
    english: 2006,
    nepali: '२००६',
  },
  {
    english: 2007,
    nepali: '२००७',
  },
  {
    english: 2008,
    nepali: '२००८',
  },
  {
    english: 2009,
    nepali: '२००९',
  },
  {
    english: 2010,
    nepali: '२०१०',
  },
  {
    english: 2011,
    nepali: '२०११',
  },
  {
    english: 2012,
    nepali: '२०१२',
  },
  {
    english: 2013,
    nepali: '२०१३',
  },
  {
    english: 2014,
    nepali: '२०१४',
  },
  {
    english: 2015,
    nepali: '२०१५',
  },
  {
    english: 2016,
    nepali: '२०१६',
  },
  {
    english: 2017,
    nepali: '२०१७',
  },
  {
    english: 2018,
    nepali: '२०१८',
  },
  {
    english: 2019,
    nepali: '२०१९',
  },
  {
    english: 2020,
    nepali: '२०२०',
  },
  {
    english: 2021,
    nepali: '२०२१',
  },
  {
    english: 2022,
    nepali: '२०२२',
  },
  {
    english: 2023,
    nepali: '२०२३',
  },
  {
    english: 2024,
    nepali: '२०२४',
  },
  {
    english: 2025,
    nepali: '२०२५',
  },
  {
    english: 2026,
    nepali: '२०२६',
  },
  {
    english: 2027,
    nepali: '२०२७',
  },
  {
    english: 2028,
    nepali: '२०२८',
  },
  {
    english: 2029,
    nepali: '२०२९',
  },
  {
    english: 2030,
    nepali: '२०३०',
  },
  {
    english: 2031,
    nepali: '२०३१',
  },
  {
    english: 2032,
    nepali: '२०३२',
  },
  {
    english: 2033,
    nepali: '२०३३',
  },
  {
    english: 2034,
    nepali: '२०३४',
  },
  {
    english: 2035,
    nepali: '२०३५',
  },
  {
    english: 2036,
    nepali: '२०३६',
  },
  {
    english: 2037,
    nepali: '२०३७',
  },
  {
    english: 2038,
    nepali: '२०३८',
  },
  {
    english: 2039,
    nepali: '२०३९',
  },
  {
    english: 2040,
    nepali: '२०४०',
  },
  {
    english: 2041,
    nepali: '२०४१',
  },
  {
    english: 2042,
    nepali: '२०४२',
  },
  {
    english: 2043,
    nepali: '२०४३',
  },
  {
    english: 2044,
    nepali: '२०४४',
  },
  {
    english: 2045,
    nepali: '२०४५',
  },
  {
    english: 2046,
    nepali: '२०४६',
  },
  {
    english: 2047,
    nepali: '२०४७',
  },
  {
    english: 2048,
    nepali: '२०४८',
  },
  {
    english: 2049,
    nepali: '२०४९',
  },
  {
    english: 2050,
    nepali: '२०५०',
  },
  {
    english: 2051,
    nepali: '२०५१',
  },
  {
    english: 2052,
    nepali: '२०५२',
  },
  {
    english: 2053,
    nepali: '२०५३',
  },
  {
    english: 2054,
    nepali: '२०५४',
  },
  {
    english: 2055,
    nepali: '२०५५',
  },
  {
    english: 2056,
    nepali: '२०५६',
  },
  {
    english: 2057,
    nepali: '२०५७',
  },
  {
    english: 2058,
    nepali: '२०५८',
  },
  {
    english: 2059,
    nepali: '२०५९',
  },
  {
    english: 2060,
    nepali: '२०६०',
  },
  {
    english: 2061,
    nepali: '२०६१',
  },
  {
    english: 2062,
    nepali: '२०६२',
  },
  {
    english: 2063,
    nepali: '२०६३',
  },
  {
    english: 2064,
    nepali: '२०६४',
  },
  {
    english: 2065,
    nepali: '२०६५',
  },
  {
    english: 2066,
    nepali: '२०६६',
  },
  {
    english: 2067,
    nepali: '२०६७',
  },
  {
    english: 2068,
    nepali: '२०६८',
  },
  {
    english: 2069,
    nepali: '२०६९',
  },
  {
    english: 2070,
    nepali: '२०७०',
  },
  {
    english: 2071,
    nepali: '२०७१',
  },
  {
    english: 2072,
    nepali: '२०७२',
  },
  {
    english: 2073,
    nepali: '२०७३',
  },
  {
    english: 2074,
    nepali: '२०७४',
  },
  {
    english: 2075,
    nepali: '२०७५',
  },
  {
    english: 2076,
    nepali: '२०७६',
  },
  {
    english: 2077,
    nepali: '२०७७',
  },
  {
    english: 2078,
    nepali: '२०७८',
  },
  {
    english: 2079,
    nepali: '२०७९',
  },
  {
    english: 2080,
    nepali: '२०८०',
  },
  {
    english: 2081,
    nepali: '२०८१',
  },
  {
    english: 2082,
    nepali: '२०८२',
  },
  {
    english: 2083,
    nepali: '२०८३',
  },
  {
    english: 2084,
    nepali: '२०८४',
  },
  {
    english: 2085,
    nepali: '२०८५',
  },
  {
    english: 2086,
    nepali: '२०८६',
  },
  {
    english: 2087,
    nepali: '२०८७',
  },
  {
    english: 2088,
    nepali: '२०८८',
  },
  {
    english: 2089,
    nepali: '२०८९',
  },
  {
    english: 2090,
    nepali: '२०९०',
  },
  {
    english: 2091,
    nepali: '२०९१',
  },
  {
    english: 2092,
    nepali: '२०९२',
  },
  {
    english: 2093,
    nepali: '२०९३',
  },
  {
    english: 2094,
    nepali: '२०९४',
  },
  {
    english: 2095,
    nepali: '२०९५',
  },
  {
    english: 2096,
    nepali: '२०९६',
  },
  {
    english: 2097,
    nepali: '२०९७',
  },
  {
    english: 2098,
    nepali: '२०९८',
  },
  {
    english: 2099,
    nepali: '२०९९',
  },
  {
    english: 2100,
    nepali: '२१००',
  },
  {
    english: 2101,
    nepali: '२१०१',
  },
  {
    english: 2102,
    nepali: '२१०२',
  },
  {
    english: 2103,
    nepali: '२१०३',
  },
  {
    english: 2104,
    nepali: '२१०४',
  },
  {
    english: 2105,
    nepali: '२१०५',
  },
  {
    english: 2106,
    nepali: '२१०६',
  },
  {
    english: 2107,
    nepali: '२१०७',
  },
  {
    english: 2108,
    nepali: '२१०८',
  },
  {
    english: 2109,
    nepali: '२१०९',
  },
  {
    english: 2110,
    nepali: '२११०',
  },
  {
    english: 2111,
    nepali: '२१११',
  },
  {
    english: 2112,
    nepali: '२११२',
  },
  {
    english: 2113,
    nepali: '२११३',
  },
  {
    english: 2114,
    nepali: '२११४',
  },
  {
    english: 2115,
    nepali: '२११५',
  },
  {
    english: 2116,
    nepali: '२११६',
  },
  {
    english: 2117,
    nepali: '२११७',
  },
  {
    english: 2118,
    nepali: '२११८',
  },
  {
    english: 2119,
    nepali: '२११९',
  },
  {
    english: 2120,
    nepali: '२१२०',
  },
  {
    english: 2121,
    nepali: '२१२१',
  },
  {
    english: 2122,
    nepali: '२१२२',
  },
  {
    english: 2123,
    nepali: '२१२३',
  },
  {
    english: 2124,
    nepali: '२१२४',
  },
  {
    english: 2125,
    nepali: '२१२५',
  },
  {
    english: 2126,
    nepali: '२१२६',
  },
  {
    english: 2127,
    nepali: '२१२७',
  },
  {
    english: 2128,
    nepali: '२१२८',
  },
  {
    english: 2129,
    nepali: '२१२९',
  },
  {
    english: 2130,
    nepali: '२१३०',
  },
  {
    english: 2131,
    nepali: '२१३१',
  },
  {
    english: 2132,
    nepali: '२१३२',
  },
  {
    english: 2133,
    nepali: '२१३३',
  },
  {
    english: 2134,
    nepali: '२१३४',
  },
  {
    english: 2135,
    nepali: '२१३५',
  },
  {
    english: 2136,
    nepali: '२१३६',
  },
  {
    english: 2137,
    nepali: '२१३७',
  },
  {
    english: 2138,
    nepali: '२१३८',
  },
  {
    english: 2139,
    nepali: '२१३९',
  },
  {
    english: 2140,
    nepali: '२१४०',
  },
  {
    english: 2141,
    nepali: '२१४१',
  },
  {
    english: 2142,
    nepali: '२१४२',
  },
  {
    english: 2143,
    nepali: '२१४३',
  },
  {
    english: 2144,
    nepali: '२१४४',
  },
  {
    english: 2145,
    nepali: '२१४५',
  },
  {
    english: 2146,
    nepali: '२१४६',
  },
  {
    english: 2147,
    nepali: '२१४७',
  },
  {
    english: 2148,
    nepali: '२१४८',
  },
  {
    english: 2149,
    nepali: '२१४९',
  },
  {
    english: 2150,
    nepali: '२१५०',
  },
  {
    english: 2151,
    nepali: '२१५१',
  },
  {
    english: 2152,
    nepali: '२१५२',
  },
  {
    english: 2153,
    nepali: '२१५३',
  },
  {
    english: 2154,
    nepali: '२१५४',
  },
  {
    english: 2155,
    nepali: '२१५५',
  },
  {
    english: 2156,
    nepali: '२१५६',
  },
  {
    english: 2157,
    nepali: '२१५७',
  },
  {
    english: 2158,
    nepali: '२१५८',
  },
  {
    english: 2159,
    nepali: '२१५९',
  },
  {
    english: 2160,
    nepali: '२१६०',
  },
  {
    english: 2161,
    nepali: '२१६१',
  },
  {
    english: 2162,
    nepali: '२१६२',
  },
  {
    english: 2163,
    nepali: '२१६३',
  },
  {
    english: 2164,
    nepali: '२१६४',
  },
  {
    english: 2165,
    nepali: '२१६५',
  },
  {
    english: 2166,
    nepali: '२१६६',
  },
  {
    english: 2167,
    nepali: '२१६७',
  },
  {
    english: 2168,
    nepali: '२१६८',
  },
  {
    english: 2169,
    nepali: '२१६९',
  },
  {
    english: 2170,
    nepali: '२१७०',
  },
  {
    english: 2171,
    nepali: '२१७१',
  },
  {
    english: 2172,
    nepali: '२१७२',
  },
  {
    english: 2173,
    nepali: '२१७३',
  },
  {
    english: 2174,
    nepali: '२१७४',
  },
  {
    english: 2175,
    nepali: '२१७५',
  },
  {
    english: 2176,
    nepali: '२१७६',
  },
  {
    english: 2177,
    nepali: '२१७७',
  },
  {
    english: 2178,
    nepali: '२१७८',
  },
  {
    english: 2179,
    nepali: '२१७९',
  },
  {
    english: 2180,
    nepali: '२१८०',
  },
  {
    english: 2181,
    nepali: '२१८१',
  },
  {
    english: 2182,
    nepali: '२१८२',
  },
  {
    english: 2183,
    nepali: '२१८३',
  },
  {
    english: 2184,
    nepali: '२१८४',
  },
  {
    english: 2185,
    nepali: '२१८५',
  },
  {
    english: 2186,
    nepali: '२१८६',
  },
  {
    english: 2187,
    nepali: '२१८७',
  },
  {
    english: 2188,
    nepali: '२१८८',
  },
  {
    english: 2189,
    nepali: '२१८९',
  },
  {
    english: 2190,
    nepali: '२१९०',
  },
  {
    english: 2191,
    nepali: '२१९१',
  },
  {
    english: 2192,
    nepali: '२१९२',
  },
  {
    english: 2193,
    nepali: '२१९३',
  },
  {
    english: 2194,
    nepali: '२१९४',
  },
  {
    english: 2195,
    nepali: '२१९५',
  },
  {
    english: 2196,
    nepali: '२१९६',
  },
  {
    english: 2197,
    nepali: '२१९७',
  },
  {
    english: 2198,
    nepali: '२१९८',
  },
  {
    english: 2199,
    nepali: '२१९९',
  },
  {
    english: 2200,
    nepali: '२२००',
  },
  {
    english: 2201,
    nepali: '२२०१',
  },
  {
    english: 2202,
    nepali: '२२०२',
  },
  {
    english: 2203,
    nepali: '२२०३',
  },
  {
    english: 2204,
    nepali: '२२०४',
  },
  {
    english: 2205,
    nepali: '२२०५',
  },
  {
    english: 2206,
    nepali: '२२०६',
  },
  {
    english: 2207,
    nepali: '२२०७',
  },
  {
    english: 2208,
    nepali: '२२०८',
  },
  {
    english: 2209,
    nepali: '२२०९',
  },
  {
    english: 2210,
    nepali: '२२१०',
  },
  {
    english: 2211,
    nepali: '२२११',
  },
  {
    english: 2212,
    nepali: '२२१२',
  },
  {
    english: 2213,
    nepali: '२२१३',
  },
  {
    english: 2214,
    nepali: '२२१४',
  },
  {
    english: 2215,
    nepali: '२२१५',
  },
  {
    english: 2216,
    nepali: '२२१६',
  },
  {
    english: 2217,
    nepali: '२२१७',
  },
  {
    english: 2218,
    nepali: '२२१८',
  },
  {
    english: 2219,
    nepali: '२२१९',
  },
  {
    english: 2220,
    nepali: '२२२०',
  },
  {
    english: 2221,
    nepali: '२२२१',
  },
  {
    english: 2222,
    nepali: '२२२२',
  },
  {
    english: 2223,
    nepali: '२२२३',
  },
  {
    english: 2224,
    nepali: '२२२४',
  },
  {
    english: 2225,
    nepali: '२२२५',
  },
  {
    english: 2226,
    nepali: '२२२६',
  },
  {
    english: 2227,
    nepali: '२२२७',
  },
  {
    english: 2228,
    nepali: '२२२८',
  },
  {
    english: 2229,
    nepali: '२२२९',
  },
  {
    english: 2230,
    nepali: '२२३०',
  },
  {
    english: 2231,
    nepali: '२२३१',
  },
  {
    english: 2232,
    nepali: '२२३२',
  },
  {
    english: 2233,
    nepali: '२२३३',
  },
  {
    english: 2234,
    nepali: '२२३४',
  },
  {
    english: 2235,
    nepali: '२२३५',
  },
  {
    english: 2236,
    nepali: '२२३६',
  },
  {
    english: 2237,
    nepali: '२२३७',
  },
  {
    english: 2238,
    nepali: '२२३८',
  },
  {
    english: 2239,
    nepali: '२२३९',
  },
  {
    english: 2240,
    nepali: '२२४०',
  },
  {
    english: 2241,
    nepali: '२२४१',
  },
  {
    english: 2242,
    nepali: '२२४२',
  },
  {
    english: 2243,
    nepali: '२२४३',
  },
  {
    english: 2244,
    nepali: '२२४४',
  },
  {
    english: 2245,
    nepali: '२२४५',
  },
  {
    english: 2246,
    nepali: '२२४६',
  },
  {
    english: 2247,
    nepali: '२२४७',
  },
  {
    english: 2248,
    nepali: '२२४८',
  },
  {
    english: 2249,
    nepali: '२२४९',
  },
  {
    english: 2250,
    nepali: '२२५०',
  },
  {
    english: 2251,
    nepali: '२२५१',
  },
  {
    english: 2252,
    nepali: '२२५२',
  },
  {
    english: 2253,
    nepali: '२२५३',
  },
  {
    english: 2254,
    nepali: '२२५४',
  },
  {
    english: 2255,
    nepali: '२२५५',
  },
  {
    english: 2256,
    nepali: '२२५६',
  },
  {
    english: 2257,
    nepali: '२२५७',
  },
  {
    english: 2258,
    nepali: '२२५८',
  },
  {
    english: 2259,
    nepali: '२२५९',
  },
  {
    english: 2260,
    nepali: '२२६०',
  },
  {
    english: 2261,
    nepali: '२२६१',
  },
  {
    english: 2262,
    nepali: '२२६२',
  },
  {
    english: 2263,
    nepali: '२२६३',
  },
  {
    english: 2264,
    nepali: '२२६४',
  },
  {
    english: 2265,
    nepali: '२२६५',
  },
  {
    english: 2266,
    nepali: '२२६६',
  },
  {
    english: 2267,
    nepali: '२२६७',
  },
  {
    english: 2268,
    nepali: '२२६८',
  },
  {
    english: 2269,
    nepali: '२२६९',
  },
  {
    english: 2270,
    nepali: '२२७०',
  },
  {
    english: 2271,
    nepali: '२२७१',
  },
  {
    english: 2272,
    nepali: '२२७२',
  },
  {
    english: 2273,
    nepali: '२२७३',
  },
  {
    english: 2274,
    nepali: '२२७४',
  },
  {
    english: 2275,
    nepali: '२२७५',
  },
  {
    english: 2276,
    nepali: '२२७६',
  },
  {
    english: 2277,
    nepali: '२२७७',
  },
  {
    english: 2278,
    nepali: '२२७८',
  },
  {
    english: 2279,
    nepali: '२२७९',
  },
  {
    english: 2280,
    nepali: '२२८०',
  },
  {
    english: 2281,
    nepali: '२२८१',
  },
  {
    english: 2282,
    nepali: '२२८२',
  },
  {
    english: 2283,
    nepali: '२२८३',
  },
  {
    english: 2284,
    nepali: '२२८४',
  },
  {
    english: 2285,
    nepali: '२२८५',
  },
  {
    english: 2286,
    nepali: '२२८६',
  },
  {
    english: 2287,
    nepali: '२२८७',
  },
  {
    english: 2288,
    nepali: '२२८८',
  },
  {
    english: 2289,
    nepali: '२२८९',
  },
  {
    english: 2290,
    nepali: '२२९०',
  },
  {
    english: 2291,
    nepali: '२२९१',
  },
  {
    english: 2292,
    nepali: '२२९२',
  },
  {
    english: 2293,
    nepali: '२२९३',
  },
  {
    english: 2294,
    nepali: '२२९४',
  },
  {
    english: 2295,
    nepali: '२२९५',
  },
  {
    english: 2296,
    nepali: '२२९६',
  },
  {
    english: 2297,
    nepali: '२२९७',
  },
  {
    english: 2298,
    nepali: '२२९८',
  },
  {
    english: 2299,
    nepali: '२२९९',
  },
  {
    english: 2300,
    nepali: '२३००',
  },
  {
    english: 2301,
    nepali: '२३०१',
  },
  {
    english: 2302,
    nepali: '२३०२',
  },
  {
    english: 2303,
    nepali: '२३०३',
  },
  {
    english: 2304,
    nepali: '२३०४',
  },
  {
    english: 2305,
    nepali: '२३०५',
  },
  {
    english: 2306,
    nepali: '२३०६',
  },
  {
    english: 2307,
    nepali: '२३०७',
  },
  {
    english: 2308,
    nepali: '२३०८',
  },
  {
    english: 2309,
    nepali: '२३०९',
  },
  {
    english: 2310,
    nepali: '२३१०',
  },
  {
    english: 2311,
    nepali: '२३११',
  },
  {
    english: 2312,
    nepali: '२३१२',
  },
  {
    english: 2313,
    nepali: '२३१३',
  },
  {
    english: 2314,
    nepali: '२३१४',
  },
  {
    english: 2315,
    nepali: '२३१५',
  },
  {
    english: 2316,
    nepali: '२३१६',
  },
  {
    english: 2317,
    nepali: '२३१७',
  },
  {
    english: 2318,
    nepali: '२३१८',
  },
  {
    english: 2319,
    nepali: '२३१९',
  },
  {
    english: 2320,
    nepali: '२३२०',
  },
  {
    english: 2321,
    nepali: '२३२१',
  },
  {
    english: 2322,
    nepali: '२३२२',
  },
  {
    english: 2323,
    nepali: '२३२३',
  },
  {
    english: 2324,
    nepali: '२३२४',
  },
  {
    english: 2325,
    nepali: '२३२५',
  },
  {
    english: 2326,
    nepali: '२३२६',
  },
  {
    english: 2327,
    nepali: '२३२७',
  },
  {
    english: 2328,
    nepali: '२३२८',
  },
  {
    english: 2329,
    nepali: '२३२९',
  },
  {
    english: 2330,
    nepali: '२३३०',
  },
  {
    english: 2331,
    nepali: '२३३१',
  },
  {
    english: 2332,
    nepali: '२३३२',
  },
  {
    english: 2333,
    nepali: '२३३३',
  },
  {
    english: 2334,
    nepali: '२३३४',
  },
  {
    english: 2335,
    nepali: '२३३५',
  },
  {
    english: 2336,
    nepali: '२३३६',
  },
  {
    english: 2337,
    nepali: '२३३७',
  },
  {
    english: 2338,
    nepali: '२३३८',
  },
  {
    english: 2339,
    nepali: '२३३९',
  },
  {
    english: 2340,
    nepali: '२३४०',
  },
  {
    english: 2341,
    nepali: '२३४१',
  },
  {
    english: 2342,
    nepali: '२३४२',
  },
  {
    english: 2343,
    nepali: '२३४३',
  },
  {
    english: 2344,
    nepali: '२३४४',
  },
  {
    english: 2345,
    nepali: '२३४५',
  },
  {
    english: 2346,
    nepali: '२३४६',
  },
  {
    english: 2347,
    nepali: '२३४७',
  },
  {
    english: 2348,
    nepali: '२३४८',
  },
  {
    english: 2349,
    nepali: '२३४९',
  },
  {
    english: 2350,
    nepali: '२३५०',
  },
  {
    english: 2351,
    nepali: '२३५१',
  },
  {
    english: 2352,
    nepali: '२३५२',
  },
  {
    english: 2353,
    nepali: '२३५३',
  },
  {
    english: 2354,
    nepali: '२३५४',
  },
  {
    english: 2355,
    nepali: '२३५५',
  },
  {
    english: 2356,
    nepali: '२३५६',
  },
  {
    english: 2357,
    nepali: '२३५७',
  },
  {
    english: 2358,
    nepali: '२३५८',
  },
  {
    english: 2359,
    nepali: '२३५९',
  },
  {
    english: 2360,
    nepali: '२३६०',
  },
  {
    english: 2361,
    nepali: '२३६१',
  },
  {
    english: 2362,
    nepali: '२३६२',
  },
  {
    english: 2363,
    nepali: '२३६३',
  },
  {
    english: 2364,
    nepali: '२३६४',
  },
  {
    english: 2365,
    nepali: '२३६५',
  },
  {
    english: 2366,
    nepali: '२३६६',
  },
  {
    english: 2367,
    nepali: '२३६७',
  },
  {
    english: 2368,
    nepali: '२३६८',
  },
  {
    english: 2369,
    nepali: '२३६९',
  },
  {
    english: 2370,
    nepali: '२३७०',
  },
  {
    english: 2371,
    nepali: '२३७१',
  },
  {
    english: 2372,
    nepali: '२३७२',
  },
  {
    english: 2373,
    nepali: '२३७३',
  },
  {
    english: 2374,
    nepali: '२३७४',
  },
  {
    english: 2375,
    nepali: '२३७५',
  },
  {
    english: 2376,
    nepali: '२३७६',
  },
  {
    english: 2377,
    nepali: '२३७७',
  },
  {
    english: 2378,
    nepali: '२३७८',
  },
  {
    english: 2379,
    nepali: '२३७९',
  },
  {
    english: 2380,
    nepali: '२३८०',
  },
  {
    english: 2381,
    nepali: '२३८१',
  },
  {
    english: 2382,
    nepali: '२३८२',
  },
  {
    english: 2383,
    nepali: '२३८३',
  },
  {
    english: 2384,
    nepali: '२३८४',
  },
  {
    english: 2385,
    nepali: '२३८५',
  },
  {
    english: 2386,
    nepali: '२३८६',
  },
  {
    english: 2387,
    nepali: '२३८७',
  },
  {
    english: 2388,
    nepali: '२३८८',
  },
  {
    english: 2389,
    nepali: '२३८९',
  },
  {
    english: 2390,
    nepali: '२३९०',
  },
  {
    english: 2391,
    nepali: '२३९१',
  },
  {
    english: 2392,
    nepali: '२३९२',
  },
  {
    english: 2393,
    nepali: '२३९३',
  },
  {
    english: 2394,
    nepali: '२३९४',
  },
  {
    english: 2395,
    nepali: '२३९५',
  },
  {
    english: 2396,
    nepali: '२३९६',
  },
  {
    english: 2397,
    nepali: '२३९७',
  },
  {
    english: 2398,
    nepali: '२३९८',
  },
  {
    english: 2399,
    nepali: '२३९९',
  },
  {
    english: 2400,
    nepali: '२४००',
  },
  {
    english: 2401,
    nepali: '२४०१',
  },
  {
    english: 2402,
    nepali: '२४०२',
  },
  {
    english: 2403,
    nepali: '२४०३',
  },
  {
    english: 2404,
    nepali: '२४०४',
  },
  {
    english: 2405,
    nepali: '२४०५',
  },
  {
    english: 2406,
    nepali: '२४०६',
  },
  {
    english: 2407,
    nepali: '२४०७',
  },
  {
    english: 2408,
    nepali: '२४०८',
  },
  {
    english: 2409,
    nepali: '२४०९',
  },
  {
    english: 2410,
    nepali: '२४१०',
  },
  {
    english: 2411,
    nepali: '२४११',
  },
  {
    english: 2412,
    nepali: '२४१२',
  },
  {
    english: 2413,
    nepali: '२४१३',
  },
  {
    english: 2414,
    nepali: '२४१४',
  },
  {
    english: 2415,
    nepali: '२४१५',
  },
  {
    english: 2416,
    nepali: '२४१६',
  },
  {
    english: 2417,
    nepali: '२४१७',
  },
  {
    english: 2418,
    nepali: '२४१८',
  },
  {
    english: 2419,
    nepali: '२४१९',
  },
  {
    english: 2420,
    nepali: '२४२०',
  },
  {
    english: 2421,
    nepali: '२४२१',
  },
  {
    english: 2422,
    nepali: '२४२२',
  },
  {
    english: 2423,
    nepali: '२४२३',
  },
  {
    english: 2424,
    nepali: '२४२४',
  },
  {
    english: 2425,
    nepali: '२४२५',
  },
  {
    english: 2426,
    nepali: '२४२६',
  },
  {
    english: 2427,
    nepali: '२४२७',
  },
  {
    english: 2428,
    nepali: '२४२८',
  },
  {
    english: 2429,
    nepali: '२४२९',
  },
  {
    english: 2430,
    nepali: '२४३०',
  },
  {
    english: 2431,
    nepali: '२४३१',
  },
  {
    english: 2432,
    nepali: '२४३२',
  },
  {
    english: 2433,
    nepali: '२४३३',
  },
  {
    english: 2434,
    nepali: '२४३४',
  },
  {
    english: 2435,
    nepali: '२४३५',
  },
  {
    english: 2436,
    nepali: '२४३६',
  },
  {
    english: 2437,
    nepali: '२४३७',
  },
  {
    english: 2438,
    nepali: '२४३८',
  },
  {
    english: 2439,
    nepali: '२४३९',
  },
  {
    english: 2440,
    nepali: '२४४०',
  },
  {
    english: 2441,
    nepali: '२४४१',
  },
  {
    english: 2442,
    nepali: '२४४२',
  },
  {
    english: 2443,
    nepali: '२४४३',
  },
  {
    english: 2444,
    nepali: '२४४४',
  },
  {
    english: 2445,
    nepali: '२४४५',
  },
  {
    english: 2446,
    nepali: '२४४६',
  },
  {
    english: 2447,
    nepali: '२४४७',
  },
  {
    english: 2448,
    nepali: '२४४८',
  },
  {
    english: 2449,
    nepali: '२४४९',
  },
  {
    english: 2450,
    nepali: '२४५०',
  },
  {
    english: 2451,
    nepali: '२४५१',
  },
  {
    english: 2452,
    nepali: '२४५२',
  },
  {
    english: 2453,
    nepali: '२४५३',
  },
  {
    english: 2454,
    nepali: '२४५४',
  },
  {
    english: 2455,
    nepali: '२४५५',
  },
  {
    english: 2456,
    nepali: '२४५६',
  },
  {
    english: 2457,
    nepali: '२४५७',
  },
  {
    english: 2458,
    nepali: '२४५८',
  },
  {
    english: 2459,
    nepali: '२४५९',
  },
  {
    english: 2460,
    nepali: '२४६०',
  },
  {
    english: 2461,
    nepali: '२४६१',
  },
  {
    english: 2462,
    nepali: '२४६२',
  },
  {
    english: 2463,
    nepali: '२४६३',
  },
  {
    english: 2464,
    nepali: '२४६४',
  },
  {
    english: 2465,
    nepali: '२४६५',
  },
  {
    english: 2466,
    nepali: '२४६६',
  },
  {
    english: 2467,
    nepali: '२४६७',
  },
  {
    english: 2468,
    nepali: '२४६८',
  },
  {
    english: 2469,
    nepali: '२४६९',
  },
  {
    english: 2470,
    nepali: '२४७०',
  },
  {
    english: 2471,
    nepali: '२४७१',
  },
  {
    english: 2472,
    nepali: '२४७२',
  },
  {
    english: 2473,
    nepali: '२४७३',
  },
  {
    english: 2474,
    nepali: '२४७४',
  },
  {
    english: 2475,
    nepali: '२४७५',
  },
  {
    english: 2476,
    nepali: '२४७६',
  },
  {
    english: 2477,
    nepali: '२४७७',
  },
  {
    english: 2478,
    nepali: '२४७८',
  },
  {
    english: 2479,
    nepali: '२४७९',
  },
  {
    english: 2480,
    nepali: '२४८०',
  },
  {
    english: 2481,
    nepali: '२४८१',
  },
  {
    english: 2482,
    nepali: '२४८२',
  },
  {
    english: 2483,
    nepali: '२४८३',
  },
  {
    english: 2484,
    nepali: '२४८४',
  },
  {
    english: 2485,
    nepali: '२४८५',
  },
  {
    english: 2486,
    nepali: '२४८६',
  },
  {
    english: 2487,
    nepali: '२४८७',
  },
  {
    english: 2488,
    nepali: '२४८८',
  },
  {
    english: 2489,
    nepali: '२४८९',
  },
  {
    english: 2490,
    nepali: '२४९०',
  },
  {
    english: 2491,
    nepali: '२४९१',
  },
  {
    english: 2492,
    nepali: '२४९२',
  },
  {
    english: 2493,
    nepali: '२४९३',
  },
  {
    english: 2494,
    nepali: '२४९४',
  },
  {
    english: 2495,
    nepali: '२४९५',
  },
  {
    english: 2496,
    nepali: '२४९६',
  },
  {
    english: 2497,
    nepali: '२४९७',
  },
  {
    english: 2498,
    nepali: '२४९८',
  },
  {
    english: 2499,
    nepali: '२४९९',
  },
]
const months = [
  {
    id: '1',
    name: 'वैशाख',
  },

  {
    id: '2',
    name: 'जेष्ठ',
  },
  {
    id: '3',
    name: 'आषाढ़',
  },
  {
    id: '4',
    name: 'श्रावण',
  },
  {
    id: '5',
    name: 'भदौ',
  },
  {
    id: '6',
    name: 'आश्विन',
  },
  {
    id: '7',
    name: 'कार्तिक',
  },
  {
    id: '8',
    name: 'मंसिर',
  },
  {
    id: '9',
    name: 'पौष',
  },
  {
    id: '10',
    name: 'माघ',
  },
  {
    id: '11',
    name: 'फाल्गुण',
  },
  {
    id: '12',
    name: 'चैत्र',
  },
]
const days = [
  {
    english: 1,
    nepali: '१',
  },
  {
    english: 2,
    nepali: '२',
  },
  {
    english: 3,
    nepali: '३',
  },
  {
    english: 4,
    nepali: '४',
  },
  {
    english: 5,
    nepali: '५',
  },
  {
    english: 6,
    nepali: '६',
  },
  {
    english: 7,
    nepali: '७',
  },
  {
    english: 8,
    nepali: '८',
  },
  {
    english: 9,
    nepali: '९',
  },
  {
    english: 10,
    nepali: '१०',
  },
  {
    english: 11,
    nepali: '११',
  },
  {
    english: 12,
    nepali: '१२',
  },
  {
    english: 13,
    nepali: '१३',
  },
  {
    english: 14,
    nepali: '१४',
  },
  {
    english: 15,
    nepali: '१५',
  },
  {
    english: 16,
    nepali: '१६',
  },
  {
    english: 17,
    nepali: '१७',
  },
  {
    english: 18,
    nepali: '१८',
  },
  {
    english: 19,
    nepali: '१९',
  },
  {
    english: 20,
    nepali: '२०',
  },
  {
    english: 21,
    nepali: '२१',
  },
  {
    english: 22,
    nepali: '२२',
  },
  {
    english: 23,
    nepali: '२३',
  },
  {
    english: 24,
    nepali: '२४',
  },
  {
    english: 25,
    nepali: '२५',
  },
  {
    english: 26,
    nepali: '२६',
  },
  {
    english: 27,
    nepali: '२७',
  },
  {
    english: 28,
    nepali: '२८',
  },
  {
    english: 29,
    nepali: '२९',
  },
  {
    english: 30,
    nepali: '३०',
  },
  {
    english: 31,
    nepali: '३१',
  },
  {
    english: 32,
    nepali: '३२',
  },
]

const allYears = () => {
  let res = []

  for (let i = 2000; i < 2; i++) {
    res.push({
      english: i,
      nepali: NepaliNumber(i),
    })
  }

  return res
}

console.log(allYears())
