import React, { Suspense, useState, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import { AdminRoutes, ZillaAdminRoutes } from '../routes'
import { UserContext } from '../context/UserContext'

const AppContent = () => {
  const [user] = useContext(UserContext)

  // console.log('User Role is', user.role)
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Switch>
          {user.role === 'superadmin'
            ? AdminRoutes.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <>
                          <route.component {...props} />
                        </>
                      )}
                    />
                  )
                )
              })
            : ZillaAdminRoutes.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <>
                          <route.component {...props} />
                        </>
                      )}
                    />
                  )
                )
              })}

          {/* <Redirect from="/" to="/dashboard" /> */}
        </Switch>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
