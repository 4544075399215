import React, { useState } from 'react'
import OldAnusuchiForm from './OldAnusuchiForm'
import OldAnusuchiList from './OldAnusuchiList'

const OldAnusuchi = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      {/* <OldAnusuchiForm reload={reload} setReload={setReload} /> */}
      <OldAnusuchiList reload={reload} setReload={setReload} />
    </>
  )
}
export default OldAnusuchi
