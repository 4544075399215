import React, { useEffect, useState } from 'react'
import { NepaliDatePicker } from 'nepali-datepicker-reactjs'
import 'nepali-datepicker-reactjs/dist/index.css'
import { allZilla } from 'src/helpers/ZillaProvider'
import Calendar from '@sbmdkl/nepali-datepicker-reactjs'
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css'
import NepaliCalendar from 'src/components/NepaliCalendar'
import { convert_to_preeti } from 'src/helpers/PreetiConverter'

import {
  CFormInput,
  CForm,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CButton,
  CFormTextarea,
} from '@coreui/react'
const AnusuchiThree = ({
  handleChange,
  anusuchiValue,
  mudda,
  adalat,
  rastriyata,
  karyalaya,
  setPunarabendanMiti,
  setKaidParekoMiti,
  setChuttiMiti,
  setMuddaFaislaMiti,
  zilla,
  setMuddaAntimMiti,
  allZilla,
}) => {
  const [anusuchiYear, setAnusuchiYear] = useState('')
  const [anusuchiPercent, setAnusuchiPercent] = useState('')

  console.log('Data is', anusuchiValue.kaaid_bhuktan_awadhi_percent)

  useEffect(() => {
    if (anusuchiValue) {
      if (anusuchiValue.choteunu_parne_kaiid) {
        console.log('Data is', anusuchiValue.choteunu_parne_kaiid)
        let data = anusuchiValue.choteunu_parne_kaiid.split('/')
        let year = ''
        for (let i = 0; i < 3; i++) {
          if (i === 0) {
            year = data[i]
          } else {
            year = year + '/' + data[i]
          }
        }
        setAnusuchiYear(year)
        setAnusuchiPercent(`${data[3]} %`)
      }
    }
  }, [anusuchiValue.choteunu_parne_kaiid])
  return (
    <div>
      <h4 className="text-center mb-5 form-header">अनुसूची-३ फारम</h4>
      <CForm className="row g-3 mb-5">
        {/* Karyalaya */}
        {/* <CCol md={6}>
          <CFormLabel htmlFor="karyalaya">कारागार कार्यालय </CFormLabel>

          <CFormSelect id="karyalaya">
            <option>छान्नुहोस...</option>
          </CFormSelect>
        </CCol> */}

        {/* Name */}

        {/* Age */}
        {/* <CCol md={6}>
          <CFormLabel htmlFor="age">तोकिएको कैद (प्रतिसत)</CFormLabel>

          <CFormInput
            type="text"
            id="tokiyeko_kaiid"
            required
            name="tokiyeko_kaiid"
            onChange={handleChange}
            value={anusuchiValue.tokiyeko_kaiid}
          />
        </CCol> */}
        <CCol md={6}>
          <CFormLabel htmlFor="name">
            कैदीको पुरा नाम ,थर <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            disabled
            type="text"
            id="name"
            required
            name="kaidi_ko_nam_thar"
            onChange={handleChange}
            value={anusuchiValue.kaidi_ko_nam_thar}
          />
        </CCol>

        {/* Address */}
        {/* <CCol md={6}>
          <CFormLabel htmlFor="name">कैदीको ठेगाना</CFormLabel>

          <CFormInput
            type="text"
            id="name"
            required
            name="kaidi_ko_thegana"
            onChange={handleChange}
            value={anusuchiValue.kaidi_ko_thegana}
          />
        </CCol> */}

        {/* Age */}
        <CCol md={6}>
          <CFormLabel htmlFor="age">
            कैदीको उमेर <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            id="age"
            required
            name="kaidi_ko_umer"
            onChange={handleChange}
            value={anusuchiValue.kaidi_ko_umer}
            disabled
          />
        </CCol>

        {/*Jaherwala Name */}
        <CCol md={6}>
          <CFormLabel htmlFor="jaherwala-naam">
            जाहेरवालाको नाम <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="text"
            id="jaherwala-naam"
            required
            name="jaherwala_ko_nam"
            onChange={handleChange}
            value={anusuchiValue.jaherwala_ko_nam}
          />
        </CCol>

        {/* nikaya*/}
        <CCol md={6}>
          <CFormLabel htmlFor="nikaya">
            मुद्दाको अन्तिम कारवाही गर्ने निकाय <span className="required">*</span>
          </CFormLabel>

          <CFormSelect
            id="mudda"
            disabled
            name="adalat"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.adalat}
          >
            <option value="">छान्नुहोस...</option>
            {adalat
              ? adalat.map((item, id) => (
                  <option key={id} value={item.name}>
                    {item.name}
                  </option>
                ))
              : null}
          </CFormSelect>
        </CCol>

        {/* faisla Miti */}
        <CCol md={6}>
          <CFormLabel htmlFor="nikaya">
            मुद्दाको अन्तिम फैसला मिति <span className="required">*</span>
          </CFormLabel>

          <NepaliCalendar
            setDate={setMuddaAntimMiti}
            date={anusuchiValue.mudda_aantim_bhayeko_miti}
          />

          {/* <Calendar
            className="form-control date-control"
            language="en"
            onChange={(date) => setMuddaAntimMiti(date.bsDate)}
          /> */}
          {/* <NepaliDatePicker
            inputClassName="form-control date-control"
            className=""
            name="antim_faisala_miti"
            onChange={(value) => setMuddaAntimMiti(value)}
            // onChange={(e) => handleChange(e)}
            value={anusuchiValue.mudda_aantim_bhayeko_miti}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          /> */}

          {/* <CFormInput
            type="date"
            id="nikaya"
            required
            name="antim_faisala_miti"
            onChange={handleChange}
            value={anusuchiValue.antim_faisala_miti}
          /> */}
        </CCol>

        {/* Gender */}
        <CCol md={4}>
          <div onChange={handleChange} value={anusuchiValue.linga}>
            <CFormLabel htmlFor="pareko-napareko">
              लिङ्ग <span className="required">*</span>
            </CFormLabel>
            <br />
            <CFormCheck
              inline
              type="radio"
              name="linga"
              id="male"
              value="पुरुष"
              label="पुरुष"
              checked={anusuchiValue.linga === 'पुरुष'}
            />

            <CFormCheck
              inline
              type="radio"
              name="linga"
              id="female"
              value="महिला"
              label="महिला"
              checked={anusuchiValue.linga === 'महिला'}
            />
            <CFormCheck
              inline
              type="radio"
              name="linga"
              id="other"
              value="अन्य"
              label="अन्य"
              checked={anusuchiValue.linga === 'अन्य'}
            />
          </div>
        </CCol>

        {/* Rastiyata */}
        <CCol md={4}>
          <CFormLabel htmlFor="mudda">
            राष्ट्रियता <span className="required">*</span>
          </CFormLabel>

          <CFormSelect
            id="mudda"
            name="rastriyata"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.rastriyata}
            disabled
          >
            <option value="">छान्नुहोस...</option>
            <option>नेपाल</option>
            {rastriyata
              ? rastriyata.map((item, id) => (
                  <option key={id} value={item.name}>
                    {item.name}
                  </option>
                ))
              : null}
          </CFormSelect>
        </CCol>

        {/* mudda */}
        <CCol md={4}>
          <CFormLabel htmlFor="mudda">
            मुद्दा <span className="required">*</span>
          </CFormLabel>
          <CFormInput value={anusuchiValue.mudda.map((item) => item)} disabled={true} />

          {/* <CFormSelect
            id="mudda"
            name="mudda"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.mudda}
          >
            <option value="">छान्नुहोस...</option>
            {mudda
              ? mudda.map((item, id) => (
                  <option key={id} value={item.name}>
                    {item.name}
                  </option>
                ))
              : null}
          </CFormSelect> */}
        </CCol>

        {/* Pareko napareko */}
        <CCol md={4}>
          <div onChange={handleChange} value={anusuchiValue.nij_ko_mudda_nakaratmak}>
            <CFormLabel htmlFor="pareko-napareko">
              निजको मुद्दा नकारात्मक सूचीमा <span className="required">*</span>
            </CFormLabel>
            <br />
            <CFormCheck
              inline
              type="radio"
              name="nij_ko_mudda_nakaratmak"
              id="pareko"
              value="परेको"
              label="परेको"
              checked={anusuchiValue.nij_ko_mudda_nakaratmak === 'परेको'}
            />

            <CFormCheck
              inline
              type="radio"
              name="nij_ko_mudda_nakaratmak"
              id="napareko"
              value="नपरेको"
              label="नपरेको"
              checked={anusuchiValue.nij_ko_mudda_nakaratmak === 'नपरेको'}
            />
          </div>
        </CCol>

        {/* Punarabendan */}

        <CCol md={12}>
          <div style={{ padding: '1.5rem', border: '2px solid  #000' }}>
            <p>पुनरावेदन मा नपरेको प्रमाण</p>
            <CRow>
              <CCol md="6">
                <CFormLabel htmlFor="mudda">कार्यालय</CFormLabel>

                <CFormSelect
                  id="mudda"
                  name="punarabedan_karyalaya"
                  onChange={(e) => handleChange(e, false)}
                  value={anusuchiValue.punarabedan_karyalaya}
                >
                  <option value="">छान्नुहोस...</option>
                  {karyalaya
                    ? karyalaya.map((item, id) => (
                        <option key={id} value={item.name}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </CFormSelect>
              </CCol>

              {anusuchiValue.punarabedan_karyalaya === 'महान्यायाधिवक्ताको कार्यालय' ||
              anusuchiValue.punarabedan_karyalaya === 'सर्वोच्च अदालत' ? null : (
                <CCol md="6">
                  <CFormLabel htmlFor="mudda">जिल्ला </CFormLabel>

                  <CFormSelect
                    id="mudda"
                    name="punarabedan_jilla"
                    onChange={(e) => handleChange(e, false)}
                    value={anusuchiValue.punarabedan_jilla}
                  >
                    <option value="">छान्नुहोस...</option>
                    {allZilla.map((item, id) => (
                      <option key={id}>{item.jilla_nam}</option>
                    ))}
                  </CFormSelect>
                </CCol>
              )}

              {anusuchiValue.punarabedan_karyalaya === 'सर्वोच्च अदालत' ? null : (
                <CCol md="6">
                  <CFormLabel htmlFor="myad" className="mt-4">
                    चलान नम्बर
                  </CFormLabel>

                  <CFormInput
                    type="text"
                    id="myad"
                    required
                    name="punarabedan_chalan_number"
                    onChange={handleChange}
                    value={anusuchiValue.punarabedan_chalan_number}
                  />
                </CCol>
              )}

              <CCol md="6">
                <CFormLabel htmlFor="myad" className="mt-4">
                  मिति
                </CFormLabel>
                <NepaliCalendar
                  setDate={setPunarabendanMiti}
                  date={anusuchiValue.punarabedan_miti}
                />
                {/* <Calendar
                  className="form-control date-control"
                  language="en"
                  onChange={(date) => setPunarabendanMiti(date.bsDate)}
                /> */}
                {/* <NepaliDatePicker
                    inputClassName="form-control date-control"
                    className=""
                    name="punarabedan_miti"
                    onChange={(value) => setPunarabendanMiti(value)}
                    // onChange={(e) => handleChange(e)}
                    // value={anusuchiValue.punarabedan_miti}
                    options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                  /> */}

                {/* <CFormInput
                  type="date"
                  id="myad"
                  required
                  name="mudda_aantim_bhayeko_miti"
                  onChange={handleChange}
                  value={anusuchiValue.mudda_aantim_bhayeko_miti}
                /> */}
              </CCol>
            </CRow>
          </div>
          {/* <CFormLabel htmlFor="punarabedan">पुनरावेदन नपरेको प्रमाण</CFormLabel>

          <CFormInput
            type="text"
            id="punarabedan"
            required
            name="punarabedan_napareko_praman"
            onChange={handleChange}
            value={anusuchiValue.punarabedan_napareko_praman}
          /> */}
        </CCol>

        {/* Kaid miti */}
        {/* <CCol md={6}>
          <CFormLabel htmlFor="kaid-miti">कैद परेको मिति</CFormLabel>

          <NepaliDatePicker
            inputClassName="form-control"
            className=""
            name="antim_faisala_miti"
            onChange={(value) => setKaidParekoMiti(value)}
            // onChange={(e) => handleChange(e)}
            // value={anusuchiValue.antim_faisala_miti}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </CCol> */}

        {/* Kaid bhuktani din */}
        <CCol md={6}>
          <CFormLabel htmlFor="kaidi-bhuktan-din">
            कैद भुक्तान गरेको अवधि (वर्ष / महिना /दिन / प्रतिशत) <span className="required">*</span>
          </CFormLabel>

          <CRow>
            <CCol md={6}>
              <CFormInput
                type="name"
                id="kaidi-bhuktan-din"
                required
                name="kaaid_bhuktan_awadhi"
                // onChange={(e) => handleChange(e, false)}
                value={anusuchiValue.kaaid_bhuktan_awadhi}
                disabled
              />
            </CCol>
            <CCol md={6}>
              <CFormInput
                type="name"
                id="kaidi-bhuktan-din"
                required
                name="kaaid_bhuktan_percent"
                // onChange={(e) => handleChange(e, false)}
                value={anusuchiValue.kaaid_bhuktan_percent + ' %'}
                disabled
              />
            </CCol>
          </CRow>
        </CCol>

        {/* Chutti miti */}
        <CCol md={6}>
          <CFormLabel htmlFor="kaid-chutti-miti">
            {/* जरिवाना <span className="required">*</span> */}
          </CFormLabel>
          <CRow>
            {/* <CCol md={6}>
              <Calendar
                disabled
                className="form-control date-control"
                language="en"
                onChange={(date) => setChuttiMiti(date.bsDate)}
              />
              
            </CCol> */}

            <CCol md={12}>
              <label>जरिवाना</label>
              <CFormInput
                type="text"
                onKeyPress={(event) => {
                  if (!/[१२३४५६७८९०]/.test(convert_to_preeti(event.key))) {
                    event.preventDefault()
                  }
                }}
                name="jariwana"
                placeholder="जरिवाना"
                value={anusuchiValue.jariwana}
                onChange={handleChange}
              />
            </CCol>
          </CRow>
        </CCol>

        {/* Kaid miti */}
        <CCol md={6}>
          <CFormLabel htmlFor="kaid-pareko-miti">
            तोकिएको कैद र जरिवाना गरि जम्मा कैद (वर्ष / महिना / दिन ){' '}
            <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="text"
            id="kaid-pareko-miti"
            disabled
            placeholder="वर्ष / महिना / दिन"
            required
            name="tokiyeko_kaiid_ra_jaribana_gari_kaiid"
            onChange={handleChange}
            value={anusuchiValue.tokiyeko_kaiid_ra_jaribana_gari_kaiid}
          />
        </CCol>

        {/* Chottaunu parney kaid */}
        <CCol md={6}>
          <CFormLabel htmlFor="chottaunu-kaid">
            छोट्औनु पर्ने कैद (वर्ष / महिना / दिन / प्रतिशत ) <span className="required">*</span>
          </CFormLabel>

          <CRow>
            <CCol md={6}>
              <CFormInput
                type="text"
                id="chottaunu-kaid"
                placeholder="वर्ष / महिना / दिन"
                required
                disabled
                name="choteunu_parne_kaiid"
                onChange={handleChange}
                value={anusuchiYear}
              />
            </CCol>

            <CCol md={6}>
              <CFormInput
                type="text"
                id="chottaunu-kaid"
                placeholder="प्रतिशत"
                required
                disabled
                name="choteunu_parne_kaiid"
                onChange={handleChange}
                value={anusuchiPercent}
              />
            </CCol>
          </CRow>
        </CCol>

        {/* Kaifiyat */}
        <CCol md={6}>
          <CFormLabel htmlFor="kaifiyat">
            कैफियत (बृद्ध, रोगी वा अशक्त भए सो समेत उल्लेख गर्ने)
          </CFormLabel>

          <CFormInput
            type="text"
            id="kaifiyat"
            maxLength={50}
            required
            name="kaiifeyad"
            onChange={handleChange}
            value={anusuchiValue.kaiifeyad}
          />
          <small>{anusuchiValue.kaiifeyad ? anusuchiValue.kaiifeyad.length : 0}/50</small>
        </CCol>

        {/* upayukta */}
        <CCol md={6}>
          <div onChange={handleChange} value={anusuchiValue.kaiid_katti_garne_upayukta}>
            <CFormLabel htmlFor="pareko-napareko">
              कैद कट्टी गर्न उपयुक्त <span className="required">*</span>
            </CFormLabel>
            <br />
            <CFormCheck
              inline
              type="radio"
              name="kaiid_katti_garne_upayukta"
              id="छ"
              value="छ"
              label="छ"
              checked={anusuchiValue.kaiid_katti_garne_upayukta === 'छ'}
            />
            <CFormCheck
              inline
              type="radio"
              name="kaiid_katti_garne_upayukta"
              id="छैन"
              value="छैन"
              label="छैन"
              checked={anusuchiValue.kaiid_katti_garne_upayukta === 'छैन'}
            />
          </div>
        </CCol>
      </CForm>
    </div>
  )
}

export default AnusuchiThree
