import React, { Component, useContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import './App.css'
import { FirstLoginContext } from './context/UserContext'
import PasswordChange from './views/pages/login/PasswordChange'
import DefaultLayout from './layout/DefaultLayout'
import { UserContext } from './context/UserContext'
import { CONSTANTS } from 'src/constants'
import axios from 'axios'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const [firstLogin, setFirstLogin] = useContext(FirstLoginContext)
  const [user] = useContext(UserContext)

  useEffect(() => {
    if (user) {
      if (user.role === 'superadmin') {
        setFirstLogin('false')
      } else {
        console.log('I am called')
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASEURL}/user/flag`,
          headers: {
            Authorization: `Bearer ${user ? user.access_token : null}`,
          },
          timeout: CONSTANTS.NETWORK_TIME_OUT,
          timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
        })
          .then((res) => {
            console.log(res.data)
            if (res.data.status_code === 200) {
              setFirstLogin('true')
            } else {
              setFirstLogin('false')
            }
          })
          .catch((e) => console.log(e.message))
      }
    }
  }, [user])
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            path="/"
            name="Home"
            render={(props) => {
              if (user && firstLogin === 'false') {
                return <DefaultLayout {...props} />
              } else if (user && firstLogin === 'true') {
                return <PasswordChange {...props} />
              } else {
                return <Login {...props} />
              }
            }}
          />

          {/* <Route exact path="/" name="Login Page" render={(props) => <Login {...props} />} />
          {firstLogin === 'true' ? (
            <>
              <PasswordChange />
            </>
          ) : (
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          )} */}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
