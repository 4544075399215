import React from 'react'
import { PuranoAbedanAPI } from './PuranoAbedanAPI'

export const PuranoAbedanTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => rowData.tableData.id + 1,
      },
      {
        title: 'कैदीको नाम',
        field: 'kaidi_ko_nam_thar',
      },
      {
        title: 'छुट्टि जाने मिति',
        field: 'chutti_janey_miti',
      },
    ]
  },

  getTableDatas: function (puranoabedanies) {
    return puranoabedanies
    // return [
    //   {
    //     id: 1,
    //     name: 'XXX',
    //   },
    //   {
    //     id: 2,
    //     name: 'YYY',
    //   },
    //   {
    //     id: 3,
    //     name: 'ZZZ',
    //   },
    // ]
  },

  getDataActions: function (handleEditOpen, handleDeleteOpen, history) {},
}
