import React from 'react'
import { OldAnusuchiAPI } from './OldAnusuchiAPI'

export const OldAnusuchiTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => rowData.tableData.id + 1,
      },
      {
        title: 'गणना मिति',
        field: 'date',
      },
    ]
  },

  getTableDatas: function (oldAnusuchies) {
    return oldAnusuchies
    // return [
    //   {
    //     id: 1,
    //     name: '2078-10-2',
    //   },
    //   {
    //     id: 2,
    //     name: '2078-11-20',
    //   },
    //   {
    //     id: 3,
    //     name: '2078-05-02',
    //   },
    // ]
  },

  getDataActions: function (history) {
    return [
      {
        icon: 'visibility',
        tooltip: 'View',
        onClick: (e, rowData) =>
          history.push({
            pathname: '/purano-abedan',
            state: { forms: rowData },
          }),
        position: 'row',
      },
    ]
  },
}
