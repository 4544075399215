import React, { useContext, useState, useEffect } from 'react'
import Calendar from '@sbmdkl/nepali-datepicker-reactjs'
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css'
import {
  CFormInput,
  CForm,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CButton,
  CFormTextarea,
} from '@coreui/react'
import { NepaliDatePicker } from 'nepali-datepicker-reactjs'
import 'nepali-datepicker-reactjs/dist/index.css'
import { UserContext } from '../../../context/UserContext'
import { RastriyataAPI } from '../rastriyata/RastriyataAPI'
import { allZilla } from 'src/helpers/ZillaProvider'
import NepaliCalendar from 'src/components/NepaliCalendar'

const AnusuchiSecond = ({
  initialState,
  handleChange,
  anusuchiValue,
  mudda,
  adalat,
  rastriyata,
  karyalaya,
  zilla,
  setMuddaAntimMiti,
  allZilla,
  allSthaniya,
}) => {
  const [user] = useContext(UserContext)
  const [rastriyeta, setRastriyeta] = useState([])

  useEffect(() => {
    RastriyataAPI.get(user ? user.access_token : null)
      .then((res) => {
        setRastriyeta(res.data.data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  return (
    <div>
      <h4 className="text-center mb-5 form-header">अनुसूची-२ फारम</h4>
      <CForm className="row g-3 mb-5">
        {/* Karyalaya */}
        <CCol md={6}>
          <CFormLabel htmlFor="karyalaya">
            कारागार कार्यालय <span className="required">*</span>{' '}
          </CFormLabel>
          {user ? (
            user.role === 'superadmin' ? (
              <CFormSelect
                id="karyalaya"
                disabled={true}
                name="Karagar_Karyalaya"
                onChange={(e) => handleChange(e, false)}
                value={anusuchiValue.Karagar_Karyalaya}
              >
                <option value="">छान्नुहोस...</option>
                {zilla
                  ? zilla.map((item, id) => (
                      <option key={id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  : null}
              </CFormSelect>
            ) : (
              <CFormInput
                type="name"
                id="name"
                required
                disabled
                name="Karagar_Karyalaya"
                // onChange={handleChange}
                value={anusuchiValue.Karagar_Karyalaya}
              />
            )
          ) : null}
        </CCol>
        {/* Name */}
        <CCol md={6}>
          <CFormLabel htmlFor="name">
            निवेदकको पुरा नाम <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            disabled
            type="name"
            id="name"
            required
            name="nibedako_nam_thar"
            onChange={handleChange}
            value={anusuchiValue.nibedako_nam_thar}
          />
        </CCol>
        {/* address */}
        <CCol md={6}>
          <CFormLabel htmlFor="name">
            निवेदकको ठेगाना<span className="required">*</span>
          </CFormLabel>

          <CRow>
            <CCol md={anusuchiValue.kaidi_ko_desh === 'नेपाल' ? 3 : 12}>
              <CFormSelect
                name="kaidi_ko_desh"
                onChange={handleChange}
                value={anusuchiValue.kaidi_ko_desh}
                disabled={true}
              >
                <option value="">देश</option>
                <option>नेपाल</option>
                {rastriyeta.map((get, id) => (
                  <option key={id}>{get.name}</option>
                ))}
              </CFormSelect>
            </CCol>
            {anusuchiValue.kaidi_ko_desh === 'नेपाल' ? (
              <>
                <CCol md={3}>
                  <CFormSelect
                    name="kaidi_ko_jilla"
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    value={anusuchiValue.kaidi_ko_jilla}
                    disabled={true}
                  >
                    <option value="">जिल्ला</option>
                    {allZilla.map((item) => (
                      <option key={item.id} value={item.jilla_nam}>
                        {item.jilla_nam}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>

                <CCol md={3}>
                  <CFormSelect
                    name="kaidi_ko_sthaniya"
                    onChange={handleChange}
                    value={anusuchiValue.kaidi_ko_sthaniya}
                    disabled={true}
                  >
                    <option value="">स्थानीय तह</option>
                    {allSthaniya.map((item) => {
                      if (item.jillabibhajan.jilla_nam === anusuchiValue.kaidi_ko_jilla) {
                        return (
                          <option key={item.id} value={item.esthania_tahako_nam}>
                            {item.esthania_tahako_nam}
                          </option>
                        )
                      }
                    })}
                  </CFormSelect>

                  {/* <CFormInput
                    type="text"
                    id="name"
                    placeholder="स्थानीय तह"
                    name="kaidi_ko_sthaniya"
                    onChange={handleChange}
                    value={anusuchiValue.kaidi_ko_sthaniya}
                  /> */}
                </CCol>

                <CCol md={3}>
                  <CFormInput
                    type="text"
                    id="name"
                    placeholder="वडा न."
                    name="kaidi_ko_wada"
                    disabled={true}
                    onChange={handleChange}
                    value={anusuchiValue.kaidi_ko_wada}
                  />
                </CCol>
              </>
            ) : null}
          </CRow>
        </CCol>
        {/* mudda */}
        <CCol md={6}>
          <CFormLabel htmlFor="mudda">
            मुद्दा <span className="required">*</span>
          </CFormLabel>
          <CFormInput value={anusuchiValue.mudda.map((item) => item)} disabled={true} />
          {/* <MultipleSelect
            dataList={anusuchiValue.mudda}
            selectionState={garekoKasurs}
            setSelectionState={setGarekoKasurs}
            value="name"
            showValue="name"
          /> */}
        </CCol>
        {/* Badi pradibadi*/}
        <CCol md={6}>
          <CFormLabel htmlFor="badi">
            वादी र प्रतिवादिहरुको पुरा नाम , थर <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="name"
            id="badi"
            required
            name="baadi_ra_pratibadhiko_nam_thar"
            onChange={handleChange}
            value={anusuchiValue.baadi_ra_pratibadhiko_nam_thar}
          />
        </CCol>

        {/* Badi Pradibadi address */}
        <CCol md={6}>
          <CFormLabel htmlFor="badi">
            वादी र प्रतिवादिहरुको ठेगाना <span className="required">*</span>{' '}
          </CFormLabel>

          <CFormInput
            type="name"
            id="badi"
            required
            name="baadi_ra_pratibadhiko_thegana"
            onChange={handleChange}
            value={anusuchiValue.baadi_ra_pratibadhiko_thegana}
          />
        </CCol>
        {/* Court */}
        <CCol md={6}>
          <CRow>
            <CCol md={anusuchiValue.adalat === 'जिल्ला अदालत' ? 6 : 12}>
              <CFormLabel htmlFor="mudda">
                मुद्दा फैसला गर्ने अदालत <span className="required">*</span>
              </CFormLabel>

              <CFormSelect
                id="mudda"
                name="adalat"
                onChange={(e) => handleChange(e, false)}
                value={anusuchiValue.adalat}
              >
                <option value="">छान्नुहोस...</option>
                {adalat
                  ? adalat.map((item, id) => (
                      <option key={id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  : null}
              </CFormSelect>
            </CCol>
            {anusuchiValue.adalat === 'जिल्ला अदालत' ? (
              <CCol md={6}>
                <CFormLabel htmlFor="mudda">जिल्ला</CFormLabel>
                <CFormSelect
                  name="adalat_jilla"
                  onChange={(e) => handleChange(e, false)}
                  value={anusuchiValue.adalat_jilla}
                >
                  <option value="">जिल्ला</option>
                  {allZilla.map((item) => (
                    <option key={item.jilla_nam} value={item.jilla_nam}>
                      {item.jilla_nam}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            ) : null}
          </CRow>
        </CCol>
        {/* Bhayeko sajaye */}
        <CCol md={6}>
          <CFormLabel htmlFor="sajaye">
            भएको सजाय <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="name"
            id="sajaye"
            required
            name="bhayeko_sajaye"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.bhayeko_sajaye}
            disabled
          />
        </CCol>
        {/* Miti */}
        <CCol md={6}>
          <CFormLabel htmlFor="myad">
            मुद्दा अन्तिम भएको मिति वा पुनरावेदन लाग्ने भए पुनरावेदनको म्याद समाप्त भएको मिति{' '}
            <span className="required">*</span>
          </CFormLabel>

          <NepaliCalendar
            setDate={setMuddaAntimMiti}
            date={anusuchiValue.mudda_aantim_bhayeko_miti}
          />

          {/* <Calendar
            className="form-control date-control"
            language="en"
            onChange={(date) => setMuddaAntimMiti(date.bsDate)}
          /> */}
          {/* <NepaliDatePicker
            inputClassName="form-control date-control"
            className=""
            name="mudda_aantim_bhayeko_miti"
            onChange={(value) => setMuddaAntimMiti(value)}
            // onChange={(e) => handleChange(e)}
            value={anusuchiValue.mudda_aantim_bhayeko_miti}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          /> */}

          {/* <CFormInput
            type="date"
            id="myad"
            required
            name="mudda_aantim_bhayeko_miti"
            onChange={handleChange}
            value={anusuchiValue.mudda_aantim_bhayeko_miti}
          /> */}
        </CCol>
        {/* Bhuktan sajaye */}
        <CCol md={6}>
          <CFormLabel htmlFor="sajaye">
            हालसम्म भुक्तान गरेको सजाय <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="name"
            id="sajaye"
            required
            name="kaaid_bhuktan_awadhi"
            onChange={handleChange}
            value={anusuchiValue.kaaid_bhuktan_awadhi}
            disabled
          />
        </CCol>
        {/* adahar ra auchitya */}
        <CCol md={6}>
          <CFormLabel htmlFor="adhar">
            कैद कट्टा गर्नुपर्ने आधार र औचित्य <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="name"
            id="adhar"
            required
            name="kaiid_katta_aadhar"
            onChange={handleChange}
            value={anusuchiValue.kaiid_katta_aadhar}
          />
        </CCol>
      </CForm>
    </div>
  )
}

export default AnusuchiSecond
