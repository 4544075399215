import React from 'react'
import { GadanaAPI } from './GadanaAPI'
import { CONSTANTS } from '.././../../constants'

export const GadanaTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => rowData.tableData.id + 1,
      },
      {
        title: 'महिना',
        field: 'month',
        render: (rowData) => {
          if (rowData.month === '1') {
            return 'वैशाख'
          } else if (rowData.month === '2') {
            return 'ज्येष्ठ'
          } else if (rowData.month === '3') {
            return 'आषाढ़'
          } else if (rowData.month === '4') {
            return 'श्रावण'
          } else if (rowData.month === '5') {
            return 'भदौ'
          } else if (rowData.month === '6') {
            return 'आश्विन'
          } else if (rowData.month === '7') {
            return 'कार्तिक'
          } else if (rowData.month === '8') {
            return 'मंसिर'
          } else if (rowData.month === '9') {
            return 'पौष'
          } else if (rowData.month === '10') {
            return 'माघ'
          } else if (rowData.month === '11') {
            return 'फाल्गुण'
          } else if (rowData.month === '12') {
            return 'चैत्र'
          } else {
            return 'Invalid'
          }
        },
      },
      {
        title: 'दिन',
        field: 'day',
      },
    ]
  },

  getTableDatas: function (gadanaes) {
    return gadanaes
  },

  getDataActions: function (handleEditOpen, handleDeleteOpen) {
    return [
      {
        icon: 'edit',
        iconProps: {
          style: {
            color: CONSTANTS.EDIT_ICON_COLOR,
            background: CONSTANTS.EDIT_BACKGROUND_COLOUR,
          },
        },
        tooltip: 'Edit',
        onClick: (e, rowData) => handleEditOpen(rowData.id),
        position: 'row',
      },

      {
        icon: 'delete',
        iconProps: {
          style: {
            color: CONSTANTS.DELETE_ICON_COLOR,
            background: CONSTANTS.DELETE_BACKGROUND_COLOR,
          },
        },
        tooltip: 'Delete',
        onClick: (e, rowData) => handleDeleteOpen(rowData.id),
        position: 'row',
      },
    ]
  },
}
