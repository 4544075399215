import React from 'react'
import ReactExport from 'react-export-excel'
import { NepaliNumber, EnglishNumber } from '../../../helpers/NepaliNumber'
import { bhuktan_sajaye_calculator } from '../../../helpers/DateCalculator'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const returnStringFromArray = (array) => {
  let res = ''
  for (let i = 0; i < array.length; i++) {
    if (i < array.length - 1) {
      res = res + array[i] + ', '
    } else {
      res = res + array[i]
    }
  }

  return res
}

const ExcelDownload = ({ data }) => {
  // console.log('Excel data is', data)

  if (data) {
    return (
      <ExcelFile
        element={<button className="btn btn-primary btn-sm">एक्सेलमा डाउनलोड गर्नुहोस् ।</button>}
      >
        <ExcelSheet
          data={data}
          name="
आवेदनहरु"
        >
          {/* <ExcelColumn label="सि.नं." value={(col) => NepaliNumber(col.id)} /> */}
          <ExcelColumn label="कारागारको नाम" value="Karagar_Karyalaya" />
          <ExcelColumn
            label="कैदीको नाम ,थर , ठेगाना"
            value={(col) =>
              col.kaidi_ko_desh === 'नेपाल'
                ? col.kaidi_ko_nam_thar +
                  '\n' +
                  col.kaidi_ko_sthaniya +
                  '-' +
                  col.kaidi_ko_wada +
                  ', ' +
                  col.kaidi_ko_jilla +
                  ', ' +
                  col.kaidi_ko_desh
                : col.kaidi_ko_nam_thar + ',' + '\n' + col.kaidi_ko_desh
            }
          />
          <ExcelColumn
            label="जाहेरवालाको नाम"
            value={(col) => col.anusuchi3[0]?.jaherwala_ko_nam}
          />
          <ExcelColumn
            label="मुद्दाको अन्तिम कारवाही गर्ने निकाय र अन्तिम फैसला मिति"
            value={(col) => {
              if (col.anusuchi2.length > 0) {
                // console.log(col.anusuchi2)
                return col.anusuchi2[0]?.adalat === 'जिल्ला अदालत'
                  ? col.anusuchi2[0]?.adalat_jilla +
                      ' ' +
                      col.anusuchi2[0]?.adalat +
                      '\n' +
                      NepaliNumber(col.anusuchi2 ? col.anusuchi2[0]?.mudda_aantim_bhayeko_miti : '')
                  : col.anusuchi2[0].adalat +
                      ' ' +
                      '\n' +
                      NepaliNumber(col.anusuchi2 ? col.anusuchi2[0]?.mudda_aantim_bhayeko_miti : '')
              }
            }}
          />
          <ExcelColumn label="उमेर" value="kaidi_ko_umer" />
          <ExcelColumn label="लिङ्ग" value={(col) => col.anusuchi3[0]?.linga} />
          <ExcelColumn label="राष्ट्रियता" value={(col) => col.anusuchi3[0]?.rastriyata} />

          <ExcelColumn label="मुद्दा" value={(col) => returnStringFromArray(col.mudda)} />
          <ExcelColumn
            label="निजको मुद्दा नकारात्मक सूचीमा परे / नपरेको"
            value="nij_ko_mudda_nakaratmak"
          />
          <ExcelColumn
            label="पुनरावेदन नपरेको प्रमाण"
            value={(col) =>
              col.anusuchi3[0]?.punarabedan_karyalaya === 'सर्वोच्च अदालत'
                ? col.anusuchi3[0]?.punarabedan_karyalaya +
                  'को मिति' +
                  '\n' +
                  NepaliNumber(col.anusuchi3[0]?.punarabedan_miti) +
                  ' को फैसला'
                : col.anusuchi3[0]?.punarabedan_karyalaya === 'महान्यायाधिवक्ताको कार्यालय'
                ? col.anusuchi3[0]?.punarabedan_karyalaya +
                  ' ' +
                  'को \n च.न ' +
                  col.anusuchi3[0]?.punarabedan_chalan_number +
                  ' मिति \n' +
                  NepaliNumber(col.anusuchi3[0]?.punarabedan_miti) +
                  'को पत्र'
                : col.anusuchi3[0]?.punarabedan_karyalaya +
                  ' ' +
                  col.anusuchi3[0]?.punarabedan_jilla +
                  '' +
                  'को \n च.न ' +
                  col.anusuchi3[0]?.punarabedan_chalan_number +
                  ' मिति \n' +
                  NepaliNumber(col.anusuchi3[0]?.punarabedan_miti) +
                  ' को पत्र'
            }
          />
          <ExcelColumn
            label="कैद परेको मिति"
            value={(col) =>
              (col.barsa === null || col.barsa == NepaliNumber('0')) &&
              (col.mahina === null || col.mahina == NepaliNumber('0')) &&
              (col.din === null || col.din == NepaliNumber('0'))
                ? NepaliNumber(col.kaid_pareko_miti)
                : NepaliNumber(col.barsa ? col.barsa : '0') +
                  '/' +
                  NepaliNumber(col.mahina ? col.mahina : '0') +
                  '/' +
                  NepaliNumber(col.din ? col.din : '0') +
                  ' पहिले बसेको' +
                  '\n' +
                  NepaliNumber(col.kaid_pareko_miti)
            }
          />
          <ExcelColumn
            label="कैदिपूर्जीमा उल्लेखित छुटी जाने मिति र जरिवाना भए सो समेत"
            value={(col) =>
              col.jariwana
                ? NepaliNumber(col.chutti_janey_miti) +
                  ' \n जरिवाना रु. ' +
                  col.jariwana +
                  ' तिरेको'
                : NepaliNumber(col.chutti_janey_miti)
            }
          />
          <ExcelColumn
            label="तोकिएको कैद र जरिवाना गरी जम्मा कैद ( वर्ष / महिना / दिन )"
            value={(col) => NepaliNumber(col.anusuchi3[0]?.tokiyeko_kaiid_ra_jaribana_gari_kaiid)}
          />
          <ExcelColumn
            label="भुक्तान कैद ( वर्ष / महिना / दिन  / प्रतिशत )"
            value={(col) =>
              NepaliNumber(col.kaaid_bhuktan_awadhi.split('/').slice(0, 3).join('/')) +
              '\n' +
              NepaliNumber(
                (
                  100 -
                  parseFloat(EnglishNumber(col.anusuchi3[0]?.choteunu_parne_kaiid.split('/')[3]))
                ).toFixed(2),
              )
            }
          />
          <ExcelColumn
            label="छोट्याउनु पर्ने कैद ( वर्ष / महिना / दिन  / प्रतिशत )"
            value={(col) =>
              NepaliNumber(
                col.anusuchi3[0]?.choteunu_parne_kaiid?.split('/').slice(0, 3).join('/'),
              ) +
              '\n' +
              NepaliNumber(col.anusuchi3[0]?.choteunu_parne_kaiid.split('/')[3])
            }
          />
          <ExcelColumn
            label="कैफियत (वृद्ध, रोगी वा अशक्त भए सो समेत उल्लेख गर्ने)"
            value={(col) => col.anusuchi3[0]?.kaiifeyad}
          />
          <ExcelColumn
            label="कैद कट्टी गर्न उपयुक्त छ / छैन"
            value={(col) => col.anusuchi3[0]?.kaiid_katti_garne_upayukta}
          />
          {/* <ExcelColumn label="कैदीको नाम ,थर , ठेगाना" value="sex" /> */}
        </ExcelSheet>
        {/* <ExcelSheet data={dataSet2} name="Leaves">
                  <ExcelColumn label="Name" value="name" />
                  <ExcelColumn label="Total Leaves" value="total" />
                  <ExcelColumn label="Remaining Leaves" value="remaining" />
                </ExcelSheet> */}
      </ExcelFile>
    )
  }

  return <></>
}

export default ExcelDownload
