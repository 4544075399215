import React from 'react'
import Adalat from './views/pages/adalat/adalat'
import Anusuchi from './views/pages/anusuchi/anusuchi'
import AnusuchiForm from './views/pages/anusuchi/AnusuchiForm'

import AnusuchiOnePdf from './views/pages/anusuchi/Anusuchi1Pdf'
import AnusuchiTwoPdf from './views/pages/anusuchi/Anusuchi2Pdf'
import AnusuchiThreePdf from './views/pages/anusuchi/Anusuchi3Pdf'
import Gadana from './views/pages/gadanaMiti/gadana'
import Karyalaya from './views/pages/karyalaya/karyalaya'
import Mudda from './views/pages/mudda/mudda'
import Rastriyata from './views/pages/rastriyata/rastriyata'
import ZillaAdmin from './views/pages/zillaAdmin/zillaAdmin'
import OldAnusuchi from './views/pages/OldAnusuchi/oldanusuchi'
import Abedan from './views/pages/abedan/abedan'
import PuranoAbedan from './views/pages/puranoabedan/puranoabedan'
import ExcelDownload from './views/pages/abedan/AbedanExcel'
import ZillaPasswordChange from './views/pages/login/ZillaPasswordChange'
// import OldAnusuchi from './views/pages/OldAnusuchi/OldAnusuchi'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

export const AdminRoutes = [
  // { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: '', component: Dashboard },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', component: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', component: Placeholders },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress', name: 'Progress', component: Progress },
  { path: '/base/spinners', name: 'Spinners', component: Spinners },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/forms', name: 'Forms', component: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', component: FormControl },
  { path: '/forms/select', name: 'Select', component: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', component: ChecksRadios },
  { path: '/forms/range', name: 'Range', component: Range },
  { path: '/forms/input-group', name: 'Input Group', component: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', component: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', component: Layout },
  { path: '/forms/validation', name: 'Validation', component: Validation },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },
  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toasts', name: 'Toasts', component: Toasts },
  { path: '/widgets', name: 'Widgets', component: Widgets },

  // KFA Components
  { path: '/mudda', name: 'मुद्दा', component: Mudda },
  { path: '/adalat', name: 'अदालत', component: Adalat },
  { path: '/rastriyata', name: 'देश', component: Rastriyata },
  { path: '/karyalaya', name: 'कार्यालय', component: Karyalaya },
  { path: '/anusuchi', name: 'अनुसूची', component: Anusuchi, exact: true },
  { path: '/anusuchi/create', name: 'अनुसूची फारम ', component: AnusuchiForm, exact: true },

  { path: '/gadana-miti', name: 'गणना मिति', component: Gadana, exact: true },
  { path: '/anusuchi1Pdf', name: 'अनुसूची फारम ', component: AnusuchiOnePdf, exact: true },
  { path: '/anusuchi2Pdf', name: 'अनुसूची फारम ', component: AnusuchiTwoPdf, exact: true },
  { path: '/anusuchi3Pdf', name: 'अनुसूची फारम ', component: AnusuchiThreePdf, exact: true },
  { path: '/zillaAdmin', name: 'जिल्ला एडमिन', component: ZillaAdmin },

  { path: '/anusuchi-old', name: 'पुरानो गणना मिति ', component: OldAnusuchi, exact: true },
  { path: '/jilla-ko-abedan', name: 'जिल्ला का आबेदन', component: Abedan, exact: true },
  { path: '/purano-abedan', name: 'पुरानो आबेदन', component: PuranoAbedan, exact: true },

  { path: '/abedan-excel', name: 'पुरानो आबेदन', component: ExcelDownload, exact: true },
  {
    path: '/password-change',
    name: 'पासवर्ड परिवर्तन',
    component: ZillaPasswordChange,
    exact: true,
  },

  // { path: '/departments', name: 'Departments', component: Department },
  // { path: '/functions', name: 'Functions', component: Function },
  // { path: '/pivots', name: 'Pivots', component: Pivot },
  // { path: '/cmfs', name: 'CMF Representation', component: CMF },
  // { path: '/employees', name: 'Employees', component: Employee },
]

// export default routes

export const ZillaAdminRoutes = [
  { path: '/dashboard', name: '', component: Dashboard },
  { path: '/anusuchi', name: 'अनुसूची फारम ', component: Anusuchi, exact: true },
  { path: '/anusuchi/create', name: 'अनुसूची फारम ', component: AnusuchiForm, exact: true },
  { path: '/anusuchi-old', name: 'पुरानो गणना मिति  ', component: OldAnusuchi, exact: true },
  { path: '/purano-abedan', name: 'पुरानो आबेदन', component: PuranoAbedan, exact: true },
  {
    path: '/password-change',
    name: 'पासवर्ड परिवर्तन',
    component: ZillaPasswordChange,
    exact: true,
  },
]
