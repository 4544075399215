import React from 'react'
import { NepaliNumber, EnglishNumber } from 'src/helpers/NepaliNumber'
import { AbedanAPI } from './abedanAPI'
import { CONSTANTS } from '../../../constants'

export const AbedanTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => NepaliNumber(rowData.tableData.id + 1),
      },
      {
        title: 'कारागारको नाम ',
        field: 'Karagar_Karyalaya',
      },

      {
        title: 'कैदीको नाम ,थर , ठेगाना ',
        field: 'kaidi_ko_nam_thar',
        render: (col) =>
          col.kaidi_ko_desh === 'नेपाल'
            ? col.kaidi_ko_nam_thar +
              '\n' +
              col.kaidi_ko_jilla +
              ' ' +
              '-' +
              col.kaidi_ko_wada +
              ', ' +
              col.kaidi_ko_desh
            : col.kaidi_ko_nam_thar + ',' + '\n' + col.kaidi_ko_desh,
      },

      {
        title: 'जाहेरवालाको नाम',
        field: 'kaidi_ko_desh',
        render: (col) => (col.anusuchi3 ? col.anusuchi3[0]?.jaherwala_ko_nam : ''),
      },

      {
        title: 'मुद्दाको अन्तिम कारवाही गर्ने निकाय र अन्तिम फैसला मिति',
        field: 'mudda',
        render: (col) =>
          col.anusuchi2[0]?.adalat === 'जिल्ला अदालत'
            ? col.anusuchi2[0]?.adalat_jilla +
              ' ' +
              col.anusuchi2[0]?.adalat +
              '\n' +
              NepaliNumber(col.anusuchi2 ? col.anusuchi2[0]?.mudda_aantim_bhayeko_miti : '')
            : col.anusuchi2[0]?.adalat +
              ' ' +
              '\n' +
              NepaliNumber(col.anusuchi2 ? col.anusuchi2[0]?.mudda_aantim_bhayeko_miti : ''),
      },

      {
        title: 'उमेर',
        field: 'kaidi_ko_umer',
        // render: (col) => col.anusuchi3[0].jaherwala_ko_nam,
      },

      {
        title: 'लिङ्ग',
        field: 'kaidi_ko_umer',
        render: (col) => (col.anusuchi3 ? col.anusuchi3[0]?.linga : ''),
      },

      {
        title: 'राष्ट्रियता',
        field: 'kaidi_ko_umer',
        render: (col) => (col.anusuchi3 ? col.anusuchi3[0]?.rastriyata : ''),
      },

      {
        title: 'मुद्दा',
        field: 'kaidi_ko_umer',
        render: (col) => col.mudda.map((item) => item + ','),
      },

      {
        title: 'निजको मुद्दा नकारात्मक सूचीमा परे / नपरेको',
        field: 'nij_ko_mudda_nakaratmak',
        // render: (col) => col.mudda.map((item) => item + ','),
      },

      {
        title: 'पुनरावेदन नपरेको प्रमाण',
        field: 'nij_ko_mudda_nakaratmak',
        render: (col) =>
          col.anusuchi3[0]?.punarabedan_karyalaya === 'सर्वोच्च अदालत'
            ? col.anusuchi3[0]?.punarabedan_karyalaya +
              ' को मिति' +
              '\n' +
              NepaliNumber(col.anusuchi3[0]?.punarabedan_miti) +
              ' को फैसला'
            : col.anusuchi3[0]?.punarabedan_karyalaya === 'महान्यायाधिवक्ताको कार्यालय'
            ? col.anusuchi3[0]?.punarabedan_karyalaya +
              ' ' +
              'को \n च.न ' +
              col.anusuchi3[0]?.punarabedan_chalan_number +
              ' मिति \n' +
              NepaliNumber(col.anusuchi3[0]?.punarabedan_miti) +
              ' को पत्र'
            : col.anusuchi3[0]?.punarabedan_karyalaya +
              ' ' +
              col.anusuchi3[0]?.punarabedan_jilla +
              '' +
              'को \n च.न ' +
              col.anusuchi3[0]?.punarabedan_chalan_number +
              ' मिति \n' +
              NepaliNumber(col.anusuchi3[0]?.punarabedan_miti) +
              ' को पत्र',
      },
      {
        title: 'कैद परेको मिति',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) =>
          col.barsa === null && col.mahina === null && col.din === null
            ? NepaliNumber(col.kaid_pareko_miti)
            : NepaliNumber(col.barsa ? col.barsa : '0') +
              '/' +
              NepaliNumber(col.mahina ? col.mahina : '0') +
              '/' +
              NepaliNumber(col.din ? col.din : '0') +
              ' पहिले बसेको' +
              '\n' +
              NepaliNumber(col.kaid_pareko_miti),
      },

      {
        title: 'कैदिपूर्जीमा उल्लेखित छुटी जाने मिति र जरिवाना भए सो समेत',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) =>
          col.jariwana
            ? NepaliNumber(col.chutti_janey_miti) + ' \n जरिवाना रु. ' + col.jariwana + ' तिरेको'
            : NepaliNumber(col.chutti_janey_miti),
      },

      {
        title: 'तोकिएको कैद र जरिवाना गरी जम्मा कैद ( वर्ष / महिना / दिन )',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) =>
          NepaliNumber(
            col.anusuchi3 ? col.anusuchi3[0]?.tokiyeko_kaiid_ra_jaribana_gari_kaiid : '',
          ),
      },

      {
        title: 'भुक्तान कैद ( वर्ष / महिना / दिन  / प्रतिशत )',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) =>
          NepaliNumber(col.kaaid_bhuktan_awadhi.split('/').slice(0, 3).join('/')) +
          '\n' +
          NepaliNumber(
            100 - parseFloat(EnglishNumber(col.anusuchi3[0]?.choteunu_parne_kaiid.split('/')[3])),
          ),
      },

      // {
      //   title: 'पुनरावेदन नपरेको प्रमाण ',
      //   field: 'kaaid_bhuktan_awadhi',
      //   render: (col) => {
      //     if (col.adalat === 'सर्वोच्च अदालत') {
      //       return (
      //         col.adalat +
      //         ' को मिति' +
      //         '\n' +
      //         NepaliNumber(
      //           col.anusuchi2 ? col.anusuchi2[0]?.mudda_aantim_bhayeko_miti + ' को फैसला' : null,
      //         )
      //       )
      //     } else {
      //       return col.anusuchi3
      //         ? col.anusuchi3[0]?.punarabedan_karyalaya + ' को \n च.न '
      //         : '' + col.anusuchi3
      //         ? col.anusuchi3[0]?.punarabedan_chalan_number + ' मिति \n'
      //         : '' + col.anusuchi3
      //         ? col.anusuchi3[0]?.punarabedan_miti + ' को पत्र'
      //         : ''
      //     }
      //   },
      // },

      {
        title: 'छोट्याउनु पर्ने कैद ( वर्ष / महिना / दिन  / प्रतिशत )',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) =>
          NepaliNumber(col.anusuchi3[0]?.choteunu_parne_kaiid?.split('/').slice(0, 3).join('/')) +
          '\n' +
          NepaliNumber(col.anusuchi3[0]?.choteunu_parne_kaiid.split('/')[3]),
      },

      {
        title: 'कैफियत (वृद्ध, रोगी वा अशक्त भए सो समेत उल्लेख गर्ने)',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) => (col.anusuchi3 ? col.anusuchi3[0]?.kaiifeyad : ''),
      },

      {
        title: 'कैद कट्टी गर्न उपयुक्त छ / छैन',
        field: 'kaaid_bhuktan_awadhi',
        render: (col) => (col.anusuchi3 ? col.anusuchi3[0]?.kaiid_katti_garne_upayukta : ''),
      },
    ]
  },

  getTableDatas: function (abedanes) {
    return abedanes
  },

  getDataActions: function (
    handleEditOpen,
    handleDeleteOpen,
    excelHeader,
    excelData,
    handleRollbackOpen,
  ) {
    return [
      {
        icon: 'restore',
        iconProps: {
          style: {
            color: CONSTANTS.DELETE_ICON_COLOR,
            background: CONSTANTS.DELETE_BACKGROUND_COLOR,
          },
        },
        tooltip: 'Roll Back Abedan',
        onClick: (e, rowData) => handleRollbackOpen(e, rowData),
        position: 'row',
      },
      // {
      //   icon: 'window',
      //   tooltip: 'Import to excel',
      //   onClick: (e, rowData) => handleDeleteOpen(rowData.id),
      //   isFreeAction: true,
      // },
    ]
  },
}
