import React, { useState, useEffect, useContext } from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import Table from 'src/components/Table/Table'
import { GadanaTableContent } from './GadanaTableContent'
import MyDialog from 'src/components/Dialog/Dialog'
import GadanaForm from './GadanaForm'
import ContentBox from 'src/components/ContentBox/ContentBox'
import { CButton } from '@coreui/react'
import { GadanaAPI } from './GadanaAPI'
import { UserContext } from 'src/context/UserContext'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { CONSTANTS } from 'src/constants'

const GadanaList = ({ reload, setReload }) => {
  // Selected Gadana
  const [selectedGadana, setSelectedGadana] = useState(null)

  const [response, setResponse] = useState({
    success: null,
    error: null,
  })

  // State maintains loading while creating data
  const [loading, setLoading] = useState(false)
  // state maintains table data
  const [loadingTableData, setLoadingTableData] = useState(true)

  // State to maintain loading while fetching individual data
  const [fetching, setFetching] = useState(false)

  // Selected Item
  const [selectedItem, setSelectedItem] = useState(null)
  // User Context
  const [user] = useContext(UserContext)
  // State to store gadanaes
  const [gadanaes, setGadanaes] = useState([])

  // State to handle edit dialog box
  const [openEdit, setOpenEdit] = useState(false)

  // State to handle delete dialog box

  const [openDelete, setOpenDelete] = useState(false)

  // edit Menu Handler
  const handleEditOpen = (id) => {
    setOpenEdit(true)
    setSelectedItem(id)
  }
  const handleEditClose = () => {
    setOpenEdit(false)
    setReload(!reload)
    setSelectedItem(null)
  }

  // Delete Dialog Handler
  const handleDeleteOpen = (id) => {
    setOpenDelete(true)
    setSelectedItem(id)
  }
  const handleDeleteClose = () => {
    setOpenDelete(false)
    setReload(!reload)
    setSelectedItem(null)
  }

  // Lifecycle method to fetch gadana

  useEffect(() => {
    setLoadingTableData(true)
    GadanaAPI.get(user ? user.access_token : null)
      .then((res) => {
        setGadanaes(res.data.data)
        setLoadingTableData(false)
      })
      .catch((e) => console.log(e.message))
  }, [reload])

  // Lifecycle method to fetch the selected gadana in edit or delete
  useEffect(() => {
    if (selectedItem) {
      setFetching(true)
      GadanaAPI.getSingle(user ? user.access_token : null, selectedItem)
        .then((res) => {
          setSelectedGadana(res.data.data)
          setFetching(false)
        })
        .catch((e) => console.log(e.message))
    }
  }, [selectedItem])

  // Function to delete gadana
  const DeleteGadana = async () => {
    setLoading(true)
    await GadanaAPI.delete(user ? user.access_token : null, selectedItem)
      .then((res) => {
        if (res.data.status === true) {
          setResponse({
            success: 'Gadana has been deleted successfully',
            error: null,
          })
        } else {
          setResponse({
            success: null,
            error: 'Failed to delete ! Try again later.',
          })
        }
      })
      .catch((e) => console.log(e.message))

    setLoading(false)

    handleDeleteClose()
    setTimeout(
      () =>
        setResponse({
          success: null,
          error: null,
        }),
      CONSTANTS.ALERT_TIME_OUT,
    )
  }

  return (
    <CCard className="mb-4">
      <CCardHeader>गणना मितिहरू</CCardHeader>
      <CCardBody>
        {response.success ? (
          <div className="mb-3">
            {' '}
            <Alert severity="success">{response.success}</Alert>
          </div>
        ) : null}
        {response.error ? (
          <div className="mb-3">
            <Alert severity="error">{response.error}</Alert>
          </div>
        ) : null}
        {/* Gadana Lists */}
        <Table
          columns={GadanaTableContent.getTableHeadings()}
          data={GadanaTableContent.getTableDatas(gadanaes)}
          actions={GadanaTableContent.getDataActions(handleEditOpen, handleDeleteOpen)}
          loading={loadingTableData}
        />

        {/* Edit Dialog Box */}
        <MyDialog open={openEdit} handleClose={handleEditClose}>
          <GadanaForm
            open={openEdit}
            editMode={true}
            handleClose={handleEditClose}
            editData={selectedGadana}
            fetching={fetching}
            selectedId={selectedItem}
          />
        </MyDialog>

        {/* Delete Dialog Box */}
        <MyDialog open={openDelete} handleClose={handleDeleteClose}>
          <ContentBox title="Remove Gadana">
            <p>Do you want to remove this gadana?</p>
            {loading ? (
              <CircularProgress />
            ) : (
              <CButton color="primary" size="sm" onClick={DeleteGadana}>
                Confirm
              </CButton>
            )}
            &nbsp;{' '}
            <CButton color="danger" size="sm" variant="outline" onClick={handleDeleteClose}>
              Close
            </CButton>
          </ContentBox>
        </MyDialog>
      </CCardBody>
    </CCard>
  )
}

export default GadanaList
