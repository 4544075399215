import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import { FirstLoginProvider, UserProvider } from './context/UserContext'
import { PrintProvider } from './context/PrintContext'

console.log(PrintProvider)
console.log(UserProvider)

ReactDOM.render(
  <UserProvider>
    <FirstLoginProvider>
      <PrintProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </PrintProvider>
    </FirstLoginProvider>
  </UserProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
