import React from 'react'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types'
import { CPagination, CPaginationItem } from '@coreui/react'

const Table = ({
  title,
  columns,
  actions,
  data,
  selection,
  filtering,
  loading,
  headerSize,
  actionIndex,
}) => {
  return (
    <>
      <MaterialTable
        id="filter-table"
        title={title}
        columns={columns}
        actions={actions}
        data={data}
        isLoading={loading}
        localization={{
          header: {
            actions: 'कार्यहरू',
          },
        }}
        options={{
          // exportButton: true,
          selection: selection,
          actionsColumnIndex: actionIndex || -1,
          filtering: filtering,
          headerStyle: {
            backgroundColor: '#321FDB',
            color: '#FFF',
          },
        }}
      />
    </>
  )
}

export default Table

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  actions: PropTypes.array,
  data: PropTypes.array,
  selection: PropTypes.bool,
  filtering: PropTypes.bool,
}

Table.defaultProps = {
  title: '',
  columns: [],
  actions: [],
  data: [],
  selection: false,
  filtering: false,
}
