import React, { useState } from 'react'
import AdalatForm from './AdalatForm'
import AdalatList from './AdalatList'

const Adalat = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      <AdalatForm reload={reload} setReload={setReload} />
      <AdalatList reload={reload} setReload={setReload} />
    </>
  )
}
export default Adalat
