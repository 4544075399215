import React, { useState, useEffect, useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormLabel,
  CFormInput,
  CRow,
  CCol,
  CFormSelect,
  CButton,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { UserContext } from 'src/context/UserContext'
import { GadanaAPI } from './GadanaAPI'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CONSTANTS } from 'src/constants'
import { convert_to_preeti } from 'src/helpers/PreetiConverter'

const initialState = {
  id: null,
  month: '1',
  day: '',
}

const GadanaForm = ({
  open,
  editMode,
  handleClose,
  editData,
  reload,
  setReload,
  fetching,
  selectedId,
}) => {
  // Response State
  const [response, setResponse] = useState({
    success: null,
    error: null,
  })
  // Gadana data
  const [gadanaData, setGadanaData] = useState(initialState)

  // Lifecycle method to
  useEffect(() => {
    // console.log(editData)
    if (editData) {
      setGadanaData({
        id: editData.id,
        month: editData.month,
        day: editData.day,
      })
    }
  }, [editData])

  useEffect(() => {
    if (open === false) {
      setResponse({
        success: null,
        error: null,
      })
    }
  }, [open])
  // User Context
  const [user] = useContext(UserContext)

  const [loading, setLoading] = useState(false)

  // function to handle gadana data changes
  const handleGadanaDataChange = (e, convert = true) => {
    if (convert) {
      setGadanaData({ ...gadanaData, [e.target.name]: convert_to_preeti(e.target.value) })
    } else {
      setGadanaData({ ...gadanaData, [e.target.name]: e.target.value })
    }
  }

  // Function to create Gadana
  const handleSubmit = (e) => {
    e.preventDefault()
    if (gadanaData.month === '' || gadanaData.day === '') {
      return
    }
    setLoading(true)
    {
      editMode
        ? GadanaAPI.editGadana(user.access_token, gadanaData, selectedId)
            .then((res) => {
              if (res.data.status === true) {
                setGadanaData(initialState)
                setResponse({
                  error: null,
                  success: 'Gadana has been updated',
                })
                setLoading(false)
              } else {
                console.log(res.data)
                setResponse({
                  error: 'Failed to update',
                  success: null,
                })
                setLoading(false)
              }
            })
            .catch((e) => console.log(e.message))
        : GadanaAPI.createGadana(user.access_token, gadanaData)
            .then((res) => {
              if (res.data.status === true) {
                setLoading(false)
                setGadanaData(initialState)
                setResponse({
                  error: null,
                  success: 'New Gadana has been created',
                })
                setReload(!reload)
              }
            })
            .catch((e) => {
              setLoading(false)
              setResponse({
                success: null,
                error: e.message,
              })
            })

      setTimeout(
        () =>
          setResponse({
            success: null,
            error: null,
          }),
        CONSTANTS.ALERT_TIME_OUT,
      )
    }
  }

  return (
    <CCard className="mb-4">
      <CCardHeader>{editMode ? 'Edit Gadana' : 'नयाँ गणना मिति'}</CCardHeader>
      <CCardBody>
        {response.success ? <Alert severity="success">{response.success}</Alert> : null}
        {response.error ? <Alert severity="error">{response.error}</Alert> : null}

        {fetching ? (
          <CircularProgress />
        ) : (
          <CForm className={response.success || response.error ? 'mt-4' : null}>
            <CRow>
              <CCol md="6">
                <div className="mb-3">
                  <CFormLabel htmlFor="gadanaName">महिना</CFormLabel>
                  <CFormSelect
                    className="mb-3"
                    name="month"
                    value={gadanaData.month}
                    onChange={(e) => handleGadanaDataChange(e, false)}
                    aria-label="Large select example"
                  >
                    <option value="1">वैशाख </option>

                    <option value="2">ज्येष्ठ</option>

                    <option value="3">आषाढ़</option>

                    <option value="4">श्रावण </option>

                    <option value="5">भदौ</option>

                    <option value="6">आश्विन</option>

                    <option value="7">कार्तिक </option>

                    <option value="8">मंसिर</option>

                    <option value="9">पौष</option>

                    <option value="10">माघ </option>

                    <option value="11">फाल्गुण</option>

                    <option value="12">चैत्र</option>
                  </CFormSelect>

                  {/* <CFormInput
                    type="text"
                    id="gadanaName"
                    placeholder=""
                    name="name"
                    value={gadanaData.name}
                    onChange={handleGadanaDataChange}
                    // onKeyUp={conversion}
                  /> */}
                </div>
              </CCol>

              <CCol md="6">
                <div className="mb-3">
                  <CFormLabel htmlFor="gadanaName">दिन</CFormLabel>

                  <CFormInput
                    type="text"
                    id="gadanaName"
                    placeholder=""
                    name="day"
                    value={gadanaData.day}
                    onChange={handleGadanaDataChange}
                    // onKeyUp={conversion}
                  />
                </div>
              </CCol>

              <CCol md="12">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <CButton type="submit" color="primary" size="sm" onClick={handleSubmit}>
                    {editMode ? 'Save' : 'गणना मिति थप्नुहोस'}
                  </CButton>
                )}
                &nbsp;{' '}
                {editMode ? (
                  <CButton color="danger" size="sm" variant="outline" onClick={handleClose}>
                    Close
                  </CButton>
                ) : null}
              </CCol>
            </CRow>
          </CForm>
        )}
      </CCardBody>
    </CCard>
  )
}

export default GadanaForm

GadanaForm.propTypes = {
  editMode: PropTypes.bool,
  handleClose: PropTypes.func,
}

GadanaForm.defaultProps = {
  editMode: false,
  handleClose: () => console.log('Clicked'),
}
