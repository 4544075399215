import React, { useState } from 'react'
import KaryalayaForm from './KaryalayaForm'
import KaryalayaList from './KaryalayaList'

const Karyalaya = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      <KaryalayaForm reload={reload} setReload={setReload} />
      <KaryalayaList reload={reload} setReload={setReload} />
    </>
  )
}
export default Karyalaya
