import { CButton } from '@coreui/react'
import React, { useState } from 'react'
import ContentBox from 'src/components/ContentBox/ContentBox'
import AnusuchiForm from './AnusuchiForm'
import AnusuchiList from './AnusuchiList'
import { useHistory } from 'react-router-dom'

const Anusuchi = () => {
  const [reload, setReload] = useState(false)
  const history = useHistory()

  return (
    <>
      <ContentBox title="नयाँ अनुसूची फारम">
        <CButton onClick={() => history.push('/anusuchi/create')} size="sm">
          नयाँ फारम{' '}
        </CButton>
      </ContentBox>
      <AnusuchiList reload={reload} setReload={setReload} />
    </>
  )
}
export default Anusuchi
