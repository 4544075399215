import React, { useState, useEffect, useContext } from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import Table from 'src/components/Table/Table'
import { AnusuchiTableContent } from './AnusuchiTableContent'
import MyDialog from 'src/components/Dialog/Dialog'
import AnusuchiForm from './AnusuchiForm'
import ContentBox from 'src/components/ContentBox/ContentBox'
import { CButton } from '@coreui/react'
import { AnusuchiAPI } from './AnusuchiAPI'
import { UserContext } from 'src/context/UserContext'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { CONSTANTS } from 'src/constants'
import { PrintContext } from 'src/context/PrintContext'
import AnusuchiOnePdf from './Anusuchi1Pdf'
import AnusuchiTwoPdf from './Anusuchi2Pdf'
import AnusuchiThreePdf from './Anusuchi3Pdf'

function printDiv(id) {
  var divContents = id.innerHTML
  var a = window.open('', 'My Div', 'height=700, width=700')
  a.document.write('<html>')
  a.document.write('<body > ')
  a.document.write(divContents)
  a.document.write('</body></html>')
  a.document.close()
  a.print()
}

const generatePdf1 = () => {
  const element = document.querySelector('#anusuchi-1')
  printDiv(element)

  // element.print()
  // const options = {
  //   filename: 'anusuchi1.pdf',
  // }

  // return domToPdf(element, options, (pdf) => {
  //   console.log('Pdf created')
  // })
}

const generatePdf2 = (id) => {
  const element = document.querySelector(`#anusuchitwo-${id}`)
  printDiv(element)

  // PrintElem(element)

  // const options = {
  //   filename: 'anusuchi2.pdf',
  // }

  // return domToPdf(element, options, () => {
  //   // callback function
  // })
}

const generatePdf3 = () => {
  const element = document.querySelector('#anusuchi-3')
  printDiv(element)

  // PrintElem(element)

  // const options = {
  //   filename: 'anusuchi3.pdf',
  // }

  // return domToPdf(element, options, () => {
  //   // callback function
  // })
}

const generatePdf = (printData) => {
  generatePdf1()
  for (let i = 0; i < printData.length; i++) {
    generatePdf2(i)
  }
  generatePdf3()
}

const AnusuchiList = ({ reload, setReload }) => {
  // Selected Anusuchi
  const [selectedAnusuchi, setSelectedAnusuchi] = useState(null)
  const [printData, setPrintData] = useContext(PrintContext)
  const [response, setResponse] = useState({
    success: null,
    error: null,
  })

  // State maintains loading while creating data
  const [loading, setLoading] = useState(false)
  // state maintains table data
  const [loadingTableData, setLoadingTableData] = useState(true)

  // State to maintain loading while fetching individual data
  const [fetching, setFetching] = useState(false)

  // Selected Item
  const [selectedItem, setSelectedItem] = useState(null)
  // User Context
  const [user] = useContext(UserContext)
  // State to store anusuchies
  const [anusuchies, setAnusuchies] = useState([])

  // State to handle edit dialog box
  const [openEdit, setOpenEdit] = useState(false)

  // State to handle delete dialog box

  const [openDelete, setOpenDelete] = useState(false)

  // State to handle anusuchi submit
  const [openSubmit, setOpenSubmit] = useState(false)

  const [allMaafiId, setAllMaafiId] = useState([])

  const [openMaafi, setOpenMaafi] = useState(false)

  const handleMaafiOpen = (rowData) => {
    setOpenMaafi(true)
    let temp = []
    for (let i = 0; i < rowData.length; i++) {
      temp.push(rowData[i].id)
    }
    setAllMaafiId(temp)
  }

  const handleMaafiClose = () => {
    setOpenMaafi(false)
  }

  // state to handle data to be submitted
  const [dataToBeSubmitted, setDataToBeSubmitted] = useState([])

  useEffect(() => {
    if (selectedItem) {
      let res = {}
      res = { ...selectedItem }

      if (res.anusuchi2) {
        for (let i = 0; i < res.anusuchi2.length; i++) {
          // console.log('I have entered I')
          for (let j = 0; j < Object.keys(res.anusuchi2[i]).length; j++) {
            // console.log('I have entered J')

            res[Object.keys(res.anusuchi2[i])[j]] =
              res.anusuchi2[i][Object.keys(res.anusuchi2[i])[j]]
          }
        }
      }

      if (res.anusuchi3) {
        for (let i = 0; i < res.anusuchi3.length; i++) {
          // console.log('I have entered I')
          for (let j = 0; j < Object.keys(res.anusuchi3[i]).length; j++) {
            // console.log('I have entered J')

            res[Object.keys(res.anusuchi3[i])[j]] =
              res.anusuchi3[i][Object.keys(res.anusuchi3[i])[j]]
          }
        }
      }

      console.log(res)
      setSelectedAnusuchi(res)
    }
  }, [selectedItem])

  // edit Menu Handler
  const handleEditOpen = (rowData) => {
    setOpenEdit(true)
    setSelectedItem(rowData)
  }
  const handleEditClose = () => {
    setOpenEdit(false)
    setReload(!reload)
    setSelectedItem(null)
  }

  // Delete Dialog Handler
  const handleDeleteOpen = (id) => {
    setOpenDelete(true)
    setSelectedItem(id)
  }
  const handleDeleteClose = () => {
    setOpenDelete(false)
    setReload(!reload)
    setSelectedItem(null)
  }

  // Delete Dialog Handler
  const handleSubmitOpen = (rowData) => {
    // console.log('Row Data is', rowData)
    setOpenSubmit(true)
    if (rowData) {
      let temp = []
      for (let i = 0; i < rowData.length; i++) {
        temp.push(rowData[i].id)
      }

      setDataToBeSubmitted(temp)
    }
    console.log('Selected Data are', rowData)
  }

  const giveMaafi = () => {
    AnusuchiAPI.giveMaafiFunction(user?.access_token, allMaafiId)
      .then((res) =>
        setResponse({
          success: 'कैदीहरु ले माफी पाएका छन्।',
          error: null,
        }),
      )
      .catch((e) => {
        setResponse({
          success: null,
          error: 'Server Error',
        })
      })

    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      })
    }, 3000)

    handleMaafiClose()
    setReload(!reload)
  }

  const handleSubmitClose = () => {
    setOpenSubmit(false)
  }

  // Lifecycle method to fetch anusuchi

  useEffect(() => {
    setLoadingTableData(true)
    AnusuchiAPI.get(user ? user.access_token : null)
      .then((res) => {
        setAnusuchies(res.data.data)
        setLoadingTableData(false)
      })
      .catch((e) => console.log(e.message))
  }, [reload])

  // Function to delete anusuchi
  const DeleteAnusuchi = async () => {
    setLoading(true)
    await AnusuchiAPI.delete(user ? user.access_token : null, selectedItem.id)
      .then((res) => {
        if (res.data.status === true) {
          setResponse({
            success: 'Anusuchi has been deleted successfully',
            error: null,
          })
        } else {
          setResponse({
            success: null,
            error: 'Failed to delete ! Try again later.',
          })
        }
      })
      .catch((e) => console.log(e.message))

    setLoading(false)

    handleDeleteClose()
    setTimeout(
      () =>
        setResponse({
          success: null,
          error: null,
        }),
      CONSTANTS.ALERT_TIME_OUT,
    )
  }

  const submitToCentral = async () => {
    console.log('Datas to be submitted are')
    setLoading(true)
    await AnusuchiAPI.submitToCentral(user ? user.access_token : null, dataToBeSubmitted)
      .then((res) => {
        if (res.data.status) {
          handleSubmitClose()
          setResponse({
            success: 'तपाईंको आवेदन पेश गरिएको छ।',
            error: null,
          })
        } else {
          handleSubmitClose()
          setResponse({
            success: null,
            error: ' आवेदन पेश गर्न असफल भयो।',
          })
        }
      })
      .catch((e) => console.log(e.message))

    setLoading(false)

    setTimeout(
      () =>
        setResponse({
          success: null,
          error: null,
        }),
      CONSTANTS.ALERT_TIME_OUT,
    )
  }

  return (
    <CCard className="mb-4">
      <div id="divToPrint1" className="d-none">
        <AnusuchiOnePdf />
      </div>
      {printData.map((data, id) => (
        <div id={`divToPrint${id}`} className="d-none" key={id}>
          <AnusuchiTwoPdf id={id} data={data} />
        </div>
      ))}

      <div id="divToPrint3" className="d-none">
        <AnusuchiThreePdf />
      </div>
      {/* <div id="divToPrint2" className="d-none">
        <AnusuchiTwoPdf />
      </div>

     
      </div> */}
      <CCardHeader>अनुसूची फारम</CCardHeader>
      <CCardBody>
        {response.success ? (
          <div className="mb-3">
            {' '}
            <Alert severity="success">{response.success}</Alert>
          </div>
        ) : null}
        {response.error ? (
          <div className="mb-3">
            <Alert severity="error">{response.error}</Alert>
          </div>
        ) : null}
        {/* Anusuchi Lists */}
        <Table
          columns={AnusuchiTableContent.getTableHeadings()}
          data={AnusuchiTableContent.getTableDatas(anusuchies)}
          actions={AnusuchiTableContent.getDataActions(
            handleEditOpen,
            handleDeleteOpen,
            setPrintData,
            generatePdf,
            handleSubmitOpen,
            handleMaafiOpen,
            giveMaafi,
          )}
          loading={loadingTableData}
          selection={true}
        />

        {/* Edit Dialog Box */}
        <MyDialog open={openEdit} handleClose={handleEditClose} fullScreen={true}>
          <AnusuchiForm
            open={openEdit}
            editMode={true}
            handleClose={handleEditClose}
            editData={selectedAnusuchi}
            fetching={fetching}
            selectedId={selectedItem}
          />
        </MyDialog>

        {/* Delete Dialog Box */}
        <MyDialog open={openDelete} handleClose={handleDeleteClose}>
          <ContentBox title="Remove Anusuchi">
            <p>Do you want to remove this anusuchi?</p>
            {loading ? (
              <CircularProgress />
            ) : (
              <CButton color="primary" size="sm" onClick={DeleteAnusuchi}>
                Confirm
              </CButton>
            )}
            &nbsp;{' '}
            <CButton color="danger" size="sm" variant="outline" onClick={handleDeleteClose}>
              Close
            </CButton>
          </ContentBox>
        </MyDialog>

        {/* Submit Dialog */}
        {/* Edit Dialog Box */}
        <MyDialog open={openSubmit} handleClose={handleSubmitClose}>
          <ContentBox title="आवेदन पेश गर्नुहोस्">
            <p>के तपाइँ यी आवेदनहरू केन्द्रीय कार्यालयमा पठाउन चाहनुहुन्छ?</p>
            {loading ? (
              <CircularProgress />
            ) : (
              <CButton color="primary" size="sm" onClick={submitToCentral}>
                पेश गर्नुहोस्
              </CButton>
            )}
            &nbsp;{' '}
            <CButton color="danger" size="sm" variant="outline" onClick={handleSubmitClose}>
              रद्द गर्नुहोस्
            </CButton>
          </ContentBox>
        </MyDialog>

        <MyDialog open={openMaafi} handleClose={handleMaafiClose}>
          <ContentBox
            title="
कैदी माफी"
          >
            <p>के तपाई यो कार्य गर्न चाहनुहुन्छ?</p>
            {loading ? (
              <CircularProgress />
            ) : (
              <CButton color="primary" size="sm" onClick={giveMaafi}>
                पेश गर्नुहोस्
              </CButton>
            )}
            &nbsp;{' '}
            <CButton color="danger" size="sm" variant="outline" onClick={handleMaafiClose}>
              रद्द गर्नुहोस्
            </CButton>
          </ContentBox>
        </MyDialog>
      </CCardBody>
    </CCard>
  )
}

export default AnusuchiList
