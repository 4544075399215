import React from 'react'
import NepaliDate from 'nepali-date'
import { NepaliNumber } from 'src/helpers/NepaliNumber'

// Create Document Component
const AnusuchiTwoPdf = ({ data, id }) => {
  return (
    <div id={`anusuchitwo-${id}`} style={{ marginTop: '2rem' }}>
      <header>
        <h4 style={{ textAlign: 'center' }}>अनुसूची २</h4>
        <p style={{ textAlign: 'center' }}>(नियम ५ को उपनियम ( १ ) संग सम्बन्धित)</p>
      </header>

      {/*  */}
      {/* Subject section */}
      <section id="subject">
        <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>
          कैद कट्टा गर्न दिने निवेदनको ढाँचा
        </h4>
      </section>

      {/* greeting section */}
      <section id="greeting" style={{ marginLeft: '3rem' }}>
        <div>
          <p>श्री प्रमुखज्यू,</p>
        </div>

        <div>
          <p>कारागार कार्यालय,{data?.Karagar_Karyalaya} ।</p>
        </div>
      </section>

      <section id="subject">
        <p style={{ textAlign: 'center', textDecoration: 'underline' }}>
          विषय: कैद कट्टा गरी पाउँ ।
        </p>
      </section>

      {/* Form */}
      <section id="form" style={{ marginLeft: '3rem' }}>
        <div>
          <p>
            १. &nbsp; निवेदकको पूरा नाम, थर र ठेगाना: {data?.anusuchi2[0]?.nibedako_nam_thar} {' ,'}
            {data.kaidi_ko_desh === 'नेपाल'
              ? data.kaidi_ko_sthaniya + ' ' + data.kaidi_ko_wada + ',' + ' ' + data.kaidi_ko_jilla
              : data.kaidi_ko_desh}{' '}
          </p>
        </div>

        <div>
          <p>२. &nbsp; मुद्दा: {data.mudda ? data.mudda.join(',') : null} </p>
        </div>

        <div>
          <p>
            ३. &nbsp; वादी र प्रतिवादीहरुको नाम, थर र ठेगाना:
            {data.anusuchi2[0]?.baadi_ra_pratibadhiko_nam_thar},
            {' ' + data.anusuchi2[0]?.baadi_ra_pratibadhiko_thegana}
          </p>
        </div>

        <div>
          <p>४. &nbsp; मुद्दा फैसला गर्ने अदालत: {data.anusuchi2[0]?.adalat}</p>
        </div>

        <div>
          <p>५. &nbsp; भएको सजाय: {NepaliNumber(data.bhayeko_sajaye)}</p>
        </div>

        <div>
          <p>
            ६ . &nbsp; मुद्दा अन्तिम भएको मिति वा पुनरावेदन लाग्ने भए पुनरावेदनको म्याद समाप्त भएको
            मिति: {NepaliNumber(data.anusuchi2[0]?.mudda_aantim_bhayeko_miti)}
          </p>
        </div>

        <div>
          <p>७. &nbsp; हालसम्म भुक्तान गरेको सजाय: {NepaliNumber(data.kaaid_bhuktan_awadhi)}</p>
        </div>

        <div>
          <p>
            ८. &nbsp; कैद कट्टा गर्नु पर्ने आधार र औचित्य: {data.anusuchi2[0]?.kaiid_katta_aadhar}{' '}
          </p>
        </div>

        <div>
          <p>
            ९. &nbsp; कैदीको स्वघोषणा: अब उप्रान्त कुनै पनि किसिमको कसूरजन्य क्रियाकलापमा संग्लग्न
            हुने छैन । कुनै कसुरमा संग्लग्न भएमा कैद कट्टा भएको सजाय समेत भोग्न मञ्जुर गर्दछु ।
          </p>
        </div>

        <div>
          <p>
            मैले मलाई भएको सजाय उपर पुनरावेदन गरेको वा पुनरावलोकन वा मुद्दा दोहोराउने निवेदन दिई सो
            विचाराधीन रहेको अवस्था छैन । व्यहोरा साँचो हो, झुट्टा ठहरे कानून बमोजिम सहुँला बुझाउँला
            ।
          </p>
        </div>

        <div>
          <p>म आफुले गरेको कसुरका लागि क्षमायाचना गर्दछु ।</p>
        </div>
      </section>

      {/* Signatture section */}
      <section id="signature">
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            // alignContent: 'flex-start',
          }}
        >
          <table>
            <tr>
              <td>निवेदकको;</td>
            </tr>

            <tr>
              <td>नाम थर : </td>
              <td>{data.anusuchi2[0]?.nibedako_nam_thar}</td>
            </tr>

            <tr>
              <td>दस्तखत:</td>
              <td>.........................</td>
            </tr>

            <tr>
              <td>मिति:</td>
              <td>
                {' '}
                {NepaliNumber(
                  `${new NepaliDate().getYear()}-${
                    new NepaliDate().getMonth() + 1
                  }-${new NepaliDate().getDate()}`,
                )}
              </td>
            </tr>
          </table>
          {/* <div>
            <p style={{ textAlign: 'right' }}>निवेदकको,</p>
          </div>

          <div>
            <p style={{ textAlign: 'right' }}>नाम , थर : {data.anusuchi2[0]?.nibedako_nam_thar}</p>
          </div>

          <div>
            <p style={{ textAlign: 'right' }}>दस्तखत:.................................</p>
          </div>

          <div>
            <p style={{ textAlign: 'right' }}>
              मिति:
              {NepaliNumber(
                `${new NepaliDate().getYear()}-${
                  new NepaliDate().getMonth() + 1
                }-${new NepaliDate().getDate()}`,
              )}
            </p>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default AnusuchiTwoPdf
