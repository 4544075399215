import React, { useState } from 'react'
import PuranoAbedanForm from './PuranoAbedanForm'
import PuranoAbedanList from './PuranoAbedanList'

const PuranoAbedan = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      {/* <PuranoAbedanForm reload={reload} setReload={setReload} /> */}
      <PuranoAbedanList reload={reload} setReload={setReload} />
    </>
  )
}
export default PuranoAbedan
