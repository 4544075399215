import React, { useState, useEffect, useContext } from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import Table from 'src/components/Table/Table'
import { OldAnusuchiTableContent } from './OldAnusuchiTableContent'

import { UserContext } from 'src/context/UserContext'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { CONSTANTS } from 'src/constants'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const OldAnusuchiList = ({ reload, setReload }) => {
  const history = useHistory()

  const [response, setResponse] = useState({
    success: null,
    error: null,
  })

  // State maintains loading while creating data
  const [loading, setLoading] = useState(false)
  // state maintains table data
  const [loadingTableData, setLoadingTableData] = useState(true)
  const [user] = useContext(UserContext)

  const [maafiData, setMaafiData] = useState([])
  const [allDates, setAllDates] = useState([])

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/application/get-maafi
      `,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    })
      .then((res) => setMaafiData(res.data))
      .catch((e) => console.log(e.message))
  }, [])

  useEffect(() => {
    // let data = Object.keys(maafiData)
    let res = []
    for (const key in maafiData) {
      res.push({ date: key, datas: maafiData[key] })
    }
    setAllDates(res)
    setLoadingTableData(false)
  }, [maafiData])

  return (
    <CCard className="mb-4">
      <CCardHeader>पुराना गणना मिति</CCardHeader>
      <CCardBody>
        {response.success ? (
          <div className="mb-3">
            {' '}
            <Alert severity="success">{response.success}</Alert>
          </div>
        ) : null}
        {response.error ? (
          <div className="mb-3">
            <Alert severity="error">{response.error}</Alert>
          </div>
        ) : null}
        {/* OldAnusuchi Lists */}
        <Table
          columns={OldAnusuchiTableContent.getTableHeadings()}
          data={OldAnusuchiTableContent.getTableDatas(allDates)}
          actions={OldAnusuchiTableContent.getDataActions(history)}
          loading={loadingTableData}
        />

        {/* Edit Dialog Box */}
      </CCardBody>
    </CCard>
  )
}

export default OldAnusuchiList
