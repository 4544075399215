import React from 'react'
import axios from 'axios'
import { CONSTANTS } from 'src/constants'

export const AbedanAPI = {
  // ...

  // mapping the API of interest
  get: function (token) {
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/application/central`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  // ...
}
