import React, { useContext, useEffect, useState } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

import { UserContext } from '../context/UserContext'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
const DefaultLayout = () => {
  const [user] = useContext(UserContext)

  if (user) {
    return (
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <AppContent />
          </div>
          <AppFooter />
        </div>
      </div>
    )
  }

  return <Redirect to="/" />
}

export default DefaultLayout
