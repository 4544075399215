import React, { createContext, useEffect, useState } from 'react'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (sessionStorage.getItem('user')) {
      setUser(JSON.parse(sessionStorage.getItem('user')))
    }
  }, [])
  useEffect(() => {
    sessionStorage.setItem('user', JSON.stringify(user))
  }, [user])
  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>
}

export const FirstLoginContext = createContext()

export const FirstLoginProvider = ({ children }) => {
  const [firstLogin, setFirstLogin] = useState('false')

  useEffect(() => {
    if (sessionStorage.getItem('firstLogin')) {
      setFirstLogin(sessionStorage.getItem('firstLogin'))
    }
  }, [])
  useEffect(() => {
    sessionStorage.setItem('firstLogin', firstLogin)
  }, [firstLogin])

  console.log(firstLogin)

  return (
    <FirstLoginContext.Provider value={[firstLogin, setFirstLogin]}>
      {children}
    </FirstLoginContext.Provider>
  )
}
