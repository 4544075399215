export const bhayeko_sajaye_calculator = (
  chutti_miti,
  kaid_miti,
  includeHirasatDin = true,
  hirasat_din,
  returnType = 'string',
) => {
  let chutti_arr = chutti_miti?.split('-')
  let kaid_arr = kaid_miti.split('-')
  let res = {
    year: '',
    month: '',
    day: '',
  }

  if (parseInt(chutti_arr[2]) >= parseInt(kaid_arr[2])) {
    res['day'] = parseInt(chutti_arr[2]) - parseInt(kaid_arr[2])
  } else {
    res['day'] = parseInt(chutti_arr[2]) + 30 - parseInt(kaid_arr[2])
    chutti_arr[1] = parseInt(chutti_arr[1]) - 1
  }

  if (parseInt(chutti_arr[1]) >= parseInt(kaid_arr[1])) {
    res['month'] = parseInt(chutti_arr[1]) - parseInt(kaid_arr[1])
  } else {
    res['month'] = parseInt(chutti_arr[1]) + 12 - parseInt(kaid_arr[1])
    chutti_arr[0] = parseInt(chutti_arr[0]) - 1
  }

  res['year'] = parseInt(chutti_arr[0]) - parseInt(kaid_arr[0])
  if (hirasat_din && includeHirasatDin === false) {
    console.log('Hirasat din detected', hirasat_din)
    res['day'] = res['day'] + hirasat_din
    while (res['day'] >= 30) {
      res['day'] = res['day'] - 30
      res['month'] = res['month'] + 1
    }

    while (res['month'] >= 12) {
      res['month'] = res['month'] - 12
      res['year'] = res['year'] + 1
    }
  }

  while (res['day'] >= 30) {
    res['day'] = res['day'] - 30
    res['month'] = res['month'] + 1
  }

  while (res['month'] >= 12) {
    res['month'] = res['month'] - 12
    res['year'] = res['year'] + 1
  }

  if (returnType === 'string') {
    return `${res.year}/${res.month}/${res.day}`
  } else if (returnType === 'number') {
    return res
  } else {
    return res.year * 365 + res.month * 30 + res.day
  }
}

export const bhuktan_sajaye_calculator = (
  gadana_miti,
  kaid_miti,
  hirasat_din,
  includeHirasatDin,
  chutti_miti,
  returnType,
) => {
  // console.log('Gadana miti is', gadana_miti)
  // console.log(hirasat_din)
  if (gadana_miti !== '' && gadana_miti !== null) {
    const bhayekoSajaye = bhayeko_sajaye_calculator(
      chutti_miti,
      kaid_miti,
      includeHirasatDin,
      hirasat_din,
      'day',
    )

    // console.log('Bhayeko Sajaye is', bhayekoSajaye)
    let gadana_arr = gadana_miti.split('-')
    // console.log('Gadana Array is', gadana_arr)
    let kaid_arr = kaid_miti.split('-')
    // console.log('Kaid Aray is', kaid_arr)
    let res = {
      year: '',
      month: '',
      day: '',
      percent: '',
    }

    // console.log(kaid_arr)

    if (parseInt(gadana_arr[2]) >= parseInt(kaid_arr[2])) {
      res['day'] = parseInt(gadana_arr[2]) - parseInt(kaid_arr[2])
    } else {
      res['day'] = parseInt(gadana_arr[2]) + 30 - parseInt(kaid_arr[2])
      gadana_arr[1] = parseInt(gadana_arr[1]) - 1
    }

    if (parseInt(gadana_arr[1]) >= parseInt(kaid_arr[1])) {
      res['month'] = parseInt(gadana_arr[1]) - parseInt(kaid_arr[1])
    } else {
      res['month'] = parseInt(gadana_arr[1]) + 12 - parseInt(kaid_arr[1])
      gadana_arr[0] = parseInt(gadana_arr[0]) - 1
    }

    res['year'] = parseInt(gadana_arr[0]) - parseInt(kaid_arr[0])

    if (hirasat_din) {
      // console.log('Hirasat din detected', hirasat_din)
      res['day'] = res['day'] + hirasat_din
      while (res['day'] >= 30) {
        res['day'] = res['day'] - 30
        res['month'] = res['month'] + 1
      }

      while (res['month'] >= 12) {
        res['month'] = res['month'] - 12
        res['year'] = res['year'] + 1
      }
    }

    const bhuktanSajaye = res.year * 365 + res.month * 30 + res.day

    res['percent'] = ((bhuktanSajaye / bhayekoSajaye) * 100).toFixed(2)

    // console.log('Res is', res)

    if (returnType === 'percent') {
      return res.percent
    } else if (returnType === 'onlyDate') {
      return `${res.year}/${res.month}/${res.day}`
    } else {
      return `${res.year}/${res.month}/${res.day}/${res.percent}`
    }
  }
}

export const chotteunu_parney_kaid_calculator = (
  chutti_miti,
  gadana_miti,
  kaid_miti,
  hirasat_din,
  includeHirasatDin,
) => {
  const bhuktanPercent = bhuktan_sajaye_calculator(
    gadana_miti,
    kaid_miti,
    hirasat_din,
    includeHirasatDin,
    chutti_miti,
    'percent',
  )

  let chutti_arr = chutti_miti.split('-')
  let gadana_arr = null
  if (gadana_miti !== null) {
    gadana_arr = gadana_miti.split('-')
  }
  let res = {
    year: '',
    month: '',
    day: '',
    percent: '',
  }

  if (gadana_arr !== null) {
    if (parseInt(chutti_arr[2]) >= parseInt(gadana_arr[2])) {
      res['day'] = parseInt(chutti_arr[2]) - parseInt(gadana_arr[2])
    } else {
      res['day'] = parseInt(chutti_arr[2]) + 30 - parseInt(gadana_arr[2])
      chutti_arr[1] = parseInt(chutti_arr[1]) - 1
    }

    if (parseInt(chutti_arr[1]) >= parseInt(gadana_arr[1])) {
      res['month'] = parseInt(chutti_arr[1]) - parseInt(gadana_arr[1])
    } else {
      res['month'] = parseInt(chutti_arr[1]) + 12 - parseInt(gadana_arr[1])
      chutti_arr[0] = parseInt(chutti_arr[0]) - 1
    }

    res['year'] = parseInt(chutti_arr[0]) - parseInt(gadana_arr[0])

    if (hirasat_din) {
      console.log('Hirasat din detected', hirasat_din)
      const chottaunu_din = res['year'] * 360 + res['month'] * 30 + res['day']

      console.log('Chootaunu din', chottaunu_din)
      let temp = chottaunu_din - hirasat_din
      // console.log('Temp is', temp)
      let myYear = parseInt(temp / 360)
      // console.log('My Year is', myYear)

      let myMonth = parseInt((temp - myYear * 360) / 30)
      // console.log('My month is', myMonth)

      let myDay = parseInt(temp - myYear * 360 - myMonth * 30)
      // console.log('My Day is', myDay)

      res['year'] = myYear
      res['month'] = myMonth
      res['day'] = myDay
    }

    res['percent'] = (100 - parseFloat(bhuktanPercent)).toFixed(2)
  }

  return `${res.year}/${res.month}/${res.day}/${res.percent}`
}

export const chotauney_kaid = (kaid_bhuktan, tokiyeko_kaid, bhuktan_percent) => {
  if (kaid_bhuktan && tokiyeko_kaid) {
    const tokiyeko_array = tokiyeko_kaid.split('/')
    const bhuktan_array = kaid_bhuktan.split('/')
    // console.log('Tokiyeko Array', tokiyeko_array)
    // console.log('Bhuktan array', bhuktan_array)

    let res = {
      year: '',
      month: '',
      day: '',
      percent: '',
    }

    if (parseInt(tokiyeko_array[2]) < parseInt(bhuktan_array[2])) {
      res.day = parseInt(tokiyeko_array[2]) + 30 - parseInt(bhuktan_array[2])
      tokiyeko_array[1] = parseInt(tokiyeko_array[1]) - 1
    } else {
      res.day = parseInt(tokiyeko_array[2]) - parseInt(bhuktan_array[2])
    }

    if (parseInt(tokiyeko_array[1]) < parseInt(bhuktan_array[1])) {
      res.month = parseInt(tokiyeko_array[1]) + 12 - parseInt(bhuktan_array[1])
      tokiyeko_array[0] = parseInt(tokiyeko_array[0]) - 1
    } else {
      res.month = parseInt(tokiyeko_array[1]) - parseInt(bhuktan_array[1])
    }

    res.year = parseInt(tokiyeko_array[0]) - parseInt(bhuktan_array[0])
    res['percent'] = (100 - parseFloat(bhuktan_percent)).toFixed(2)

    console.log('Chotauney Kaid', res.year + '-' + res.month + '-' + res.day)
    return `${res.year}/${res.month}/${res.day}/${res.percent}`
  }
}
