import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    background: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 900,
    marginBottom: '1rem',
  },
  dialogPaper: {
    // minHeight: '80vh',
    // maxHeight: '80vh',
    minWidth: '500px',
  },
}))

export default function MyDialog({ open, handleClose, title, children, buttons, fullScreen }) {
  const theme = useTheme()
  const classes = useStyles()
  //   const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreen ? 'lg' : 'sm'))
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paper: classes.dialogPaper }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        {/* <DialogTitle
          id="alert-dialog-slide-title"
          classes={{
            root: classes.title,
          }}
        >
          {title}
        </DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description"> */}
          {children}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  )
}

MyDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.any,
  buttons: PropTypes.array,
}

MyDialog.defaultProps = {
  title: '',
  open: false,
  handleClose: () => console.log('Clicked'),
  children: <></>,
  buttons: [],
}
