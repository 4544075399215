export const isAnusuchiValid = (list, state) => {
  for (let i = 0; i < list.length; i++) {
    if (
      list[i] &&
      (state[list[i]] === undefined ||
        state[list[i]] === '' ||
        state[list[i]] === null ||
        state[list[i]].length === 0)
    ) {
      return false
    }
  }
  return true
}
