export const NepaliMonthResolver = (index) => {
  if (index === '1') {
    return 'वैशाख'
  } else if (index === '2') {
    return 'ज्येष्ठ'
  } else if (index === '3') {
    return 'आषाढ़'
  } else if (index === '4') {
    return 'श्रावण'
  } else if (index === '5') {
    return 'भदौ'
  } else if (index === '6') {
    return 'आश्विन'
  } else if (index === '7') {
    return 'कार्तिक'
  } else if (index === '8') {
    return 'मंसिर'
  } else if (index === '9') {
    return 'पौष'
  } else if (index === '10') {
    return 'माघ'
  } else if (index === '11') {
    return 'फाल्गुण'
  } else if (index === '12') {
    return 'चैत्र'
  } else {
    return 'Invalid'
  }
}

export const NepaliDayResolver = (index) => {
  if (index === '१') {
    return '1'
  } else if (index === '२') {
    return '2'
  } else if (index === '३') {
    return '3'
  } else if (index === '४') {
    return '4'
  } else if (index === '५') {
    return '5'
  } else if (index === '६') {
    return '6'
  } else if (index === '७') {
    return '7'
  } else if (index === '८') {
    return '8'
  } else if (index === '९') {
    return '9'
  } else if (index === '१०') {
    return '10'
  } else if (index === '११') {
    return '11'
  } else if (index === '१२') {
    return '12'
  } else if (index === '१३') {
    return '13'
  } else if (index === '१४') {
    return '14'
  } else if (index === '१५') {
    return '15'
  } else if (index === '१६') {
    return '16'
  } else if (index === '१७') {
    return '17'
  } else if (index === '१८') {
    return '18'
  } else if (index === '१९') {
    return '19'
  } else if (index === '२०') {
    return '20'
  } else if (index === '२१') {
    return '21'
  } else if (index === '२२') {
    return '22'
  } else if (index === '२३') {
    return '23'
  } else if (index === '२४') {
    return '24'
  } else if (index === '२५') {
    return '25'
  } else if (index === '२६') {
    return '26'
  } else if (index === '२७') {
    return '27'
  } else if (index === '२८') {
    return '28'
  } else if (index === '२९') {
    return '29'
  } else if (index === '३०') {
    return '30'
  } else if (index === '३१') {
    return '31'
  } else if (index === '३२') {
    return '32'
  } else {
    return 'Invalid'
  }
}
