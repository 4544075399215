import React, { useEffect, useState, useContext } from 'react'
import ContentBox from 'src/components/ContentBox/ContentBox'
import Stepper from 'src/components/Stepper'
import { MuddaAPI } from '../mudda/MuddaAPI'
import { UserContext } from 'src/context/UserContext'
import { AdalatAPI } from '../adalat/AdalatAPI'
import { RastriyataAPI } from '../rastriyata/RastriyataAPI'
import { KaryalayaAPI } from '../karyalaya/KaryalayaAPI'
import axios from 'axios'
import { ZillaAdminAPI } from '../zillaAdmin/ZillaAdminAPI'
import {
  bhayeko_sajaye_calculator,
  bhuktan_sajaye_calculator,
  chotauney_kaid,
  chotteunu_parney_kaid_calculator,
} from 'src/helpers/DateCalculator'
import { GadanaAPI } from '../gadanaMiti/GadanaAPI'
import NepaliDate from 'nepali-date'
import { convert_to_preeti } from 'src/helpers/PreetiConverter'
import { EnglishNumber, NepaliNumber } from 'src/helpers/NepaliNumber'
import { useHistory } from 'react-router-dom'
const initialState = {
  // Anusuchi 1
  Karagar_Karyalaya: '',
  kaidi_ko_nam_thar: '',
  kaidi_ko_desh: '',
  kaidi_ko_jilla: '',
  kaidi_ko_sthaniya: '',
  kaidi_ko_wada: '',
  kaidi_ko_umer: '',
  mudda: [],
  bhayeko_sajaye: '',
  kaid_pareko_miti: '',
  nij_ko_mudda_nakaratmak: '',
  kaaid_bhuktan_awadhi: '',
  Kaid_ma_kunai_kisim_ko_anuchit_kam: '',
  anuchid_kam_ko_bibaran: '',
  karagar_pramuk_ko_raye: '',
  barsa: null,
  mahina: null,
  din: null,
  chutti_janey_miti: '',
  gadana_miti: '',
  includeHirasatDin: true,

  // Anusuchi 2
  nibedako_nam_thar: '',
  nibedako_desh: '',
  nibedako_jilla: '',
  nibedako_wada: '',
  baadi_ra_pratibadhiko_nam_thar: '',
  baadi_ra_pratibadhiko_thegana: '',
  adalat: '',
  adalat_jilla: '',
  mudda_aantim_bhayeko_miti: '',

  kaiid_katta_aadhar: '',

  // Anusuchi 3
  tokiyeko_kaiid: '',
  jaherwala_ko_nam: '',
  linga: '',
  rastriyata: '',
  punarabedan_karyalaya: '',
  punarabedan_jilla: '',
  punarabedan_miti: '',
  punarabedan_chalan_number: '',
  tokiyeko_kaiid_ra_jaribana_gari_kaiid: '',
  choteunu_parne_kaiid: '',
  kaiifeyad: '',
  kaiid_katti_garne_upayukta: '',
  kaaid_bhuktan_awadhi_percent: '',
  kaaid_bhuktan_percent: '',
  jariwana: '',
}
const Anusuchi = ({ editMode, editData, handleClose }) => {
  const [garekoKasurs, setGarekoKasurs] = useState([])
  const [loading, setLoading] = useState(false)
  const [user] = useContext(UserContext)
  const [anusuchiValue, setAnusuchiValue] = useState(initialState)
  const [zilla, setZilla] = useState([])
  const [mudda, setMudda] = useState([])
  const [adalat, setAdalat] = useState([])
  const [rastriyata, setRastriyata] = useState([])
  const [karyalaya, setKaryalaya] = useState([])
  const [gadanaMiti, setGadanaMiti] = useState([])
  const [allZilla, setAllZilla] = useState([])
  const [allSthaniya, setAllSthaniya] = useState([])
  const [response, setResponse] = useState({
    success: null,
    error: null,
  })

  // State to handle all dates
  const [chuttimMiti, setChuttiMiti] = useState('')
  const [muddaAntimMiti, setMuddaAntimMiti] = useState('')
  const [punarabedanMiti, setPunarabendanMiti] = useState('')
  const [kaidParekoMiti, setKaidParekoMiti] = useState('')

  const history = useHistory()

  useEffect(() => {
    if (editMode && editData) {
      setAnusuchiValue({
        ...editData,
        gadana_miti: editData.ganana_miti,
      })
      setChuttiMiti(editData.chutti_janey_miti)
      setMuddaAntimMiti(editData.mudda_aantim_bhayeko_miti)
      setPunarabendanMiti(editData.punarabedan_miti)
      setGarekoKasurs(editData.mudda)
      setKaidParekoMiti(editData.kaid_pareko_miti)
    }
  }, [editData, editMode])

  useEffect(() => {
    setAnusuchiValue({
      ...anusuchiValue,
      nibedako_nam_thar: anusuchiValue.kaidi_ko_nam_thar,
      nibedako_desh: anusuchiValue.kaidi_ko_desh,
      nibedako_jilla: anusuchiValue.kaidi_ko_jilla,
      nibedako_wada: anusuchiValue.kaidi_ko_wada,
      rastriyata: anusuchiValue.kaidi_ko_desh,
      tokiyeko_kaiid_ra_jaribana_gari_kaiid: anusuchiValue.bhayeko_sajaye,
    })
  }, [
    anusuchiValue.kaidi_ko_nam_thar,
    anusuchiValue.kaidi_ko_desh,
    anusuchiValue.kaidi_ko_jilla,
    anusuchiValue.kaidi_ko_wada,
    anusuchiValue.bhayeko_sajaye,
  ])

  useEffect(() => {
    setAnusuchiValue({
      ...anusuchiValue,
      chutti_janey_miti: chuttimMiti,
      mudda_aantim_bhayeko_miti: muddaAntimMiti,
      kaid_pareko_miti: kaidParekoMiti,
      punarabedan_miti: punarabedanMiti,
      tokiyeko_kaiid: anusuchiValue.tokiyeko_kaiid_ra_jaribana_gari_kaiid,
      bhayeko_sajaye: NepaliNumber(
        bhayeko_sajaye_calculator(
          chuttimMiti,
          kaidParekoMiti,
          anusuchiValue.includeHirasatDin,
          parseInt(
            anusuchiValue.barsa === '' || anusuchiValue.barsa === null
              ? 0
              : parseInt(EnglishNumber(anusuchiValue.barsa)),
          ) *
            360 +
            parseInt(
              anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.mahina)),
            ) *
              30 +
            parseInt(
              anusuchiValue.din === '' || anusuchiValue.din == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.din)),
            ),
        ),
      ),

      kaaid_bhuktan_awadhi: NepaliNumber(
        bhuktan_sajaye_calculator(
          anusuchiValue.gadana_miti,
          kaidParekoMiti,
          parseInt(
            anusuchiValue.barsa === '' || anusuchiValue.barsa === null
              ? 0
              : parseInt(EnglishNumber(anusuchiValue.barsa)),
          ) *
            360 +
            parseInt(
              anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.mahina)),
            ) *
              30 +
            parseInt(
              anusuchiValue.din === '' || anusuchiValue.din == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.din)),
            ),
          anusuchiValue.includeHirasatDin,

          chuttimMiti,
          'onlyDate',
        ),
      ),
      kaaid_bhuktan_awadhi_percent: NepaliNumber(
        bhuktan_sajaye_calculator(
          anusuchiValue.gadana_miti,
          kaidParekoMiti,
          parseInt(
            anusuchiValue.barsa === '' || anusuchiValue.barsa === null
              ? 0
              : parseInt(EnglishNumber(anusuchiValue.barsa)),
          ) *
            360 +
            parseInt(
              anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.mahina)),
            ) *
              30 +
            parseInt(
              anusuchiValue.din === '' || anusuchiValue.din == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.din)),
            ),
          anusuchiValue.includeHirasatDin,
          chuttimMiti,
        ),
      ),
      kaaid_bhuktan_percent: NepaliNumber(
        bhuktan_sajaye_calculator(
          anusuchiValue.gadana_miti,
          kaidParekoMiti,
          parseInt(
            anusuchiValue.barsa === '' || anusuchiValue.barsa === null
              ? 0
              : parseInt(EnglishNumber(anusuchiValue.barsa)),
          ) *
            360 +
            parseInt(
              anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.mahina)),
            ) *
              30 +
            parseInt(
              anusuchiValue.din === '' || anusuchiValue.din == null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.din)),
            ),
          anusuchiValue.includeHirasatDin,
          chuttimMiti,
          'percent',
        ),
      ),

      choteunu_parne_kaiid: NepaliNumber(
        chotauney_kaid(
          bhuktan_sajaye_calculator(
            anusuchiValue.gadana_miti,
            kaidParekoMiti,
            parseInt(
              anusuchiValue.barsa === '' || anusuchiValue.barsa === null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.barsa)),
            ) *
              360 +
              parseInt(
                anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                  ? 0
                  : parseInt(EnglishNumber(anusuchiValue.mahina)),
              ) *
                30 +
              parseInt(
                anusuchiValue.din === '' || anusuchiValue.din == null
                  ? 0
                  : parseInt(EnglishNumber(anusuchiValue.din)),
              ),
            anusuchiValue.includeHirasatDin,

            chuttimMiti,
            'onlyDate',
          ),

          bhayeko_sajaye_calculator(
            chuttimMiti,
            kaidParekoMiti,
            anusuchiValue.includeHirasatDin,
            parseInt(
              anusuchiValue.barsa === '' || anusuchiValue.barsa === null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.barsa)),
            ) *
              360 +
              parseInt(
                anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                  ? 0
                  : parseInt(EnglishNumber(anusuchiValue.mahina)),
              ) *
                30 +
              parseInt(
                anusuchiValue.din === '' || anusuchiValue.din == null
                  ? 0
                  : parseInt(EnglishNumber(anusuchiValue.din)),
              ),
          ),

          bhuktan_sajaye_calculator(
            anusuchiValue.gadana_miti,
            kaidParekoMiti,
            parseInt(
              anusuchiValue.barsa === '' || anusuchiValue.barsa === null
                ? 0
                : parseInt(EnglishNumber(anusuchiValue.barsa)),
            ) *
              360 +
              parseInt(
                anusuchiValue.mahina === '' || anusuchiValue.mahina == null
                  ? 0
                  : parseInt(EnglishNumber(anusuchiValue.mahina)),
              ) *
                30 +
              parseInt(
                anusuchiValue.din === '' || anusuchiValue.din == null
                  ? 0
                  : parseInt(EnglishNumber(anusuchiValue.din)),
              ),
            anusuchiValue.includeHirasatDin,
            chuttimMiti,
            'percent',
          ),
        ),
      ),
      // choteunu_parne_kaiid: NepaliNumber(
      //   chotteunu_parney_kaid_calculator(
      //     chuttimMiti,
      //     anusuchiValue.gadana_miti,
      //     kaidParekoMiti,
      //     parseInt(
      //       anusuchiValue.barsa === '' || anusuchiValue.barsa === null
      //         ? 0
      //         : parseInt(EnglishNumber(anusuchiValue.barsa)),
      //     ) *
      //       360 +
      //       parseInt(
      //         anusuchiValue.mahina === '' || anusuchiValue.mahina == null
      //           ? 0
      //           : parseInt(EnglishNumber(anusuchiValue.mahina)),
      //       ) *
      //         30 +
      //       parseInt(
      //         anusuchiValue.din === '' || anusuchiValue.din == null
      //           ? 0
      //           : parseInt(EnglishNumber(anusuchiValue.din)),
      //       ),

      //     anusuchiValue.includeHirasatDin,
      //   ),
      // ),
    })
  }, [
    chuttimMiti,
    muddaAntimMiti,
    kaidParekoMiti,
    punarabedanMiti,
    anusuchiValue.gadana_miti,
    anusuchiValue.tokiyeko_kaiid_ra_jaribana_gari_kaiid,
    anusuchiValue.barsa,
    anusuchiValue.mahina,
    anusuchiValue.din,
    anusuchiValue.includeHirasatDin,
  ])

  // useEffect(() => {
  //   setAnusuchiValue({ ...anusuchiValue, mudda_aantim_bhayeko_miti: muddaAntimMiti })
  // }, [muddaAntimMiti])

  // useEffect(() => {
  //   setAnusuchiValue({ ...anusuchiValue, kaid_pareko_miti: kaidParekoMiti })
  // }, [kaidParekoMiti])\\\console

  const handleAnusuchiValueChange = (e, translate = true) => {
    if (translate) {
      setAnusuchiValue({ ...anusuchiValue, [e.target.name]: convert_to_preeti(e.target.value) })
    } else {
      setAnusuchiValue({ ...anusuchiValue, [e.target.name]: e.target.value })
    }

    setResponse({
      success: null,
      error: null,
    })
  }

  useEffect(() => {
    ZillaAdminAPI.get(user ? user.access_token : null)
      .then((res) => setZilla(res.data.data))
      .catch((e) => console.log(e.message))
    MuddaAPI.get(user ? user.access_token : null)
      .then((res) => setMudda(res.data.data))
      .catch((e) => console.log(e.message))
    AdalatAPI.get(user ? user.access_token : null)
      .then((res) => setAdalat(res.data.data))
      .catch((e) => console.log(e.message))

    RastriyataAPI.get(user ? user.access_token : null)
      .then((res) => setRastriyata(res.data.data))
      .catch((e) => console.log(e.message))

    KaryalayaAPI.get(user ? user.access_token : null)
      .then((res) => setKaryalaya(res.data.data))
      .catch((e) => console.log(e.message))

    GadanaAPI.get(user ? user.access_token : null)
      .then((res) => setGadanaMiti(res.data.data))
      .catch((e) => console.log(e.message))
  }, [])

  useEffect(() => {
    if (user) {
      setAnusuchiValue({ ...anusuchiValue, Karagar_Karyalaya: user.user.name })
    }

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_PMIS_URL}/rajnitik-bibhajan-jilla`,
    })
      .then((res) => setAllZilla(res.data.data))
      .catch((e) => console.log(e.message))

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_PMIS_URL}/rajnitik-bibhajan-sthaniya`,
    })
      .then((res) => setAllSthaniya(res.data.data))
      .catch((e) => console.log(e.message))
  }, [])

  const submitAnusuchiForm = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (editMode) {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASEURL}/anusuchi1/update`,
        data: {
          ...anusuchiValue,
          anusuchi1_id: anusuchiValue.anusuchi2[0]?.anusuchi1_id,
          anusuchi2_id: anusuchiValue?.anusuchi2[0]?.id,
          anusuchi3_id: anusuchiValue?.anusuchi3[0]?.id,
        },
        headers: {
          Authorization: `Bearer ${user ? user.access_token : null}`,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            setResponse({
              success: 'तपाई को फर्म सफलतापुर्वक पेश गरिएको छ।  ',
              error: null,
            })
            setTimeout(() => {
              handleClose()
              window.location.reload()
            }, 1000)
          } else {
            setResponse({
              success: null,
              error: 'Error',
            })
          }
        })
        .catch((e) =>
          setResponse({
            success: null,
            error: e.message,
          }),
        )
    } else {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASEURL}/anusuchi1/store`,
        data: anusuchiValue,
        headers: {
          Authorization: `Bearer ${user ? user.access_token : null}`,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            setResponse({
              success: 'तपाई को फर्म सफलतापुर्वक पेश गरिएको छ।  ',
              error: null,
            })

            setTimeout(() => {
              history.push('/anusuchi')
            }, 2000)
          } else {
            setResponse({
              success: null,
              error: 'Error',
            })
          }
        })
        .catch((e) =>
          setResponse({
            success: null,
            error: e.message,
          }),
        )
    }

    setLoading(false)
  }

  return (
    <ContentBox title="अनुसूची फारम ">
      <Stepper
        initialState={initialState}
        handleChange={handleAnusuchiValueChange}
        anusuchiValue={anusuchiValue}
        setAnusuchiValue={setAnusuchiValue}
        zilla={zilla}
        mudda={mudda}
        adalat={adalat}
        karyalaya={karyalaya}
        rastriyata={rastriyata}
        gadanaMiti={gadanaMiti}
        submit={submitAnusuchiForm}
        response={response}
        setResponse={setResponse}
        chuttimMiti={chuttimMiti}
        setChuttiMiti={setChuttiMiti}
        muddaAntimMiti={muddaAntimMiti}
        setMuddaAntimMiti={setMuddaAntimMiti}
        punarabedanMiti={punarabedanMiti}
        setPunarabendanMiti={setPunarabendanMiti}
        kaidParekoMiti={kaidParekoMiti}
        setKaidParekoMiti={setKaidParekoMiti}
        garekoKasurs={garekoKasurs}
        setGarekoKasurs={setGarekoKasurs}
        loading={loading}
        allZilla={allZilla}
        allSthaniya={allSthaniya}
        editMode={editMode}
        editData={editData}
        handleEditClose={handleClose}
      />
    </ContentBox>
  )
}

export default Anusuchi
