import React, { useContext, useState, useEffect } from 'react'
import { RastriyataAPI } from '../rastriyata/RastriyataAPI'

import {
  CFormInput,
  CForm,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CButton,
  CFormTextarea,
} from '@coreui/react'
import AnusuchiOnePdf from 'src/views/pages/anusuchi/Anusuchi1Pdf'
import { PDFViewer } from '@react-pdf/renderer'
import { UserContext } from '../../../context/UserContext'
import { NepaliDatePicker } from 'nepali-datepicker-reactjs'
import 'nepali-datepicker-reactjs/dist/index.css'
import { NepaliDayResolver, NepaliMonthResolver } from 'src/helpers/NepaliMonthResolver'
import NepaliDate from 'nepali-date'
import MultipleSelect from 'src/components/MultiSelect'
import { NepaliNumber } from 'src/helpers/NepaliNumber'
import Calendar from '@sbmdkl/nepali-datepicker-reactjs'
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css'
// import { allZilla } from 'src/helpers/ZillaProvider'
import axios from 'axios'
import NepaliCalendar from 'src/components/NepaliCalendar'
const AnusuchiFirst = ({
  initialValue,
  handleChange,
  anusuchiValue,
  setAnusuchiValue,
  zilla,
  mudda,
  adalat,
  rastriyata,
  karyalaya,
  setKaidParekoMiti,
  setChuttiMiti,
  gadanaMiti,
  garekoKasurs,
  setGarekoKasurs,
  allZilla,
  allSthaniya,
  editMode,
  editData,
}) => {
  const [rastriyeta, setRastriyeta] = useState([])
  const [selectedZilla, setSelectedZilla] = useState('')
  useEffect(() => {
    setAnusuchiValue({ ...anusuchiValue, mudda: garekoKasurs })
    // sessionStorage.setItem('garekoKasurs', JSON.stringify(garekoKasurs))
  }, [garekoKasurs])

  useEffect(() => {
    RastriyataAPI.get(user ? user.access_token : null)
      .then((res) => {
        console.log(res.data.data)
        setRastriyeta(res.data.data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  const [user] = useContext(UserContext)

  return (
    <div>
      <h4 className="text-center mb-5 form-header">अनुसूची-१ फारम</h4>

      <CForm className="row g-3 mb-5">
        {/* Karyalaya */}

        <CCol md={6}>
          <CFormLabel htmlFor="karyalaya">
            कारागार कार्यालय <span className="required">*</span>
          </CFormLabel>
          {user ? (
            user.role === 'superadmin' ? (
              <CFormSelect
                id="karyalaya"
                name="Karagar_Karyalaya"
                onChange={(e) => handleChange(e, false)}
                value={anusuchiValue.Karagar_Karyalaya}
              >
                <option value="">छान्नुहोस...</option>
                {zilla
                  ? zilla.map((item, id) => (
                      <option key={id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  : null}
              </CFormSelect>
            ) : (
              <CFormInput
                type="name"
                id="name"
                required
                disabled
                name="Karagar_Karyalaya"
                // onChange={handleChange}
                value={anusuchiValue.Karagar_Karyalaya}
              />
            )
          ) : null}
        </CCol>

        {/* Address */}
        <CCol md={6}>
          <CFormLabel htmlFor="name">
            कैदीको ठेगाना <span className="required">*</span>
          </CFormLabel>
          <CRow>
            <CCol md={anusuchiValue.kaidi_ko_desh === 'नेपाल' ? 3 : 12}>
              <CFormSelect
                name="kaidi_ko_desh"
                onChange={handleChange}
                value={anusuchiValue.kaidi_ko_desh}
              >
                <option value="">देश</option>
                <option>नेपाल</option>
                {rastriyeta.map((get, id) => (
                  <option key={id}>{get.name}</option>
                ))}
              </CFormSelect>
            </CCol>
            {anusuchiValue.kaidi_ko_desh === 'नेपाल' ? (
              <>
                <CCol md={3}>
                  <CFormSelect
                    name="kaidi_ko_jilla"
                    onChange={(e) => {
                      handleChange(e, false)
                    }}
                    value={anusuchiValue.kaidi_ko_jilla}
                  >
                    <option value="">जिल्ला</option>
                    {allZilla.map((item) => (
                      <option key={item.id} value={item.jilla_nam}>
                        {item.jilla_nam}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>

                <CCol md={3}>
                  <CFormSelect
                    name="kaidi_ko_sthaniya"
                    onChange={(e) => handleChange(e, false)}
                    value={anusuchiValue.kaidi_ko_sthaniya}
                  >
                    <option value="">स्थानीय तह</option>
                    {allSthaniya.map((item) => {
                      if (item.jillabibhajan.jilla_nam === anusuchiValue.kaidi_ko_jilla) {
                        return (
                          <option key={item.id} value={item.esthania_tahako_nam}>
                            {item.esthania_tahako_nam}
                          </option>
                        )
                      }
                    })}
                  </CFormSelect>

                  {/* <CFormInput
                    type="text"
                    id="name"
                    placeholder="स्थानीय तह"
                    name="kaidi_ko_sthaniya"
                    onChange={handleChange}
                    value={anusuchiValue.kaidi_ko_sthaniya}
                  /> */}
                </CCol>

                <CCol md={3}>
                  <CFormInput
                    type="text"
                    id="name"
                    placeholder="वडा न."
                    name="kaidi_ko_wada"
                    onChange={handleChange}
                    value={anusuchiValue.kaidi_ko_wada}
                  />
                </CCol>
              </>
            ) : null}
          </CRow>
        </CCol>

        {/* Name */}
        <CCol md={4}>
          <CFormLabel htmlFor="name">
            कैदीको पुरा नाम ,थर <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="name"
            id="name"
            required
            name="kaidi_ko_nam_thar"
            onChange={(e) => {
              handleChange(e, false)
            }}
            value={anusuchiValue.kaidi_ko_nam_thar}
          />
        </CCol>

        {/* Age */}
        <CCol md={4}>
          <CFormLabel htmlFor="age">
            कैदीको उमेर <span className="required">*</span>
          </CFormLabel>
          <CFormSelect
            name="kaidi_ko_umer"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.kaidi_ko_umer}
          >
            <option value="">उमेर</option>

            <option>१०</option>
            <option>११</option>
            <option>१२</option>
            <option>१३</option>
            <option>१४</option>
            <option>१५</option>
            <option>१६</option>
            <option>१७</option>
            <option>१८</option>
            <option>१९</option>
            <option>२०</option>
            <option>२१</option>
            <option>२२</option>
            <option>२३</option>
            <option>२४</option>
            <option>२५</option>
            <option>२६</option>
            <option>२७</option>
            <option>२८</option>
            <option>२९</option>
            <option>३०</option>
            <option>३१</option>
            <option>३२</option>
            <option>३३</option>
            <option>३४</option>
            <option>३५</option>
            <option>३६</option>
            <option>३७</option>
            <option>३८</option>
            <option>३९</option>
            <option>४०</option>
            <option>४१</option>
            <option>४२</option>
            <option>४३</option>
            <option>४४</option>
            <option>४५</option>
            <option>४६</option>
            <option>४७</option>
            <option>४८</option>
            <option>४९</option>
            <option>५०</option>
            <option>५१</option>
            <option>५२</option>
            <option>५३</option>
            <option>५४</option>
            <option>५५</option>
            <option>५६</option>
            <option>५७</option>
            <option>५८</option>
            <option>५९</option>
            <option>६०</option>
            <option>६१</option>
            <option>६२</option>
            <option>६३</option>
            <option>६४</option>
            <option>६५</option>
            <option>६६</option>
            <option>६७</option>
            <option>६८</option>
            <option>६९</option>
            <option>७०</option>
            <option>७१</option>
            <option>७२</option>
            <option>७३</option>
            <option>७४</option>
            <option>७५</option>
            <option>७६</option>
            <option>७७</option>
            <option>७८</option>
            <option>७९</option>
            <option>८०</option>
            <option>८१</option>
            <option>८२</option>
            <option>८३</option>
            <option>८४</option>
            <option>८५</option>
            <option>८६</option>
            <option>८७</option>
            <option>८८</option>
            <option>८९</option>
            <option>९०</option>
            <option>९१</option>
            <option>९२</option>
            <option>९३</option>
            <option>९४</option>
            <option>९५</option>
            <option>९६</option>
            <option>९७</option>
            <option>९८</option>
            <option>९९</option>
          </CFormSelect>
        </CCol>

        {/* Gareko Kasur */}
        <CCol md={4}>
          <CFormLabel htmlFor="gareko-kasur">
            गरेको कसूर <span className="required">*</span>
          </CFormLabel>

          <MultipleSelect
            dataList={mudda}
            selectionState={garekoKasurs}
            setSelectionState={setGarekoKasurs}
            myvalue="name"
            showValue="name"
            value={garekoKasurs}
            otherMenu={true}
          />
          {/* <CFormSelect
            id="gareko-kasur"
            name="mudda"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.mudda}
          >
            <option value="">छान्नुहोस...</option>
            {mudda
              ? mudda.map((item, id) => (
                  <option key={id} value={item.name}>
                    {item.name}
                  </option>
                ))
              : null}
          </CFormSelect> */}
        </CCol>

        {/* Kaid miti */}
        <CCol md={6}>
          <CFormLabel htmlFor="kaid-miti">
            कैद परेको मिति <span className="required">*</span>
          </CFormLabel>

          <NepaliCalendar setDate={setKaidParekoMiti} date={anusuchiValue.kaid_pareko_miti} />

          {/* <Calendar
            className="form-control date-control"
            language="en"
            onChange={(date) => setKaidParekoMiti(date.bsDate)}
          /> */}
          {/* <NepaliDatePicker
            inputClassName="form-control date-control"
            className=""
            name="kaid_pareko_miti"
            onChange={(value) => setKaidParekoMiti(value)}
            // value={anusuchiValue.kaid_pareko_miti}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          /> */}
        </CCol>

        {/* Chutti miti */}
        <CCol md={6}>
          <CFormLabel htmlFor="kaid-chutti-miti">
            कैदिपुर्जिमा उल्लेय्खित छुट्टि जाने मिति <span className="required">*</span>
          </CFormLabel>

          <NepaliCalendar setDate={setChuttiMiti} date={anusuchiValue.chutti_janey_miti} />

          {/* <Calendar
            className="form-control date-control"
            language="en"
            onChange={(date) => setChuttiMiti(date.bsDate)}
          /> */}
          {/* <NepaliDatePicker
            inputClassName="form-control date-control"
            name="chutti_janey_miti"
            onChange={(value) => setChuttiMiti(value)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
            value={anusuchiValue.chutti_janey_miti}
          /> */}
        </CCol>

        {/* hirast ma baseko din */}
        <CCol md={4}>
          <CFormLabel htmlFor="bhayeko-sajaya">
            हिरासत मा बसेको अवधि (वर्ष / महिना /दिन )<br />{' '}
            <input
              type="checkbox"
              id="included"
              checked={anusuchiValue.includeHirasatDin}
              onChange={() =>
                setAnusuchiValue({
                  ...anusuchiValue,
                  includeHirasatDin: !anusuchiValue.includeHirasatDin,
                })
              }
            />
            <label htmlFor="included">
              {' '}
              <small> &nbsp; कैद परेको मितिमै समावेश गरिएको छ। </small>
            </label>
          </CFormLabel>
          <CRow>
            <CCol>
              <CFormInput
                type="name"
                placeholder="वर्ष "
                id="name"
                required
                name="barsa"
                onChange={(e) => handleChange(e, true)}
                value={anusuchiValue.barsa}
              />
            </CCol>

            <CCol>
              <CFormInput
                type="name"
                placeholder="महिना "
                id="name"
                required
                name="mahina"
                onChange={(e) => handleChange(e, true)}
                value={anusuchiValue.mahina}
              />
            </CCol>

            <CCol>
              <CFormInput
                type="name"
                id="name"
                required
                name="din"
                placeholder="दिन "
                onChange={(e) => handleChange(e, true)}
                value={anusuchiValue.din}
              />
            </CCol>
          </CRow>

          {/* <CFormInput
            type="name"
            id="name"
            required
            name="hirasat_ma_baseko_din"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.hirasat_ma_baseko_din}
          /> */}
        </CCol>

        {/* Bhayeko sajaya */}
        <CCol md={4}>
          <CFormLabel htmlFor="bhayeko-sajaya">
            भएको सजाय (वर्ष / महिना /दिन) <span className="required">*</span>
          </CFormLabel>

          <CFormInput
            type="text"
            id="name"
            required
            name="bhayeko_sajaye"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.bhayeko_sajaye}
            disabled
          />
        </CCol>

        {/* गणना मिति */}
        <CCol md={4}>
          <CFormLabel htmlFor="gareko-kasur">
            गणना मिति <span className="required">*</span>
          </CFormLabel>

          {editMode && editData ? (
            <>
              <CFormInput value={NepaliNumber(anusuchiValue.gadana_miti)} disabled={true} />
              <label className="mt-1">गणना मिति परिवर्तन</label>
              <CFormSelect
                id="gadana_miti"
                name="gadana_miti"
                onChange={(e) => handleChange(e, false)}
                value={anusuchiValue.gadana_miti}
              >
                <option value="">छान्नुहोस...</option>
                {gadanaMiti
                  ? gadanaMiti.map((item, id) => (
                      <option
                        key={id}
                        value={`${
                          item.month < new NepaliDate().getMonth() + 1
                            ? new NepaliDate().getYear() + 1
                            : new NepaliDate().getYear()
                        }-${item.month}-${NepaliDayResolver(item.day)}`}
                      >
                        {NepaliMonthResolver(item.month)}-{item.day}
                      </option>
                    ))
                  : null}
              </CFormSelect>
            </>
          ) : (
            <CFormSelect
              id="gadana_miti"
              name="gadana_miti"
              onChange={(e) => handleChange(e, false)}
              value={anusuchiValue.gadana_miti}
            >
              <option value="">छान्नुहोस...</option>
              {gadanaMiti
                ? gadanaMiti.map((item, id) => (
                    <option
                      key={id}
                      value={`${
                        item.month < new NepaliDate().getMonth() + 1
                          ? new NepaliDate().getYear() + 1
                          : new NepaliDate().getYear()
                      }-${item.month}-${NepaliDayResolver(item.day)}`}
                    >
                      {NepaliMonthResolver(item.month)}-{item.day}
                    </option>
                  ))
                : null}
            </CFormSelect>
          )}
        </CCol>

        {/* Pareko napareko */}
        <CCol md={4}>
          <CFormLabel htmlFor="pareko-napareko">
            निजको मुद्दा नकारात्मक सूचीमा <span className="required">*</span>
          </CFormLabel>
          <br />
          <div onChange={handleChange} value={anusuchiValue.nij_ko_mudda_nakaratmak}>
            <CFormCheck
              inline
              checked={anusuchiValue.nij_ko_mudda_nakaratmak === 'परेको'}
              type="radio"
              name="nij_ko_mudda_nakaratmak"
              id="pareko"
              value="परेको"
              label="परेको"
            />

            <CFormCheck
              inline
              checked={anusuchiValue.nij_ko_mudda_nakaratmak === 'नपरेको'}
              type="radio"
              name="nij_ko_mudda_nakaratmak"
              id="napareko"
              value="नपरेको"
              label="नपरेको"
            />
          </div>
        </CCol>

        {/* Anuchit kaam */}
        <CCol md={4}>
          <CFormLabel htmlFor="pareko-napareko">
            कैदमा कुनै किसिमको अनुचित काम <span className="required">*</span>
          </CFormLabel>
          <br />
          <div onChange={handleChange}>
            <CFormCheck
              inline
              type="radio"
              checked={anusuchiValue.Kaid_ma_kunai_kisim_ko_anuchit_kam === 'गरेको'}
              name="Kaid_ma_kunai_kisim_ko_anuchit_kam"
              id="gareko"
              value="गरेको"
              label="गरेको"
            />

            <CFormCheck
              inline
              checked={anusuchiValue.Kaid_ma_kunai_kisim_ko_anuchit_kam === 'नगरेको'}
              type="radio"
              name="Kaid_ma_kunai_kisim_ko_anuchit_kam"
              id="nagareko"
              value="नगरेको"
              label="नगरेको"
            />
          </div>
        </CCol>

        {/* Kaid bhuktani din */}
        <CCol md={4}>
          <CFormLabel htmlFor="kaidi-bhuktan-din">
            कैद भुक्तान गरेको अवधि (वर्ष / महिना /दिन) <span className="required"> *</span>
          </CFormLabel>

          <CFormInput
            type="name"
            id="kaidi-bhuktan-din"
            required
            name="kaaid_bhuktan_awadhi"
            onChange={(e) => handleChange(e, false)}
            value={anusuchiValue.kaaid_bhuktan_awadhi}
            disabled
          />
        </CCol>

        {/* anuchit bibaran */}
        {anusuchiValue.Kaid_ma_kunai_kisim_ko_anuchit_kam === 'गरेको' ? (
          <CCol md={12}>
            <CFormLabel htmlFor="bibaran">
              अनुचित काम गरेको भए त्यसको विवरण <span className="required"> *</span>
            </CFormLabel>
            <CFormTextarea
              aria-label="With textarea"
              name="anuchid_kam_ko_bibaran"
              onChange={handleChange}
              value={anusuchiValue.anuchid_kam_ko_bibaran}
            ></CFormTextarea>
          </CCol>
        ) : null}

        <CCol md={12}>
          <CFormLabel htmlFor="raya">
            कारागार प्रमुखको राय <span className="required"> *</span>
          </CFormLabel>
          <CFormTextarea
            aria-label="With textarea"
            maxLength={200}
            required
            name="karagar_pramuk_ko_raye"
            onChange={handleChange}
            value={anusuchiValue.karagar_pramuk_ko_raye}
          ></CFormTextarea>
          <small>
            {anusuchiValue.karagar_pramuk_ko_raye ? anusuchiValue.karagar_pramuk_ko_raye.length : 0}
            /200
          </small>{' '}
        </CCol>
      </CForm>
    </div>
  )
}

export default AnusuchiFirst
