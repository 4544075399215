import React from 'react'
import { KaryalayaAPI } from './KaryalayaAPI'
import { CONSTANTS } from '.././../../constants'

export const KaryalayaTableContent = {
  getTableHeadings: function () {
    return [
      {
        title: 'सि.नं.',
        field: 'id',
        render: (rowData) => rowData.tableData.id + 1,
      },
      {
        title: 'कार्यालयको नाम',
        field: 'name',
      },
    ]
  },

  getTableDatas: function (karyalayaes) {
    return karyalayaes
  },

  getDataActions: function (handleEditOpen, handleDeleteOpen) {
    return [
      {
        icon: 'edit',
        iconProps: {
          style: {
            color: CONSTANTS.EDIT_ICON_COLOR,
            background: CONSTANTS.EDIT_BACKGROUND_COLOUR,
          },
        },
        tooltip: 'Edit',
        onClick: (e, rowData) => handleEditOpen(rowData.id),
        position: 'row',
      },

      {
        icon: 'delete',
        iconProps: {
          style: {
            color: CONSTANTS.DELETE_ICON_COLOR,
            background: CONSTANTS.DELETE_BACKGROUND_COLOR,
          },
        },
        tooltip: 'Delete',
        onClick: (e, rowData) => handleDeleteOpen(rowData.id),
        position: 'row',
      },
    ]
  },
}
