import React from 'react'
import axios from 'axios'
import { CONSTANTS } from 'src/constants'

export const AnusuchiAPI = {
  // ...

  // mapping the API of interest
  get: function (token) {
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/anusuchi1/get`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  getSingle: function (token, selectedItem) {
    console.log(selectedItem)
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/anusuchi/get/${selectedItem}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  createAnusuchi: function (token, data) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/anusuchi1/store`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  editAnusuchi: function (token, data, selectedItem) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/anusuchi1/update/${selectedItem}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  delete: function (token, selectedItem) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/anusuchi1/delete`,
      data: {
        id: selectedItem,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  submitToCentral: function (token, selectedItems) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/application/send-to-central`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: selectedItems,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  giveMaafiFunction: function (token, selectedItems) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/application/give-maafi`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        anusuchi_ids: selectedItems,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  // ...
}
