import React, { useState, useEffect, useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormLabel,
  CFormInput,
  CRow,
  CCol,
  CFormSelect,
  CButton,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { UserContext } from 'src/context/UserContext'
import { ZillaAdminAPI } from './ZillaAdminAPI'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CONSTANTS } from 'src/constants'
import { convert_to_preeti } from 'src/helpers/PreetiConverter'

const initialState = {
  name: '',
  email: '',
  password: '',
  role: 3,
  id: '',
}

const ZillaAdminForm = ({
  open,
  editMode,
  handleClose,
  editData,
  reload,
  setReload,
  fetching,
  selectedId,
}) => {
  // Response State
  const [response, setResponse] = useState({
    success: null,
    error: null,
  })
  // ZillaAdmin data
  const [zillaAdminData, setZillaAdminData] = useState(initialState)

  // Lifecycle method to
  useEffect(() => {
    // console.log(editData)
    if (editData) {
      console.log('Edit Data is', editData)
      setZillaAdminData({
        name: editData.name,
        email: editData.email,
        password: editData.password,
        id: editData.id,
        role: editData.roles[0].id,
      })
    }
  }, [editData])

  useEffect(() => {
    if (open === false) {
      setResponse({
        success: null,
        error: null,
      })
    }
  }, [open])
  // User Context
  const [user] = useContext(UserContext)

  const [loading, setLoading] = useState(false)

  // function to handle zillaAdmin data changes
  const handleZillaAdminDataChange = (e, translate = true) => {
    if (translate) {
      setZillaAdminData({ ...zillaAdminData, [e.target.name]: convert_to_preeti(e.target.value) })
    } else {
      setZillaAdminData({ ...zillaAdminData, [e.target.name]: e.target.value })
    }
  }

  // Function to create ZillaAdmin
  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      zillaAdminData.email === '' ||
      zillaAdminData.name === '' ||
      zillaAdminData.password === ''
    ) {
      return
    }
    setLoading(true)
    {
      editMode
        ? ZillaAdminAPI.editZillaAdmin(user.access_token, zillaAdminData, selectedId)
            .then((res) => {
              if (res.data.status === true) {
                setZillaAdminData(initialState)
                setResponse({
                  error: null,
                  success: 'ZillaAdmin has been updated',
                })
                setLoading(false)
              } else {
                console.log(res.data)
                setResponse({
                  error: 'Failed to update',
                  success: null,
                })
                setLoading(false)
              }
            })
            .catch((e) => console.log(e.message))
        : ZillaAdminAPI.createZillaAdmin(user.access_token, zillaAdminData)
            .then((res) => {
              if (res.data.status === true) {
                setLoading(false)
                setZillaAdminData(initialState)
                setResponse({
                  error: null,
                  success: 'New ZillaAdmin has been created',
                })
                setReload(!reload)
                setLoading(false)
              }
            })
            .catch((e) => {
              console.log(e.message)
              setLoading(false)
            })

      setTimeout(
        () =>
          setResponse({
            success: null,
            error: null,
          }),
        CONSTANTS.ALERT_TIME_OUT,
      )
    }
  }

  return (
    <CCard className="mb-4">
      <CCardHeader>{editMode ? 'Edit ZillaAdmin' : 'नयाँ जिल्ला एडमिन '}</CCardHeader>
      <CCardBody>
        {response.success ? <Alert severity="success">{response.success}</Alert> : null}
        {response.error ? <Alert severity="error">{response.error}</Alert> : null}

        {fetching ? (
          <CircularProgress />
        ) : (
          <CForm className={response.success || response.error ? 'mt-4' : null}>
            <CRow>
              <CCol md="4">
                <div className="mb-3">
                  <CFormLabel htmlFor="zillaAdminName">जिल्ला एडमिन को नाम</CFormLabel>

                  <CFormInput
                    type="text"
                    id="zillaAdminName"
                    placeholder=""
                    name="name"
                    value={zillaAdminData.name}
                    onChange={handleZillaAdminDataChange}
                    // onKeyUp={(e) => convert_to_unicode(e.target.value)}
                  />
                </div>
              </CCol>

              <CCol md="4">
                <div className="mb-3">
                  <CFormLabel htmlFor="zillaAdminName">जिल्ला एडमिन को प्रयोगकर्ता नाम</CFormLabel>

                  <CFormInput
                    type="text"
                    id="zillaAdminName"
                    placeholder=""
                    name="email"
                    value={zillaAdminData.email}
                    onChange={(e) => handleZillaAdminDataChange(e, false)}
                  />
                </div>
              </CCol>

              <CCol md="4">
                <div className="mb-3">
                  <CFormLabel htmlFor="zillaAdminName">पास्वोर्ड</CFormLabel>

                  <CFormInput
                    type="password"
                    id="zillaAdminName"
                    placeholder=""
                    name="password"
                    value={zillaAdminData.password}
                    onChange={(e) => handleZillaAdminDataChange(e, false)}
                  />
                </div>
              </CCol>

              <CCol md="12">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <CButton type="submit" color="primary" size="sm" onClick={handleSubmit}>
                    {editMode ? 'Save' : 'जिल्ला एडमिन  बनाउनुहोस'}
                  </CButton>
                )}
                &nbsp;{' '}
                {editMode ? (
                  <CButton color="danger" size="sm" variant="outline" onClick={handleClose}>
                    Close
                  </CButton>
                ) : null}
              </CCol>
            </CRow>
          </CForm>
        )}
      </CCardBody>
    </CCard>
  )
}

export default ZillaAdminForm

ZillaAdminForm.propTypes = {
  editMode: PropTypes.bool,
  handleClose: PropTypes.func,
}

ZillaAdminForm.defaultProps = {
  editMode: false,
  handleClose: () => console.log('Clicked'),
}
