import React, { useContext } from 'react'
import { PrintContext } from 'src/context/PrintContext'
import NepaliDate from 'nepali-date'
import { NepaliNumber, EnglishNumber } from 'src/helpers/NepaliNumber'

const returnStringFromArray = (array) => {
  let res = ''
  for (let i = 0; i < array.length; i++) {
    if (i < array.length - 1) {
      res = res + array[i] + ', '
    } else {
      res = res + array[i]
    }
  }

  return res
}

// Create Document Component
const AnusuchiThreePdf = () => {
  const [printData] = useContext(PrintContext)

  console.log('Print Data', printData)
  return (
    <div id="anusuchi-3" style={{ padding: '3rem', whiteSpace: 'pre-wrap' }}>
      <header>
        <h4 style={{ textAlign: 'center' }}>अनुसूची ३</h4>
        <p style={{ textAlign: 'center' }}>(नियम ६ को उपनियम ( २ ) संग सम्बन्धित )</p>
      </header>

      {/* Subject section */}
      <section id="subject">
        <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>
          कैद कट्टाको लागि गरिने सिफारिसको ढाँचा
        </h4>
      </section>
      {/*  */}

      {/* Table Section */}
      <section id="table" className="pdf-table">
        <table border="1" style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th colSpan="16">
                कैद कट्टा गर्ने प्रयोजनका लागि तोकिएको कैदको ५० प्रतिशत कैद भुक्तान गरेका तथा
                ज्येष्ठ नागरिकहरुको हकमा तोकिएको उमेर पूरा गरेका कैदीहरुको बाँकी कैद कट्टाका लागि{' '}
                <br />
                <span style={{ textDecoration: 'underline' }}>
                  प्रमुख जिल्ला अधिकारी तथा कारागार प्रमुखको सिफारिस सहितको विवरण
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tr className="anusuchi3-heading">
            <td>सि.न.</td>
            <td>कैदीको नाम ,थर र ठेगाना</td>
            <td>जाहेरवालाको नाम</td>
            <td>मुद्दाको अन्तिम कारवाही गर्ने निकाय र अन्तिम फैसला मिति</td>
            <td>उमेर</td>
            <td>लिङ्ग</td>
            <td>राष्ट्रियता</td>
            <td>मुद्दा</td>
            <td>निजको मुद्दा नकारात्मक सूचीमा परे/नपरेको</td>
            <td>पुनरावेदन नपरेको प्रमाण </td>
            <td>कैद परेको मिति</td>
            <td>कैदीपूर्जीमा उल्लिखित छुटी जाने मिति ( जरिवाना भए सो समेत )</td>
            <td>तोकिएको कैद र जरिवाना गरी जम्मा कैद (वर्ष, महिना, दिन )</td>
            <td>भुक्तान कैद (वर्ष, महिना, दिन र प्रतिशत )</td>
            <td>छोट्याउनु पर्ने कैद (वर्ष, महिना, दिन र प्रतिशत )</td>
            <td>कैफियत (वृद्ध, रोगी वा अशक्त भए सो समेत उल्लेख गर्ने)</td>
            <td>कैद कट्टी गर्न उपयुक्त छ/छैन</td>
          </tr>

          {printData.map((item, id) => {
            console.log(item.kaidi_ko_nam_thar)
            console.log(item.kaidi_ko_jilla)
            return (
              <tr key={id}>
                <td>{NepaliNumber(id + 1)}</td>
                <td>
                  {item.kaidi_ko_desh === 'नेपाल'
                    ? item.kaidi_ko_nam_thar +
                      '\n' +
                      item.kaidi_ko_sthaniya +
                      ' ' +
                      item.kaidi_ko_wada +
                      ',' +
                      ' ' +
                      item.kaidi_ko_jilla
                    : item.kaidi_ko_nam_thar + ',' + '\n' + item.kaidi_ko_desh}
                </td>
                <td>{item.anusuchi3[0]?.jaherwala_ko_nam}</td>
                <td>
                  {item.anusuchi2[0]?.adalat === 'जिल्ला अदालत'
                    ? item.anusuchi2[0]?.adalat_jilla +
                      ' ' +
                      item.anusuchi2[0]?.adalat +
                      '\n' +
                      ' ,' +
                      '\n' +
                      NepaliNumber(
                        item.anusuchi2 ? item.anusuchi2[0]?.mudda_aantim_bhayeko_miti : '',
                      )
                    : item.anusuchi2[0]?.adalat +
                      ', ' +
                      '\n' +
                      NepaliNumber(
                        item.anusuchi2 ? item.anusuchi2[0]?.mudda_aantim_bhayeko_miti : '',
                      )}
                  {/* {item.anusuchi2
                    ? item.anusuchi2[0]?.adalat === 'जिल्ला अदालत'
                      ? item.anusuchi2[0]?.adalat + ' ,' + item.anusuchi2[0]?.adalat_jilla
                      : item.anusuchi2[0]?.adalat +
                        ',' +
                        '\n' +
                        NepaliNumber(
                          item.anusuchi2 ? item.anusuchi2[0]?.mudda_aantim_bhayeko_miti : '',
                        )
                    : null} */}
                </td>
                <td>{item.kaidi_ko_umer}</td>
                <td>{item.anusuchi3[0]?.linga}</td>
                <td>{item.anusuchi3[0]?.rastriyata}</td>
                <td>{returnStringFromArray(item.mudda)}</td>
                <td>{item.nij_ko_mudda_nakaratmak}</td>
                <td>
                  {item.anusuchi3[0]?.punarabedan_karyalaya === 'सर्वोच्च अदालत'
                    ? item.anusuchi3[0]?.punarabedan_karyalaya +
                      ' को मिति' +
                      '\n' +
                      NepaliNumber(item.anusuchi3[0]?.punarabedan_miti) +
                      ' को फैसला'
                    : item.anusuchi3[0]?.punarabedan_karyalaya === 'महान्यायाधिवक्ताको कार्यालय'
                    ? item.anusuchi3[0]?.punarabedan_karyalaya +
                      ' ' +
                      'को \n च.न ' +
                      item.anusuchi3[0]?.punarabedan_chalan_number +
                      ' मिति \n' +
                      NepaliNumber(item.anusuchi3[0]?.punarabedan_miti) +
                      ' को पत्र'
                    : item.anusuchi3[0]?.punarabedan_karyalaya +
                      ' ' +
                      item.anusuchi3[0]?.punarabedan_jilla +
                      '' +
                      'को \n च.न ' +
                      item.anusuchi3[0]?.punarabedan_chalan_number +
                      ' मिति \n' +
                      NepaliNumber(item.anusuchi3[0]?.punarabedan_miti) +
                      ' को पत्र'}
                </td>
                {/* item.barsa || 'hi' + ',' + '\n' + item.mahina || '0' + ',' + '\n' + item.din || '0'
                + NepaliNumber(item.kaid_pareko_miti) */}
                <td>
                  {item.barsa === null && item.mahina === null && item.din === null
                    ? NepaliNumber(item.kaid_pareko_miti)
                    : NepaliNumber(item.barsa ? item.barsa : '0') +
                      '/' +
                      NepaliNumber(item.mahina ? item.mahina : '0') +
                      '/' +
                      NepaliNumber(item.din ? item.din : '0') +
                      ' पहिले बसेको' +
                      '\n' +
                      NepaliNumber(item.kaid_pareko_miti)}
                </td>
                <td>
                  {item.anusuchi3[0]?.jariwana
                    ? NepaliNumber(item.chutti_janey_miti) +
                      ' \n जरिवाना रु. ' +
                      item.anusuchi3[0]?.jariwana +
                      ' तिरेको'
                    : NepaliNumber(item.chutti_janey_miti)}
                </td>
                <td>{NepaliNumber(item.anusuchi3[0]?.tokiyeko_kaiid_ra_jaribana_gari_kaiid)}</td>
                <td>
                  {NepaliNumber(item.kaaid_bhuktan_awadhi.split('/').slice(0, 3).join('/')) +
                    '\n' +
                    NepaliNumber(
                      parseFloat(
                        100.0 -
                          parseFloat(
                            EnglishNumber(item.anusuchi3[0]?.choteunu_parne_kaiid.split('/')[3]),
                          ),
                      ).toFixed(2),
                    )}
                </td>
                <td>
                  {NepaliNumber(
                    item.anusuchi3[0]?.choteunu_parne_kaiid?.split('/').slice(0, 3).join('/'),
                  ) +
                    '\n' +
                    NepaliNumber(item.anusuchi3[0]?.choteunu_parne_kaiid.split('/')[3])}
                </td>
                <td>{item.anusuchi3[0]?.kaiifeyad}</td>
                <td>{item.anusuchi3[0]?.kaiid_katti_garne_upayukta}</td>
              </tr>
            )
          })}
        </table>
      </section>

      {/* Signatture section */}

      <p>
        {' '}
        माथि उल्लिखित कैदीको फौजदारी कसूर (सजाय निर्धारण तथा कार्यन्ववयन) ऐन , २०७४ को दफा ३७ बमोजिम
        भुक्तान गर्नु पर्ने कैद अवधि भुक्तान भई सकेको, निजको चालचलनमा सुधार आएको र निजलाई भएको कैद
        सजाय ऐनको दफा ३७ र फौजदारी कसूर (कैद कट्टा) नियमावली, २०७६ बमोजिम कट्टा गर्न मिल्ने
        देखिएकोले माथि उल्लेख भए बमोजिम भुक्तान गर्न बाँकी कैद कट्टाको निर्णय हुन मनासिब देखिएको
        व्यहोरा सिफारिस गर्दछु।
      </p>

      <section id="signature">
        <div
          className="row"
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <div className="col-md-6">
            <div>
              <p>..............................</p>
            </div>

            <div>
              <p>नाम थर:</p>
            </div>

            <div>
              <p>पद : कारागार प्रमुख (जेलर )</p>
            </div>

            <div>
              <p>
                कार्यालय : कारागार कार्यालय,{' '}
                {printData.length > 0 ? printData[0].Karagar_Karyalaya : null}
              </p>
            </div>

            <div>
              <p>
                मिति: {NepaliNumber(new NepaliDate().getYear())}-
                {NepaliNumber(new NepaliDate().getMonth() + 1)} -
                {NepaliNumber(new NepaliDate().getDate())}
              </p>
            </div>

            <div>
              <p>कार्यालयको छाप: </p>
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginRight: '5rem' }}>
              <p>..............................</p>
            </div>

            <div style={{ marginRight: '5rem' }}>
              <p>नाम थर:</p>
            </div>

            <div style={{ marginRight: '5rem' }}>
              <p>पद : प्रमुख जिल्ला अधिकारी</p>
            </div>

            <div style={{ marginRight: '5rem' }}>
              <p>कार्यालय : जिल्ला प्रशाशन कार्यालय,.... </p>
            </div>

            <div style={{ marginRight: '5rem' }}>
              <p>
                मिति:{' '}
                {NepaliNumber(
                  `${new NepaliDate().getYear()}-${
                    new NepaliDate().getMonth() + 1
                  }-${new NepaliDate().getDate()}`,
                )}
              </p>
            </div>

            <div style={{ marginRight: '5rem' }}>
              <p>कार्यालयको छाप : </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AnusuchiThreePdf
