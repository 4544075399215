import React from 'react'
import axios from 'axios'
import { CONSTANTS } from 'src/constants'

export const ZillaAdminAPI = {
  // ...

  // mapping the API of interest
  get: function (token) {
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/user-role`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  getSingle: function (token, selectedItem) {
    console.log(selectedItem)
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/user-role/show`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: selectedItem,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  createZillaAdmin: function (token, data) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/user-role/store`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  editZillaAdmin: function (token, data) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/user-role/update`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  delete: function (token, selectedItem) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/user-role/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: selectedItem,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  // ...
}
