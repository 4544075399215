import React from 'react'
import axios from 'axios'
import { CONSTANTS } from 'src/constants'

export const KaryalayaAPI = {
  // ...

  // mapping the API of interest
  get: function (token) {
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/karyalaya/get`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  getSingle: function (token, selectedItem) {
    console.log(selectedItem)
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/karyalaya/get/${selectedItem}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  createKaryalaya: function (token, data) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/karyalaya/store`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  editKaryalaya: function (token, data, selectedItem) {
    return axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/karyalaya/update/${selectedItem}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  delete: function (token, selectedItem) {
    return axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/karyalaya/delete/${selectedItem}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: CONSTANTS.NETWORK_TIME_OUT,
      timeoutErrorMessage: CONSTANTS.NETWORK_TIME_OUT_MESSAGE,
    })
  },

  // ...
}
