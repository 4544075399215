import React, { useState, useEffect, useContext } from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import Table from 'src/components/Table/Table'
import { PuranoAbedanTableContent } from './PuranoAbedanTableContent'
import MyDialog from 'src/components/Dialog/Dialog'
import PuranoAbedanForm from './PuranoAbedanForm'
import ContentBox from 'src/components/ContentBox/ContentBox'
import { CButton } from '@coreui/react'
import { PuranoAbedanAPI } from './PuranoAbedanAPI'
import { UserContext } from 'src/context/UserContext'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { CONSTANTS } from 'src/constants'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const OldAnusuchiList = ({ reload, setReload }) => {
  const history = useHistory()
  const location = useLocation()

  const [allData, setAllData] = useState([])
  const [loadingTableData, setLoadingTableData] = useState(true)
  useEffect(() => {
    if (location.state.forms) {
      setAllData(location.state.forms.datas)
      setLoadingTableData(false)
    }
  }, [location.state.forms])
  // Selected OldAnusuchi
  const [selectedPuranoAbedan, setSelectedPuranoAbedan] = useState(null)

  const [response, setResponse] = useState({
    success: null,
    error: null,
  })

  // state maintains table data

  return (
    <CCard className="mb-4">
      <CCardHeader>पुराना आवेदनहरु</CCardHeader>
      <CCardBody>
        {response.success ? (
          <div className="mb-3">
            {' '}
            <Alert severity="success">{response.success}</Alert>
          </div>
        ) : null}
        {response.error ? (
          <div className="mb-3">
            <Alert severity="error">{response.error}</Alert>
          </div>
        ) : null}
        {/* OldAnusuchi Lists */}
        <Table
          columns={PuranoAbedanTableContent.getTableHeadings()}
          data={PuranoAbedanTableContent.getTableDatas(allData)}
          actions={PuranoAbedanTableContent.getDataActions(history)}
          loading={loadingTableData}
        />
      </CCardBody>
    </CCard>
  )
}

export default OldAnusuchiList
