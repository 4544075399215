import React, { useState } from 'react'
import ZillaAdminForm from './ZillaAdminForm'
import ZillaAdminList from './ZillaAdminList'

const ZillaAdmin = () => {
  const [reload, setReload] = useState(false)
  return (
    <>
      <ZillaAdminForm reload={reload} setReload={setReload} />
      <ZillaAdminList reload={reload} setReload={setReload} />
    </>
  )
}
export default ZillaAdmin
