import React, { useState, useEffect, useContext } from 'react'
import { CCard, CCardBody, CCardHeader, CRow, CCol } from '@coreui/react'
import Table from 'src/components/Table/Table'
import { AbedanTableContent } from './AbedanTableContent'
import MyDialog from 'src/components/Dialog/Dialog'
import AbedanForm from './AbedanForm'
import ContentBox from 'src/components/ContentBox/ContentBox'
import { CButton } from '@coreui/react'
import { AbedanAPI } from './abedanAPI'
import { UserContext } from 'src/context/UserContext'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { CONSTANTS } from 'src/constants'
import { CSVLink, CSVDownload } from 'react-csv'
import { useHistory } from 'react-router-dom'
import ExcelDownload from './AbedanExcel'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import CentralPdf from './CentralPdf'
import font from '../../../assets/Kalimati.ttf'
import '../../../assets/kalimati'
import { DockTwoTone } from '@material-ui/icons'
import axios from 'axios'
import MultipleSelect from '../../../components/MultiSelect'
import { ZillaAdminAPI } from '../../../views/pages/zillaAdmin/ZillaAdminAPI'

import NepaliCalendar from 'src/components/NepaliCalendar'
const AbedanList = ({ reload, setReload }) => {
  const history = useHistory()
  // Excel headers
  const [excelHeader, setExcelHeader] = useState([
    'सि.नं.',
    'कारागारको नाम ',
    'कैदीको नाम ,थर , ठेगाना',
    'जाहेरवालाको नाम',
    'मुद्दाको अन्तिम कारवाही गर्ने निकाय र अन्तिम फैसला मिति',
    'उमेर',
    'लिङ्ग',
    'राष्ट्रियता',
    'मुद्दा',
    'निजको मुद्दा नकारात्मक सूचीमा परे / नपरेको',
    'पुनरावेदन नपरेको प्रमाण',
    'कैद परेको मिति',
    'कैदिपूर्जीमा उल्लेखित छुटी जाने मिति र जरिवाना भए सो समेत',
    'तोकिएको कैद र जरिवाना गरी जम्मा कैद ( वर्ष / महिना / दिन )',
    'भुक्तान कैद ( वर्ष / महिना / दिन  / प्रतिशत )',
    'छोट्याउनु पर्ने कैद ( वर्ष / महिना / दिन  / प्रतिशत )',
    'कैफियत (वृद्ध, रोगी वा अशक्त भए सो समेत उल्लेख गर्ने)',
    'कैद कट्टी गर्न उपयुक्त छ / छैन ',
  ])

  const [excelData, setExcelData] = useState([['adad', 'ada', 'sda']])

  const [karagars, setKaragars] = useState([])
  const [selectedKaragar, setSelectedKaragar] = useState([])
  // Selected Abedan
  const [selectedAbedan, setSelectedAbedan] = useState(null)

  const [response, setResponse] = useState({
    success: null,
    error: null,
  })

  // State maintains loading while creating data
  const [loading, setLoading] = useState(false)
  // state maintains table data
  const [loadingTableData, setLoadingTableData] = useState(true)

  // State to maintain loading while fetching individual data
  const [fetching, setFetching] = useState(false)

  // Selected Item
  const [selectedItem, setSelectedItem] = useState(null)
  // User Context
  const [user] = useContext(UserContext)
  // State to store abedanes
  const [abedanes, setAbedanes] = useState([])

  // State to handle edit dialog box
  const [openEdit, setOpenEdit] = useState(false)

  // State to handle delete dialog box

  const [openDelete, setOpenDelete] = useState(false)

  const [openRollback, setOpenRollback] = useState(false)

  const [rollBackReason, setRollBackReason] = useState('')
  const [allAbedan, setAllAbedan] = useState([])

  const [recentAbedan, setRecentAbedan] = useState([])

  const [filterValue, setFilterValue] = useState({
    date_from: '',
    date_to: '',
  })

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    setFilterValue({ ...filterValue, date_from: startDate })
  }, [startDate])

  useEffect(() => {
    setFilterValue({ ...filterValue, date_to: endDate })
  }, [endDate])

  // edit Menu Handler
  const handleEditOpen = (id) => {
    setOpenEdit(true)
    setSelectedItem(id)
  }
  const handleEditClose = () => {
    setOpenEdit(false)
    setReload(!reload)
    setSelectedItem(null)
  }

  const handleRollbackOpen = (e, data) => {
    setSelectedItem(data)
    setOpenRollback(true)
  }

  const handleRollBackClose = () => {
    setSelectedItem(null)
    setOpenRollback(false)
  }

  useEffect(() => {
    ZillaAdminAPI.get(user?.access_token)
      .then((res) => setKaragars(res.data.data))
      .catch((e) => console.log(e.message))
  }, [])

  const submitRollback = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
      url: `${process.env.REACT_APP_BASEURL}/application/roll-back`,
      data: {
        id: selectedItem.id,
        message: rollBackReason,
      },
    })
      .then((res) => {
        setResponse({
          success: 'Abedan has been rollback successfully ',
          error: null,
        })

        handleRollBackClose()
        setReload(!reload)
      })
      .catch((e) => setResponse({ success: null, error: 'Failed to rollback abedan' }))

    setLoading(false)

    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      })
    }, 5000)
  }

  // Delete Dialog Handler
  const handleDeleteOpen = (id) => {
    setOpenDelete(true)
    setSelectedItem(id)
  }
  const handleDeleteClose = () => {
    setOpenDelete(false)
    setReload(!reload)
    setSelectedItem(null)
  }

  const doc = new jsPDF('l', 'mm', 'a4')

  // doc.addFont(font, 'MyFont', 'normal')
  // doc.setFont('MyFont')
  // doc.text('उपयुक्त')
  // doc.text(100, 100, 'उपयुक्त')
  doc.setFont('Kalimati')

  // doc.setFontSize(5) // you can change size
  // doc.addFileToVFS('MyFont.ttf', font)
  // doc.addFont('MyFont.ttf', 'MyFont', 'normal')

  const downloadPdf = () => {
    // doc.setFont('Kalimati', 'normal')
    doc.text(100, 100, 'उपयुक्त')

    doc.autoTable({
      html: '#final-table',
      theme: 'grid',
      styles: {
        fontFamily: 'Kalimati',
      },
      headStyles: {
        // backgroundColor: 'red',
        fontFamily: 'Kalimati',
        textColor: 'black',
        fillColor: 'white',
        // font: 'MyFont',
      },
    })
    // doc.autoPrint({ variant: 'javascript' })
    doc.save('table.pdf')
  }

  // Lifecycle method to fetch abedan

  useEffect(() => {
    setLoadingTableData(true)
    AbedanAPI.get(user ? user.access_token : null)
      .then((res) => {
        setAllAbedan(res.data.data)
        // let temp = res.data.data.filter((item) => item.ganana_miti === '2079-6-2')
        // setRecentAbedan(res)
        setAbedanes(res.data.data)
        setLoadingTableData(false)
      })
      .catch((e) => console.log(e.message))
  }, [reload])

  // useEffect(() => {
  //   let res = allAbedan.filter((item) => item.ganana_miti === '2079-2-14')

  //   setAllAbedan(res)
  // }, [])

  // Function to delete abedan
  const DeleteAbedan = async () => {
    setLoading(true)
    await AbedanAPI.delete(user ? user.access_token : null, selectedItem)
      .then((res) => {
        if (res.data.status === true) {
          setResponse({
            success: 'Abedan has been deleted successfully',
            error: null,
          })
        } else {
          setResponse({
            success: null,
            error: 'Failed to delete ! Try again later.',
          })
        }
      })
      .catch((e) => console.log(e.message))

    setLoading(false)

    handleDeleteClose()
    setTimeout(
      () =>
        setResponse({
          success: null,
          error: null,
        }),
      CONSTANTS.ALERT_TIME_OUT,
    )
  }

  const filterAbedan = () => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/application/filter`,

      params: {
        date_from:
          filterValue.date_from === '2000-1-1  ' || filterValue.date_from === ''
            ? null
            : filterValue.date_from,
        date_to:
          filterValue.date_to === '2000-1-1' || filterValue.date_to === ''
            ? null
            : filterValue.date_to,
      },
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    })
      .then((result) => {
        let res = []
        if (selectedKaragar.length > 0) {
          for (let i = 0; i < result.data.data.length; i++) {
            for (let j = 0; j < selectedKaragar.length; j++) {
              if (result.data.data[i].Karagar_Karyalaya == selectedKaragar[j]) {
                res.push(result.data.data[i])
              }
            }
          }

          setAbedanes(res)
        } else {
          setAbedanes(result.data.data)
        }
      })
      .catch((e) => console.log(e.message))

    // let temp = [...recentAbedan]
    // let res = []
    // if (selectedKaragar.length > 0) {
    //   for (let i = 0; i < temp.length; i++) {
    //     for (let j = 0; j < selectedKaragar.length; j++) {
    //       if (temp[i].Karagar_Karyalaya == selectedKaragar[j]) {
    //         res.push(temp[i])
    //       }
    //     }
    //   }

    //   setAbedanes(res)
    // } else {
    //   setAbedanes([...recentAbedan])
    // }
  }

  console.log(filterValue)
  return (
    <>
      {/* Abedan filter */}
      <CCard className="mb-4">
        <CCardHeader>आवेदन फिल्टर </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg={12}>
              <label>जिल्ला चयन गर्नुहोस्</label>
              <MultipleSelect
                dataList={karagars}
                selectionState={selectedKaragar}
                setSelectionState={setSelectedKaragar}
                myvalue="name"
                showValue="name"
              />
            </CCol>

            <label className="mt-3">आवेदन बुझाएको मिति</label>

            <CCol md={6}>
              <label className="my-2">सुरु मिति</label>
              <NepaliCalendar setDate={setStartDate} date={filterValue.date_from} />
            </CCol>

            <CCol md={6}>
              <label className="my-2">अन्त्य मिति </label>

              <NepaliCalendar setDate={setEndDate} date={filterValue.date_to} />
            </CCol>
          </CRow>

          <CButton className="btn btn-sm mt-3" onClick={filterAbedan}>
            फिल्टर गर्नुहोस्
          </CButton>
        </CCardBody>
      </CCard>

      <CCard className="mb-4">
        <CCardHeader>जिल्ला का आबेदनहरु </CCardHeader>
        <CCardBody>
          {response.success ? (
            <div className="mb-3">
              {' '}
              <Alert severity="success">{response.success}</Alert>
            </div>
          ) : null}
          {response.error ? (
            <div className="mb-3">
              <Alert severity="error">{response.error}</Alert>
            </div>
          ) : null}

          <div className="mb-4 mt-3" style={{ display: 'flex', justifyContent: 'end' }}>
            <ExcelDownload data={abedanes} />

            {/* <button onClick={downloadPdf}>Print</button> */}
            {/* <CSVLink
            data={excelData}
            headers={excelHeader}
            filename={'Abedan Reports.csv'}
            className="btn btn-primary btn-sm"
            target="_blank"
          >
            एक्सेलमा डाउनलोड गर्नुहोस् ।
          </CSVLink> */}
          </div>
          {/* Abedan Lists */}
          {/* <div id="final-table"> */}
          <div style={{ fontSize: '13px' }} id="abedan-data">
            <Table
              columns={AbedanTableContent.getTableHeadings()}
              data={AbedanTableContent.getTableDatas(abedanes)}
              actions={AbedanTableContent.getDataActions(
                handleEditOpen,
                handleDeleteOpen,
                excelHeader,
                excelData,
                handleRollbackOpen,
              )}
              loading={loadingTableData}
              actionIndex="0"
            />
          </div>
          {/* <CentralPdf /> */}
          {/* </div> */}

          {/* Edit Dialog Box */}
          <MyDialog open={openEdit} handleClose={handleEditClose}>
            <AbedanForm
              open={openEdit}
              editMode={true}
              handleClose={handleEditClose}
              editData={selectedAbedan}
              fetching={fetching}
              selectedId={selectedItem}
            />
          </MyDialog>

          {/* Delete Dialog Box */}
          <MyDialog open={openDelete} handleClose={handleDeleteClose}>
            <ContentBox title="Remove Abedan">
              <p>Do you want to remove this abedan?</p>
              {loading ? (
                <CircularProgress />
              ) : (
                <CButton color="primary" size="sm" onClick={DeleteAbedan}>
                  Confirm
                </CButton>
              )}
              &nbsp;{' '}
              <CButton color="danger" size="sm" variant="outline" onClick={handleDeleteClose}>
                Close
              </CButton>
            </ContentBox>
          </MyDialog>

          {/* Roll Back Dialog */}
          <MyDialog open={openRollback} handleClose={handleRollBackClose}>
            <ContentBox title="Rollback Abedan">
              <div className="form-group mb-4">
                <label>Reason for rollback</label>
                <textarea
                  className="form-control"
                  value={rollBackReason}
                  onChange={(e) => setRollBackReason(e.target.value)}
                ></textarea>
              </div>
              {loading ? (
                <CircularProgress />
              ) : (
                <CButton color="primary" size="sm" onClick={submitRollback}>
                  Confirm
                </CButton>
              )}
              &nbsp;{' '}
              <CButton color="danger" size="sm" variant="outline" onClick={handleRollBackClose}>
                Close
              </CButton>
            </ContentBox>
          </MyDialog>
        </CCardBody>
      </CCard>
    </>
  )
}

export default AbedanList
